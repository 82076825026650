import { FunctionComponent, memo } from 'react'

type Props = {
  selectedKey: string
  items: Array<{ name: string; key: string }>
  onChange: (key: string) => void
}

const Tabs: FunctionComponent<Props> = ({ selectedKey, items, onChange }) => {
  return (
    <ul role='button' className='flex border-b w-full'>
      {items.map(({ key, name }) => (
        <li
          key={key}
          onClick={() => onChange(key)}
          className={` h-16 flex items-center px-5 border-b-2  ${
            selectedKey === key ? 'border-primary' : 'border-transparent'
          }`}
        >
          {name}
        </li>
      ))}
    </ul>
  )
}

export default memo(Tabs)
