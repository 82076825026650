import { CreateModelData, Model, UpdateModelData, UpdateModelOrder } from '../types/models';
import http from '../../configs/http.config';
class ModelApi {

    static readonly path: string = '/product-models'


    static async createModel(params: CreateModelData): Promise<Model> {
        const { data } = await http.post<Model>(this.path, params);
        return data;
    }

    static async getModels(): Promise<Model[]> {
        const { data } = await http.get<Model[]>(`${this.path}/all`);
        return data;
    }


    static async getCurrentModelByModelId(id: string): Promise<Model> {
        const { data } = await http.get<Model>(`${this.path}/model/${id}`);
        return data;
    }

    static async updateModelOrder(params: UpdateModelOrder[]): Promise<void> {
        await http.patch(`${this.path}/models/order`, params);
    }

    static async updateModelStatus(id: string, params: Pick<Model, 'status'>): Promise<void> {
        await http.patch(`${this.path}/models/${id}/status`, params);
    }

    static async updateModelData(id: string, params: Omit<UpdateModelData, 'modelId'>): Promise<Model> {
        const { data } = await http.put(`${this.path}/models/${id}`, params);
        return data
    }

    static async deleteModel(id: string): Promise<void> {
        await http.delete(`${this.path}/models/${id}`);
    }

    static async syncModels(id: string): Promise<void> {
        await http.post(`${this.path}/models/${id}/synchronize/prod`)
    }

}

export default ModelApi