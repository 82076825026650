import { Nullable } from '../../types/core';
import { ModelProperty } from '../../types/modelProperty';
import { StoreData } from '../../types/module';
import { initialStore } from '../../utils/store.util';


export interface IPropertyState {
    currentModelProperty: StoreData<Nullable<ModelProperty>>
    modelProperties: StoreData<ModelProperty[]>
}

export const initialState: IPropertyState = {
    currentModelProperty: initialStore<Nullable<ModelProperty>>(null),
    modelProperties: initialStore<ModelProperty[]>([])
}