import { FunctionComponent } from 'react'
import ChevronRightIcon from '../Icons/ChevronRightIcon'

type Props = {
  isOpen: boolean
  title: string
}

const HeaderProductSettingAccordion: FunctionComponent<Props> = ({ isOpen, title }) => {
  return (
    <div className='flex items-center space-x-3 py-3  w-full'>
      <p className='text-xl'> {title}</p>
      <ChevronRightIcon className={`${isOpen ? 'rotate-90' : ''} w-6 h-6 transform duration-100`} />
    </div>
  )
}

export default HeaderProductSettingAccordion
