import { PlusCircleIcon } from 'lucide-react'
import { FunctionComponent } from 'react'

type Props = {
  onClick: () => void
}

const ImageButtonUpload: FunctionComponent<Props> = ({ onClick }) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className='h-[130px] rounded bg-[#F4F4F4] flex flex-col space-y-2 items-center justify-center text-primary outline-none'
    >
      <PlusCircleIcon className='w-7 h-7' />
      <p>Upload image</p>
      {/* <input type='file' id='actual-btn' className='hidden' />
      <label
        htmlFor='actual-btn'
        className='h-[120px] rounded bg-[#F4F4F4] flex flex-col space-y-2 items-center justify-center text-primary'
      >
        <PlusCircleIcon className='w-7 h-7' />
        <p>Upload image</p>
      </label> */}
    </button>
  )
}

export default ImageButtonUpload
