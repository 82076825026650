import { Dialog } from '@headlessui/react'
import { DeleteIcon, TrashIcon } from 'lucide-react'
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Nullable } from '../../../modules/types/core'
import { CreateImage, ImageCategory } from '../../../modules/types/image'
import FlatButton from '../../atoms/Button/FlatButton'
import OutlinedButton from '../../atoms/Button/OutlinedButton'
import FormField from '../../atoms/FormField'
import Input from '../../atoms/Input'
import Selector from '../../atoms/Selector'
import TextError from '../../atoms/TextError'

import Modal from '../Modal'

type Props = {
  isOpen: boolean
  //   mode: FormMode
  categoryId: string
  onSubmit: (val: CreateImage) => void
  onClose: () => void
}

type InputImageForm = {
  name: string
  file: File
}

const ImageFormModal: FunctionComponent<Props> = ({ isOpen, categoryId, onClose, onSubmit }) => {
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState: { errors, isSubmitted },
  } = useForm<InputImageForm>()

  const [selectedImage, setSelectedImage] =
    useState<Nullable<{ src: string; name: string; file: File }>>(null)
  const handleOnClose = useCallback(() => {
    onClose()
    reset()
    setSelectedImage(null)
  }, [])

  const onSubmitData = (val: InputImageForm) => {
    if (!selectedImage?.file) return
    onSubmit({ ...val, categoryId, file: selectedImage?.file as File })
  }

  const onChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return

    const file = event.target.files[0]
    // console.log('file: ', file)
    // setValue('file', file)
    const reader = new FileReader()

    reader.onload = function (e) {
      setSelectedImage({ src: String(e.target?.result || ''), name: file.name, file })
    }

    reader.readAsDataURL(file)
  }

  const onDeleteImage = () => {
    setSelectedImage(null)
    resetField('file')
  }

  useEffect(() => {
    if (isOpen) return
    handleOnClose()
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all z-30'>
        <Dialog.Title>Image Gallery</Dialog.Title>
        <form onSubmit={handleSubmit(onSubmitData)} className='w-full'>
          <div className='py-5 flex flex-col space-y-5'>
            <FormField label='Image Name'>
              <Input {...register('name', { required: true })} />
              <TextError>{errors.name && 'Image name is required'}</TextError>
            </FormField>
            <div className='flex flex-col space-y-5'>
              <div className='flex'>
                <div className='flex-1'>
                  <p className='font-semibold text'>Image File</p>
                </div>
                <div>
                  <input
                    id='select-file-btn'
                    type='file'
                    className='hidden'
                    {...register('file')}
                    accept='image/png, image/jpeg'
                    onChange={onChangeImage}
                  />
                  <label htmlFor='select-file-btn' className='px-3 py-2 round text-primary'>
                    Choose Image
                  </label>
                </div>
              </div>
              {selectedImage && (
                <div className='flex items-center border rounded px-2 py-4 space-x-3'>
                  <img className='w-20 object-cover' src={selectedImage.src} />
                  <div className='flex-1'>
                    <p className='text-ellipsis'>{selectedImage.name}</p>
                  </div>
                  <button type='button' className=' text-primary' onClick={onDeleteImage}>
                    <TrashIcon />
                  </button>
                </div>
              )}
              <TextError>{!selectedImage && isSubmitted ? 'Image file is required' : ''}</TextError>
            </div>
            {/* <FormField label='Image File'>
              <div className='flex border rounded p-3 '>
                <div className='flex-1 flex'>
                  <image className=' w-20 object-cover' />
                  <div className='flex-1'>
                    <p></p>
                  </div>
                </div>
                <div>
                  <input
                    id='select-file-btn'
                    type='file'
                    {...register('file', { required: true })}
                    onChange={}
                  />
                  <label htmlFor='select-file-btn' className='px-3 py-2 round text-primary'>
                    Choose Image
                  </label>
                </div>
              </div>
              <TextError>{errors.file && 'Image name is required'}</TextError>
            </FormField> */}
          </div>
          <div className='mt-4 flex space-x-5 justify-end'>
            <OutlinedButton type='button' onClick={handleOnClose}>
              Cancel
            </OutlinedButton>
            <FlatButton type='submit'>Confirm</FlatButton>
          </div>
        </form>
      </Dialog.Panel>
    </Modal>
  )
}

export default ImageFormModal
