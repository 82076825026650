import { FunctionComponent, memo, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'

import { ModelProperty } from '../../../modules/types/modelProperty'

import DropdownIconButton from '../../atoms/DropdownIconButton'
import ChevronRightIcon from '../../atoms/Icons/ChevronRightIcon'
import EllipsisVertical from '../../atoms/Icons/EllipsisVertical'
import Switcher from '../../atoms/Switcher'
import ModelPropertyItem from '../../molecules/ModelPropertyItem'

type Props = {
  data: ModelProperty[]
  onChangeOrder?: (items: ModelProperty[]) => void
  onSelectPart?: (val: ModelProperty) => void
  onEdit?: (val: ModelProperty) => void
  onDelete?: (id: string) => void
  onChangeStatus: (id: string, status: boolean) => void
}
const reorder = (list: ModelProperty[], startIndex: number, endIndex: number): ModelProperty[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const DndPropertyModel: FunctionComponent<Props> = ({
  data,
  onChangeOrder,
  onSelectPart,
  onEdit,
  onDelete,
  onChangeStatus,
}) => {
  const [items, setItems] = useState<ModelProperty[]>([])

  function onDragEnd({ destination, source }: DropResult) {
    if (!destination) {
      return
    }

    if (destination.index === source.index) {
      return
    }

    const newOrderingList = reorder(items, source.index, destination.index)
    const updateOrder = newOrderingList.map((ele, index) => ({ ...ele, order: index + 1 }))
    if (onChangeOrder) onChangeOrder(updateOrder)
    setItems(updateOrder)
  }

  function handleSelectPart(val: ModelProperty) {
    if (onSelectPart) onSelectPart(val)
  }

  function handleOnSelectMenu(key: string, index: number, val: ModelProperty) {
    if (key === 'on-edit' && onEdit) onEdit(val)
    if (key === 'on-delete' && onDelete) onDelete(val.propertyId)
  }

  useEffect(() => {
    setItems(data || [])
  }, [data])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <Draggable key={item.propertyId} draggableId={item.propertyId} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ModelPropertyItem
                      value={item}
                      onChangeStatus={onChangeStatus}
                      onSelectMenu={(key, v) => handleOnSelectMenu(key, index, v)}
                      onSelectPart={handleSelectPart}
                    />
                    {/* <div className='flex-1 flex space-x-4 items-center'>
                      <Switcher
                        enabled={item.status}
                        onChange={(v) => onChangeStatus(item.modelId, v)}
                      />
                      <img className='h-[58px] object-cover' src={item.image} />
                      <div className='flex flex-col'>
                        <p className='text-xl'>{item.name}</p>
                        <p className='text-sm text-primary-light'>{item.default && 'Default'}</p>
                      </div>
                    </div>
                    <div className='flex-1 flex space-x-5 justify-around'>
                      <div className='flex flex-col space-y-1'>
                        <p className=' inline-flex space-x-2'>
                          <span className=' font-bold'>Action type : </span>
                          <span>{item.actionType}</span>
                        </p>
                        <p className=' inline-flex space-x-2'>
                          <span className=' font-bold'>Sub group : </span>
                          <span>{item.formId || '-'}</span>
                        </p>
                      </div>
                    </div>
                    <div className='flex-1'>
                      <p>
                        <span className='font-bold'>Price (USD) :</span>
                        <span>{item.price.usd}</span>
                      </p>
                    </div>
                    <DropdownIconButton
                      icon={<EllipsisVertical />}
                      menus={[
                        {
                          key: 'on-edit',
                          label: 'Edit',
                        },
                        {
                          key: 'on-delete',
                          label: 'Delete',
                        },
                      ]}
                      onSelected={(key) => handleOnSelectMenu(key, index, item)}
                    />
                    <button className='m-0' onClick={() => handleSelectPart(item)}>
                      <ChevronRightIcon />
                    </button> */}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default memo(DndPropertyModel)
