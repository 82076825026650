import { FunctionComponent, memo } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { FormGroup } from '../../../modules/types/customizationPart'

import DropdownIconButton from '../../atoms/DropdownIconButton'
import ChevronRightIcon from '../../atoms/Icons/ChevronRightIcon'
import EllipsisVertical from '../../atoms/Icons/EllipsisVertical'

type Props = {
  items: FormGroup[]
  onChangeOrder?: (items: FormGroup[]) => void
  onSelectPart?: (val: FormGroup) => void
  onEdit?: (val: FormGroup) => void
  onDelete?: (id: string) => void
}
const reorder = (list: FormGroup[], startIndex: number, endIndex: number): FormGroup[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const DndCustomizationGroupForm: FunctionComponent<Props> = ({
  items,
  onChangeOrder,
  onSelectPart,
  onEdit,
  onDelete,
}) => {
  function onDragEnd({ destination, source }: DropResult) {
    if (!destination) {
      return
    }

    if (destination.index === source.index) {
      return
    }

    const newOrderingList = reorder(items, source.index, destination.index)
    if (onChangeOrder) onChangeOrder(newOrderingList)
  }

  function handleSelectPart(val: FormGroup) {
    if (onSelectPart) onSelectPart(val)
  }

  function handleOnSelectMenu(key: string, val: FormGroup) {
    if (key === 'on-edit' && onEdit) onEdit(val)
    if (key === 'on-delete' && onDelete) onDelete(val.formId)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <Draggable key={index} draggableId={index.toString()} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className='flex space-x-4 items-center px-5 py-3 bg-secondary-bg-color border my-2'
                  >
                    <div className='flex-1 space-x-5'>
                      <p className=' inline-flex space-x-2'>
                        <span className=' font-bold'>Sub Group ID :</span>
                        <span>{item.formId}</span>
                      </p>
                      <p className=' inline-flex space-x-2'>
                        <span className=' font-bold'>Sub Group Name :</span>
                        <span>{item.name}</span>
                      </p>
                    </div>
                    <DropdownIconButton
                      icon={<EllipsisVertical />}
                      menus={[
                        {
                          key: 'on-edit',
                          label: 'Edit',
                        },
                        {
                          key: 'on-delete',
                          label: 'Delete',
                        },
                      ]}
                      onSelected={(key) => handleOnSelectMenu(key, item)}
                    />
                    <button className='m-0' onClick={() => handleSelectPart(item)}>
                      <ChevronRightIcon />
                    </button>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default memo(DndCustomizationGroupForm)
