import { CreateTranslation, Translation, UpdateTranslation } from '../types/translation';
import http from '../../configs/http.config';

class TranslationApi {
    static readonly path: string = '/translations';

    static async getTranslations(): Promise<Translation[]> {
        const { data } = await http.get(this.path);
        return data;
    }

    static async createTranslation(params: CreateTranslation): Promise<Translation> {
        const { data } = await http.post(this.path, params);
        return data;
    }

    static async updateTranslation(id: string, params: Omit<UpdateTranslation, 'docId'>): Promise<Translation> {
        const { data } = await http.put(`${this.path}/${id}`, params);
        return data;
    }

    static async deleteTranslation(id: string): Promise<void> {
        await http.delete(`${this.path}/${id}`);
    }

    static async syncTranslation(): Promise<void> {
        await http.post(`${this.path}/synchronize/prod`);
    }

}

export default TranslationApi