import { FunctionComponent, memo } from 'react'

type Props = {
  title: string
  subtitles: string[]
}

const HeaderPage: FunctionComponent<Props> = ({ title, subtitles }) => {
  return (
    <div className='flex flex-col space-y-1'>
      <p className='text-4xl'>{title}</p>
      <p className='inline-flex text-sm text-secondary-font-color space-x-1'>
        {subtitles
          .join(' > ')
          .split(' ')
          .map((t, index) => (
            <span key={index.toString()}>{t}</span>
          ))}
      </p>
    </div>
  )
}

export default memo(HeaderPage)
