import { Disclosure, Transition } from '@headlessui/react'

import { FunctionComponent, memo, useEffect, useState } from 'react'
import { Category } from '../../../modules/types/customizationPart'
import DropdownIconButton from '../../atoms/DropdownIconButton'
import EllipsisVertical from '../../atoms/Icons/EllipsisVertical'
import Switcher from '../../atoms/Switcher'
import TranslationInfoPanel from '../TranslationInfoPanel'

type Props = {
  category: Category
  onChangeStatus?: (id: string, val: boolean) => void
  onEdit?: (val: Category) => void
  onDelete?: (val: string) => void
}

const CategoryCard: FunctionComponent<Props> = ({ category, onChangeStatus, onEdit, onDelete }) => {
  const [enabled, setEnabled] = useState(category.active)
  const menus = [
    {
      key: 'on-edit',
      label: 'Edit',
    },
    {
      key: 'on-delete',
      label: 'Delete',
    },
  ]

  function handleUpdateStatus(val: boolean) {
    // setEnabled(val)
    if (onChangeStatus) onChangeStatus(category.groupId, val)
  }

  function handleOnSelected(val: string) {
    if (val === 'on-edit' && onEdit) onEdit(category)
    else if (val === 'on-delete' && onDelete) onDelete(category.groupId)
  }

  useEffect(() => {
    const { active } = category
    if (typeof active === 'undefined') return

    setEnabled(active)
  }, [category?.active])

  return (
    <div className='border bg-white shadow'>
      <Disclosure>
        {({ open }) => (
          <>
            <div className='flex w-full items-center px-3 border-b py-5 space-x-5 text-left  font-medium'>
              <Switcher enabled={enabled} onChange={handleUpdateStatus} />
              <div className='flex-1'>
                <Disclosure.Button>
                  <p className='text-xl'>{category.name}</p>
                </Disclosure.Button>
              </div>
              <DropdownIconButton
                icon={<EllipsisVertical />}
                menus={menus}
                onSelected={handleOnSelected}
              />
            </div>
            <Transition
              show={open}
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <Disclosure.Panel static className='px-4 py-5'>
                <TranslationInfoPanel language={category.language} />
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default memo(CategoryCard)
