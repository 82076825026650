import { CreateModelProperty, ModelProperty, UpdateModelProperty, UpdateModelPropertyOrder } from '../types/modelProperty';

import http from '../../configs/http.config';
class ModelPropertyApi {
    private static readonly path: string = '/property-models';


    static async getModelProperty(id: string): Promise<ModelProperty> {
        const { data } = await http.get(`${this.path}/${id}`);
        return data
    }


    static async getModelPropertiesByModelId(modelId: string): Promise<ModelProperty[]> {
        const { data } = await http.get(`${this.path}`, { params: { modelId } });
        return data
    }


    static async getModelPropertyByCustomizationPartId(customizationPartId: string): Promise<ModelProperty[]> {
        const { data } = await http.get(`${this.path}/customizationParts/${customizationPartId}`);
        return data
    }

    static async createModelProperty(params: CreateModelProperty): Promise<ModelProperty> {
        const { data } = await http.post(`${this.path}`, params);
        return data
    }

    static async updateModelProperty(id: string, params: Omit<UpdateModelProperty, 'propertyId'>): Promise<ModelProperty> {
        const { data } = await http.put(`${this.path}/${id}`, params);
        return data;
    }

    static async updateModelPropertyOrder(params: UpdateModelPropertyOrder[]): Promise<void> {
        await http.patch(`${this.path}/orders`, params);
    }

    static async updateModelPropertyStatus(id: string, status: boolean): Promise<void> {
        await http.patch(`${this.path}/${id}/status`, { status });
    }

    static async deleteModelProperty(id: string): Promise<void> {
        await http.delete(`${this.path}/${id}`);
    }


}

export default ModelPropertyApi