import { FunctionComponent } from 'react'
import FlatButton from '../../atoms/Button/FlatButton'
import CloseIcon from '../../atoms/Icons/CloseIcon'

type Props = {
  title?: string
  onSave?: () => void
  onClose?: () => void
}

const ToolbarDrawer: FunctionComponent<Props> = ({ title, onSave, onClose }) => {
  return (
    <nav className='flex items-center space-x-2 bg-primary-bg-color shadow-lg border-b px-3 py-3'>
      <button type='button' onClick={onClose}>
        <CloseIcon />
      </button>
      <div className='flex-1 text-3xl'>{title}</div>
      <FlatButton type='button' onClick={onSave}>
        Save
      </FlatButton>
    </nav>
  )
}

export default ToolbarDrawer
