import { Configuration, ConfigurationType } from '../../../../../modules/types/modelProperty'

export const getPolygonType = (type: ConfigurationType) => {
    if (type === ConfigurationType.Visibility) {
        return 'Node Object'
    }
    return 'Material'
}

export const getValueFromProperty = (config: Configuration): string | number => {
    const { type, color, texture, visible, glossiness, roughness, metalness } = config
    switch (type) {
        case ConfigurationType.Color: {
            return color || ''
        }
        case ConfigurationType.Texture: {
            return String(texture?.name) || ''
        }
        case ConfigurationType.Glossiness: {
            return glossiness || ''
        }
        case ConfigurationType.Metalness: {
            return metalness || ''
        }
        case ConfigurationType.Roughness: {
            return roughness || ''
        }
        case ConfigurationType.Visibility: {
            return visible ? 'show' : 'hidden'
        }
        default: {
            return ''
        }
    }
}