import { FunctionComponent } from 'react'
import MainBody from '../../organisms/MainBody'
import SideBar from '../../organisms/Sidebar'
import PropsWithChildren from '../../types'
const RootNavigatorTemplate: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <div className='h-screen w-screen flex'>
      <SideBar />
      <div className='flex-1'>
        <MainBody>{children}</MainBody>
      </div>
    </div>
  )
}

export default RootNavigatorTemplate
