import { Dialog } from '@headlessui/react'
import { DeleteIcon } from 'lucide-react'
import { FunctionComponent, memo } from 'react'
import FlatButton from '../../atoms/Button/FlatButton'
import OutlinedButton from '../../atoms/Button/OutlinedButton'
import Modal from '../Modal'

type Props = {
  isOpen: boolean
  value: any
  onClose: () => void
  onConfirm: (value?: any) => void
}

const ConfirmDeleteModal: FunctionComponent<Props> = ({ isOpen, value, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
        <Dialog.Title
          as='h3'
          className='text-lg inline-flex space-x-5 items-center font-medium leading-6 text-gray-900 pb-2 border-b'
        >
          <span>
            <DeleteIcon />
          </span>
          <span>Are you sure you want to delete this item?</span>
        </Dialog.Title>
        <div className='my-5'>
          <p className='text-sm text-gray-500'>
            Please press the confirm button if you are sure to delete this item.
          </p>
        </div>
        <div className='mt-4 flex space-x-5 justify-end'>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <FlatButton
            type='button'
            onClick={() => {
              onConfirm(value)
              onClose()
            }}
          >
            Confirm
          </FlatButton>
        </div>
      </Dialog.Panel>
    </Modal>
  )
}

export default memo(ConfirmDeleteModal)
