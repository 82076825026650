import { FunctionComponent } from 'react'
import { Outlet } from 'react-router-dom'
import GuardAuthenticationTemplate from './components/templates/GuardAuthentication'
import RootNavigatorTemplate from './components/templates/RootNavigatorTemplate'

const RootMainPage: FunctionComponent = () => {
  return (
    <GuardAuthenticationTemplate>
      <RootNavigatorTemplate>
        <Outlet />
      </RootNavigatorTemplate>
    </GuardAuthenticationTemplate>
  )
}

export default RootMainPage
