import { FunctionComponent, memo, useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import Drawer from 'react-modern-drawer'
import { ErrorInputType } from '../../../modules/types/constant'
import { EnvironmentConfiguration } from '../../../modules/types/modelProperty'
import { getErrorInputMessage } from '../../../modules/utils/errorHandler.util'
import { colorPattern } from '../../../utils/validation.util'
import DropdownColorPicker from '../../atoms/DropdownColorPicker'
import FormField from '../../atoms/FormField'
import Input from '../../atoms/Input'
import TextError from '../../atoms/TextError'
import { FormMode } from '../../types'
import RootMainTemplate from '../RootMainTemplate'

export type ThemeSettingForm = Pick<
  EnvironmentConfiguration,
  'backgroundColor' | 'button' | 'primaryFontColor'
> & {
  bgBegin?: string
  bgEnd?: string
}

type Props = {
  isOpen: boolean
  mode: FormMode
  value?: ThemeSettingForm
  onSubmit: (val: ThemeSettingForm) => void
  onClose: () => void
}

const defaultColor = '#FFFFFF'
const DrawerThemeSettingForm: FunctionComponent<Props> = ({
  isOpen,
  mode,
  value,
  onSubmit,
  onClose,
}) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<ThemeSettingForm>()

  const buttonRef = useRef<HTMLButtonElement>(null)

  const bgBeginValue = watch('bgBegin')
  const bgEndValue = watch('bgEnd')
  const backgroundColorValue = watch('backgroundColor')
  const primaryFontColorValue = watch('primaryFontColor')
  const buttonBackgroundColorValue = watch('button.backgroundColor')
  const buttonForegroundColorValue = watch('button.foregroundColor')
  const buttonBorderValue = watch('button.border')

  const handleOnClickSave = useCallback(() => {
    buttonRef.current?.click()
  }, [])

  const onCloseDrawer = useCallback(() => {
    reset()
    onClose()
  }, [onClose])

  const handleOnSubmitData = useCallback(
    (values: ThemeSettingForm) => {
      if (onSubmit) onSubmit(values)
      onCloseDrawer()
    },
    [onSubmit, onCloseDrawer],
  )

  useEffect(() => {
    if (!isOpen) return

    if (value && mode === FormMode.Update) {
      setValue('bgBegin', value.bgBegin)
      setValue('bgEnd', value.bgEnd)
      setValue('backgroundColor', value.backgroundColor)
      setValue('primaryFontColor', value.primaryFontColor)
      setValue('button.backgroundColor', value.button?.backgroundColor || '')
      setValue('button.foregroundColor', value.button?.foregroundColor || '')
      setValue('button.border', value.button?.border || '')
    } else if (mode === FormMode.Add) {
      setValue('bgBegin', defaultColor)
      setValue('bgEnd', defaultColor)
      setValue('backgroundColor', defaultColor)
      setValue('primaryFontColor', defaultColor)
      setValue('button.backgroundColor', defaultColor)
      setValue('button.foregroundColor', defaultColor)
      setValue('button.border', defaultColor)
    }
  }, [isOpen, value, mode])

  return (
    <Drawer size={650} open={isOpen} onClose={onCloseDrawer} direction='right' className=''>
      <RootMainTemplate title='Add/Edit Theme' onClose={onCloseDrawer} onSave={handleOnClickSave}>
        <form onSubmit={handleSubmit(handleOnSubmitData)}>
          <div className='flex flex-col items-stretch space-y-5 p-5'>
            <div className='flex gap-5 flex-wrap'>
              <FormField label='Panel Background Gradient Color #1'>
                <div className='flex items-start space-x-3'>
                  <div className='flex-1'>
                    <Input
                      className='w-full'
                      {...register('bgBegin', {
                        required: {
                          value: true,
                          message: getErrorInputMessage(
                            ErrorInputType.Required,
                            'Panel Background Gradient Color #1',
                          ),
                        },
                        pattern: {
                          value: colorPattern,
                          message: getErrorInputMessage(
                            ErrorInputType.Pattern,
                            'Panel Background Gradient Color #1',
                          ),
                        },
                      })}
                    />
                  </div>
                  <DropdownColorPicker
                    value={bgBeginValue || ''}
                    onSelected={(v) => setValue('bgBegin', v)}
                  />
                </div>
                <TextError>{errors?.bgBegin && errors.bgBegin?.message}</TextError>
              </FormField>
              <FormField label='Panel Background Gradient Color #2'>
                <div className='flex items-start space-x-3'>
                  <div className='flex-1'>
                    <Input
                      className='w-full'
                      {...register('bgEnd', {
                        required: {
                          value: true,
                          message: getErrorInputMessage(
                            ErrorInputType.Required,
                            'Panel Background Gradient Color #2 is required',
                          ),
                        },
                        pattern: {
                          value: colorPattern,
                          message: getErrorInputMessage(
                            ErrorInputType.Pattern,
                            'Panel Background Gradient Color #2 is invalid format.',
                          ),
                        },
                      })}
                    />
                  </div>
                  <DropdownColorPicker
                    value={bgEndValue || ''}
                    onSelected={(v) => setValue('bgEnd', v)}
                  />
                </div>
                <TextError>{errors?.bgEnd && errors?.bgEnd?.message}</TextError>
              </FormField>
              <FormField label='Part Background Color'>
                <div className='flex items-start space-x-3'>
                  <div className='flex-1'>
                    <Input
                      className='w-full'
                      {...register('backgroundColor', {
                        required: {
                          value: true,
                          message: getErrorInputMessage(
                            ErrorInputType.Required,
                            'Part Background Color is required',
                          ),
                        },
                        pattern: {
                          value: colorPattern,
                          message: getErrorInputMessage(
                            ErrorInputType.Pattern,
                            'Part Background Color is invalid format',
                          ),
                        },
                      })}
                    />
                  </div>
                  <DropdownColorPicker
                    value={backgroundColorValue || ''}
                    onSelected={(v) => setValue('backgroundColor', v)}
                  />
                </div>
                <TextError>{errors?.backgroundColor && errors?.backgroundColor.message}</TextError>
              </FormField>
              <FormField label='Text Color'>
                <div className='flex space-x-5'>
                  <div className='flex-1'>
                    <Input
                      className='w-full'
                      {...register('primaryFontColor', {
                        required: {
                          value: true,
                          message: getErrorInputMessage(
                            ErrorInputType.Required,
                            'Text Color is required',
                          ),
                        },
                        pattern: {
                          value: colorPattern,
                          message: getErrorInputMessage(
                            ErrorInputType.Pattern,
                            'Text Color is invalid format',
                          ),
                        },
                      })}
                    />
                  </div>
                  <DropdownColorPicker
                    value={primaryFontColorValue || ''}
                    onSelected={(v) => setValue('primaryFontColor', v)}
                  />
                </div>
                <TextError>{errors?.primaryFontColor && errors.primaryFontColor.message}</TextError>
              </FormField>
              <FormField label='Button Background Color'>
                <div className='flex space-x-5'>
                  <div className='flex-1'>
                    <Input
                      className='w-full'
                      {...register('button.backgroundColor', {
                        required: {
                          value: true,
                          message: getErrorInputMessage(
                            ErrorInputType.Required,
                            'Button Background Color is required',
                          ),
                        },
                        pattern: {
                          value: colorPattern,
                          message: getErrorInputMessage(
                            ErrorInputType.Pattern,
                            'Button Background Color is invalid format',
                          ),
                        },
                      })}
                    />
                  </div>
                  <DropdownColorPicker
                    value={buttonBackgroundColorValue || ''}
                    onSelected={(v) => setValue('button.backgroundColor', v)}
                  />
                </div>
                <TextError>
                  {errors?.button?.backgroundColor && errors?.button?.backgroundColor.message}
                </TextError>
              </FormField>
              <FormField label='Button Foreground Color'>
                <div className='flex space-x-5'>
                  <div className='flex-1'>
                    <Input
                      className='w-full'
                      {...register('button.foregroundColor', {
                        required: {
                          value: true,
                          message: getErrorInputMessage(
                            ErrorInputType.Required,
                            'Button Foreground Color is required',
                          ),
                        },
                        pattern: {
                          value: colorPattern,
                          message: getErrorInputMessage(
                            ErrorInputType.Pattern,
                            'Button Foreground Color is invalid format',
                          ),
                        },
                      })}
                    />
                  </div>
                  <DropdownColorPicker
                    value={buttonForegroundColorValue || ''}
                    onSelected={(v) => setValue('button.foregroundColor', v)}
                  />
                </div>
                <TextError>
                  {errors?.button?.foregroundColor && errors?.button?.foregroundColor?.message}
                </TextError>
              </FormField>
              <FormField label='Button Border Color'>
                <div className='flex space-x-5'>
                  <div className='flex-1'>
                    <Input
                      className='w-full'
                      {...register('button.border', {
                        required: {
                          value: true,
                          message: getErrorInputMessage(
                            ErrorInputType.Required,
                            'Button Border Color is required',
                          ),
                        },
                        pattern: {
                          value: colorPattern,
                          message: getErrorInputMessage(
                            ErrorInputType.Pattern,
                            'Button Border Color is required',
                          ),
                        },
                      })}
                    />
                  </div>
                  <DropdownColorPicker
                    value={buttonBorderValue || ''}
                    onSelected={(v) => setValue('button.border', v)}
                  />
                </div>
                <TextError>{errors?.button?.border && errors?.button?.border?.message}</TextError>
              </FormField>
            </div>
          </div>
          <button ref={buttonRef} type='submit' className='hidden'></button>
        </form>
      </RootMainTemplate>
    </Drawer>
  )
}

export default memo(DrawerThemeSettingForm)
