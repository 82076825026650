import {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import Drawer from 'react-modern-drawer'

import { useAppDispatch, useAppSelector } from '../../../modules/hook'
import {
  createCustomizationPart,
  deleteCustomizationPartFormGroup,
  fetchCategoriesList,
  fetchCustomizationPartById,
  updateCustomizationPart,
} from '../../../modules/stores/customizationParts/action'
import { actionTypes, formType } from '../../../modules/types/constant'

import {
  ActionType,
  CreateCustomizationPart,
  CustomizationPart,
  DisplayLayout,
  FormGroup,
  FormType,
  UpdateCustomizationPart,
} from '../../../modules/types/customizationPart'
import { toast } from 'react-toastify'

import { ModelProperty } from '../../../modules/types/modelProperty'
import { StateData } from '../../../modules/types/module'
import { Translation } from '../../../modules/types/translation'
import FlatButton from '../../atoms/Button/FlatButton'

import OutlinedButton from '../../atoms/Button/OutlinedButton'
import Card from '../../atoms/Card'
import FormField from '../../atoms/FormField'
import Input from '../../atoms/Input'
import Selector from '../../atoms/Selector'
import LoadingDialog from '../../molecules/LoadingDialog'
import DndCustomizationGroupForm from '../../organisms/DndCustomizationGroupForm'
import DndPropertyModel from '../../organisms/DndPropertyModel'
import { FormInputDrawer, FormMode } from '../../types'
import DrawerModelPropertyForm from '../DrawerModelPropertyForm'
import DrawerSubGroupForm from '../DrawerSubGroupForm'
import DrawerTranslation from '../DrawerTranslation'
import RootMainTemplate from '../RootMainTemplate'
import StateTemplateV2 from '../StateTemplateV2'
import useToastMessage from '../../../hooks/useToastMessage.hook'
import {
  deleteModelProperty,
  fetchModelPropertiesByCustomizationPart,
  updateModelPropertyOrders,
  updateModelPropertyStatus,
} from '../../../modules/stores/properties/action'
import ConfirmDeleteModal from '../../molecules/ConfirmDeleteModal'
import useConfirmDelete from '../../../hooks/useConfirmDelete.hook'
import { resetCurrentCustomization } from '../../../modules/stores/customizationParts'
import { resetModelProperties } from '../../../modules/stores/properties'

type Props = {
  isOpen: boolean
  onClose: () => void
  mode: FormMode
  modelId: string
  customizationPartId?: string
}

type CustomizationForm = Omit<CreateCustomizationPart, 'modelId'>

const DrawerCustomizationPartForm: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  mode,
  modelId,
  customizationPartId,
}) => {
  const { register, setValue, handleSubmit, reset, watch } = useForm<CustomizationForm>()
  const dispatch = useAppDispatch()

  const buttonRef = useRef<HTMLButtonElement>(null)
  const translationIdValue = watch('translationId')

  const { showError, showSuccess } = useToastMessage()
  const [isOpenLoadingDialog, setIsOpenLoadingDialog] = useState<boolean>(false)
  const [isOpenTranslationDrawer, setIsOpenTranslationDrawer] = useState<boolean>(false)
  const [openSubGroupDrawer, setOpenSubGroupDrawer] = useState<{
    status: boolean
    mode: FormMode
    value?: FormGroup
  }>({ status: false, mode: FormMode.Add })

  const [openModelPropertyDrawer, setOpenModelPropertyDrawer] = useState<FormInputDrawer<string>>({
    status: false,
    mode: FormMode.Idle,
  })

  const { openConfirmDelete, onOpenDeleteModal, onCloseDeleteModal } = useConfirmDelete()

  const confirmDeleteSubGroup = useConfirmDelete()
  // const [openConfirmDelete, setOpenConfirmDelete] = useState<ConfirmDeleteType<string>>({ status: true })

  const { currentModelCustomizationPart, categories, modelProperties } = useAppSelector(
    (state) => ({
      currentModelCustomizationPart: state.customizationParts.currentModelCustomizationPart,
      categories: state.customizationParts.categories,
      modelProperties: state.property.modelProperties,
    }),
  )

  const [subGroups, setSubGroups] = useState<FormGroup[]>([])
  //   const [selectedSubGroup, setSelectedSubGroup] = useState<FormGroup>()

  // const [modelProperties, setModelProperties] = useState<ModelProperty[]>([])

  const handleOpenTranslationDrawer = useCallback(() => {
    setIsOpenTranslationDrawer(true)
  }, [])

  const handleCloseTranslationDrawer = useCallback(() => {
    setIsOpenTranslationDrawer(false)
  }, [])

  const handleOnOpenSubGroupDrawer = useCallback((mode: FormMode, value?: FormGroup) => {
    setOpenSubGroupDrawer({ status: true, mode, value })
  }, [])

  const handleOnAddSubGroup = useCallback(() => {
    setOpenSubGroupDrawer({ status: true, mode: FormMode.Add })
  }, [])

  const handleOnCloseSubGroupDrawer = useCallback(() => {
    setOpenSubGroupDrawer({ status: false, mode: FormMode.Add })
  }, [])

  const handleOnEditSubGroup = useCallback((val: FormGroup) => {
    handleOnOpenSubGroupDrawer(FormMode.Update, val)
  }, [])

  // const handleOnDeleteSubGroup = useCallback((index: number) => {
  //   setSubGroups((prev) => prev.splice(index, 1))
  // }, [])

  const handleOnChangeModelPropertyStatus = useCallback(async (id: string, status: boolean) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(updateModelPropertyStatus({ propertyId: id, status })).unwrap()
      showSuccess('Update status successfully')
    } catch (error) {
      showError('Update status failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const handleOnChangeModelPropertyOrder = useCallback(async (items: ModelProperty[]) => {
    try {
      setIsOpenLoadingDialog(true)
      const payload = items.map(({ propertyId, order }) => ({ propertyId, order }))
      await dispatch(updateModelPropertyOrders(payload)).unwrap()
      showSuccess('Update order successfully')
    } catch (error) {
      showError('Update order failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const onDeleteSubGroup = useCallback(
    async (formId: string) => {
      try {
        setIsOpenLoadingDialog(true)
        await dispatch(
          deleteCustomizationPartFormGroup({ id: String(customizationPartId), formId }),
        ).unwrap()
        showSuccess('Delete Form Group Successfully')
      } catch (error) {
        showError('Delete Form Group Failed')
      } finally {
        setIsOpenLoadingDialog(false)
      }
    },
    [customizationPartId],
  )

  const handleOnEditPropertyModel = useCallback((value: ModelProperty) => {
    setOpenModelPropertyDrawer({ status: true, mode: FormMode.Update, value: value.propertyId })
  }, [])

  const handleOnAddPropertyModel = useCallback(() => {
    setOpenModelPropertyDrawer({ status: true, mode: FormMode.Add })
  }, [])

  const onDeletePropertyModel = useCallback(async (id: string) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(deleteModelProperty(id)).unwrap()
      showSuccess('Delete model property successfully')
    } catch (error) {
      showError('Delete model property failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const handleOnClosePropertyModel = useCallback(() => {
    setOpenModelPropertyDrawer({ status: false, mode: FormMode.Idle })
  }, [])

  const handleOnSubmitTranslation = useCallback(
    (val: Translation) => {
      setValue('name', val.name)
      setValue('translationId', val.docId)
    },
    [setValue],
  )

  const handleOnClickSave = useCallback(() => {
    buttonRef.current?.click()
  }, [])

  const onSubmitForm = useCallback(
    (val: CustomizationForm) => {
      if (mode === FormMode.Add)
        onAddCustomizationPart({ ...val, modelId, displayLayout: DisplayLayout.GridSelectorLayout })
      else if (mode === FormMode.Update)
        onUpdateCustomizationPart({
          ...val,
          modelId,
          displayLayout: DisplayLayout.GridSelectorLayout,
          partId: customizationPartId as string,
        })
    },
    [subGroups, mode, modelId],
  )

  const onCloseDrawer = useCallback(() => {
    onClose()
    reset()
    dispatch(resetCurrentCustomization())
    dispatch(resetModelProperties())
  }, [])

  const onAddCustomizationPart = useCallback(
    async (val: CreateCustomizationPart) => {
      try {
        setIsOpenLoadingDialog(true)
        await dispatch(createCustomizationPart(val)).unwrap()
        showSuccess('Create Customization Part Success')

        onCloseDrawer()
      } catch (error) {
        showError('Create Customization Part Failed')
      } finally {
        setIsOpenLoadingDialog(false)
      }
    },
    [onCloseDrawer],
  )

  const onUpdateCustomizationPart = useCallback(
    async (val: UpdateCustomizationPart) => {
      try {
        setIsOpenLoadingDialog(true)
        await dispatch(updateCustomizationPart(val)).unwrap()
        showSuccess('Update Customization Part Success')

        onCloseDrawer()
      } catch (error) {
        showError('Update Customization Part Failed')
      } finally {
        setIsOpenLoadingDialog(false)
      }
    },
    [onCloseDrawer],
  )

  const isLoadingState = useMemo(() => {
    return mode === FormMode.Update && currentModelCustomizationPart.state === StateData.Loading
  }, [mode, currentModelCustomizationPart.state])

  const isSuccessState = useMemo(() => {
    return (
      mode === FormMode.Add ||
      (mode === FormMode.Update && currentModelCustomizationPart.state === StateData.Success)
    )
  }, [mode, currentModelCustomizationPart.state])

  const isUpdateForm = useMemo(() => mode === FormMode.Update, [mode])

  useEffect(() => {
    if (mode === FormMode.Add || !isOpen || !customizationPartId) return

    if (
      currentModelCustomizationPart.state === StateData.Idle ||
      currentModelCustomizationPart?.data?.partId !== customizationPartId
    ) {
      dispatch(fetchCustomizationPartById(customizationPartId))
    }

    if (
      modelProperties.state === StateData.Idle ||
      currentModelCustomizationPart?.data?.partId !== customizationPartId
    ) {
      dispatch(fetchModelPropertiesByCustomizationPart(customizationPartId))
    }

    // dispatch(fetchCustomizationPartById(customizationPartId))

    // dispatch(fetchModelPropertiesByCustomizationPart(customizationPartId))
  }, [customizationPartId, isOpen, currentModelCustomizationPart])

  useEffect(() => {
    if (mode === FormMode.Add || !isOpen) return
    const { state, data } = currentModelCustomizationPart
    if (state !== StateData.Success) return
    setValue('name', data?.name || '')
    setValue('translationId', data?.translationId || '')
    setValue('formType', data?.formType || FormType.Single)
    setValue('actionType', data?.actionType || ActionType.selector)
    setValue('categoryId', data?.categoryId || '')

    setSubGroups(data?.formGroups || [])
  }, [currentModelCustomizationPart, mode, isOpen])

  useEffect(() => {
    if (categories.state === StateData.Idle) dispatch(fetchCategoriesList())
  }, [categories.state])

  useEffect(() => {
    return () => {
      dispatch(resetCurrentCustomization())
      dispatch(resetModelProperties())
    }
  }, [])

  return (
    <>
      <Drawer size='100vh' open={isOpen} onClose={onCloseDrawer} direction='bottom' zIndex={0}>
        <RootMainTemplate
          title='Customization Parts'
          onClose={onCloseDrawer}
          onSave={handleOnClickSave}
        >
          <StateTemplateV2 isLoading={isLoadingState} isSuccess={isSuccessState}>
            <div className='container mx-auto flex flex-col space-y-5 py-5'>
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <Card className='flex flex-col space-y-5 px-5 py-5'>
                  <p className='text-xl'>Customization Parts Setting</p>
                  <div className='flex space-x-5 items-start'>
                    <FormField label='Part Name'>
                      <div className='flex space-x-5'>
                        <div className='flex-1'>
                          <Input
                            {...register('name', { required: true })}
                            disabled
                            className='w-full'
                          />
                          <input className='hidden' {...register('translationId')} />
                        </div>
                        <OutlinedButton type='button' onClick={handleOpenTranslationDrawer}>
                          Translation
                        </OutlinedButton>
                      </div>
                    </FormField>
                    <FormField label='Category'>
                      <Selector
                        {...register('categoryId', { required: true })}
                        options={categories.data.map(({ groupId, name }) => ({
                          value: groupId,
                          label: name,
                        }))}
                      />
                    </FormField>
                    <FormField label='Form Type'>
                      <Selector {...register('formType', { required: true })} options={formType} />
                    </FormField>
                    <FormField label='Action Type'>
                      <Selector
                        {...register('actionType', { required: true })}
                        options={actionTypes}
                      />
                    </FormField>
                    <button className='hidden' ref={buttonRef}></button>
                  </div>
                </Card>
              </form>
              {isUpdateForm && (
                <Card className='flex flex-col space-y-5 px-5 py-5'>
                  <div className='flex items-center justify-between'>
                    <p className='text-xl'>Manage Sub Group</p>
                    <FlatButton onClick={handleOnAddSubGroup}>Add</FlatButton>
                  </div>
                  <DndCustomizationGroupForm
                    items={subGroups}
                    onEdit={handleOnEditSubGroup}
                    onDelete={confirmDeleteSubGroup.onOpenDeleteModal}
                  />
                </Card>
              )}

              {isUpdateForm && (
                <Card className='flex flex-col space-y-5 px-5 py-5'>
                  <div className='flex items-center justify-between'>
                    <p className='text-xl'>Manage Properties</p>
                    <FlatButton onClick={handleOnAddPropertyModel}>Add</FlatButton>
                  </div>
                  <DndPropertyModel
                    data={modelProperties.data}
                    onChangeStatus={handleOnChangeModelPropertyStatus}
                    onChangeOrder={handleOnChangeModelPropertyOrder}
                    onEdit={handleOnEditPropertyModel}
                    onDelete={onOpenDeleteModal}
                  />
                </Card>
              )}
            </div>
          </StateTemplateV2>
        </RootMainTemplate>
        <ConfirmDeleteModal
          isOpen={openConfirmDelete.status}
          value={openConfirmDelete?.value}
          onClose={onCloseDeleteModal}
          onConfirm={onDeletePropertyModel}
        />
        <ConfirmDeleteModal
          isOpen={confirmDeleteSubGroup.openConfirmDelete.status}
          value={confirmDeleteSubGroup.openConfirmDelete?.value}
          onClose={confirmDeleteSubGroup.onCloseDeleteModal}
          onConfirm={onDeleteSubGroup}
        />
      </Drawer>
      {isUpdateForm && (
        <Fragment>
          <DrawerSubGroupForm
            initial={openSubGroupDrawer?.value}
            mode={openSubGroupDrawer.mode}
            customizationPartId={String(customizationPartId)}
            isOpen={openSubGroupDrawer.status}
            onClose={handleOnCloseSubGroupDrawer}
            // onSubmit={handleOnSubmitSubGroup}
          />
          <DrawerModelPropertyForm
            isOpen={openModelPropertyDrawer.status}
            propertyId={openModelPropertyDrawer.value}
            mode={openModelPropertyDrawer.mode}
            subGroups={currentModelCustomizationPart.data?.formGroups || []}
            modelId={modelId}
            customizationPartId={String(customizationPartId)}
            onClose={handleOnClosePropertyModel}
          />
        </Fragment>
      )}

      <DrawerTranslation
        isOpen={isOpenTranslationDrawer}
        translationId={translationIdValue}
        onSubmit={handleOnSubmitTranslation}
        onClose={handleCloseTranslationDrawer}
      />

      <LoadingDialog isOpen={isOpenLoadingDialog} onClose={() => setIsOpenLoadingDialog(false)} />
    </>
  )
}

export default DrawerCustomizationPartForm
