import { ActionType, FormType } from './customizationPart';
import { ConfigurationType } from './modelProperty';


export const actionTypes = [
    {
        label: 'Selector',
        value: ActionType.selector,
    },
    {
        label: 'Toggle',
        value: ActionType.toggle,
    },
    {
        label: 'Multiple Selector',
        value: ActionType.multipleSelector,
    },
]

export const formType = [
    {
        label: 'Single',
        value: FormType.Single,
    },
    {
        label: 'Multiple Relation',
        value: FormType.MultipleRelation
    },
    {
        label: 'Multiple None Relation',
        value: FormType.MultipleNoneRelation
    }
]

export const textureConfiguration = {
    internalFormat: 'RGB',
    magFilter: 'LINEAR',
    minFilter: 'LINEAR_MIPMAP_LINEAR',
    texCoordUnit: 0,
    textureTarget: 'TEXTURE_2D',
    wrapS: 'REPEAT',
    wrapT: 'REPEAT',
}


// export const configurationType = [
//     {
//         label: ConfigurationType.Color.toString(),
//         value: ConfigurationType.Color
//     },
//     {
//         label: ConfigurationType.Texture.toString(),
//         value: ConfigurationType.Texture
//     },
//     {
//         label: ConfigurationType.Glossiness.toString(),
//         value: ConfigurationType.Glossiness
//     },
//     {
//         label: 'Single',
//         value: ConfigurationType.Metalness
//     },
//     {
//         label: 'Single',
//         value: ConfigurationType.Roughness
//     },
//     {
//         label: 'Single',
//         value: ConfigurationType.Visibility
//     },
// ]

export const configurationTypes = [{ label: 'All', value: '' }, ...Object.keys(ConfigurationType).map((ele) => ({ label: ele, value: ele }))]

export enum ErrorInputType {
    Required = 'required',
    Pattern = 'pattern'
}