import { Disclosure, Transition } from '@headlessui/react'
import { FunctionComponent } from 'react'
import { Translation } from '../../../modules/types/translation'
import DropdownIconButton from '../../atoms/DropdownIconButton'
import ChevronDownIcon from '../../atoms/Icons/ChevronDownIcon'
import EllipsisVertical from '../../atoms/Icons/EllipsisVertical'
import TranslationInfoPanel from '../TranslationInfoPanel'

type Props = {
  translation: Translation
  className?: string
  onDelete?: (val: string) => void
  onEdit?: (val: Translation) => void
}

const TranslationCard: FunctionComponent<Props> = ({
  translation,
  className,
  onEdit,
  onDelete,
}) => {
  const menus = [
    {
      key: 'on-edit',
      label: 'Edit',
    },
    {
      key: 'on-delete',
      label: 'Delete',
    },
  ]

  function handleOnSelected(val: string) {
    if (val === 'on-edit' && onEdit) onEdit(translation)
    else if (val === 'on-delete' && onDelete) onDelete(translation.docId)
  }

  return (
    <div className='border bg-primary-bg-color shadow '>
      <Disclosure>
        {({ open }) => (
          <>
            <div className={`flex items-center  px-3 py-3 ${className}`}>
              <Disclosure.Button className='flex-1 flex space-x-2  text-left text-sm font-medium'>
                <ChevronDownIcon
                  className={`${!open ? '-rotate-90 transform' : ''} h-5 w-5 duration-100`}
                />
                <div className='flex-1 flex space-x-2 items-center'>
                  <span>{translation.name}</span>
                </div>
              </Disclosure.Button>
              <DropdownIconButton
                onSelected={handleOnSelected}
                menus={menus}
                icon={<EllipsisVertical />}
              />
            </div>
            <Transition
              show={open}
              enter='transition duration-100 ease-out'
              enterFrom='transform  opacity-0'
              enterTo='transform  opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <Disclosure.Panel static className='px-4 py-5 border-t'>
                <TranslationInfoPanel language={translation} />
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default TranslationCard
