import { ReactNode } from 'react';
type PropsChildren = {
    children?: ReactNode | JSX.Element
}
type PropsWithChildren<P = void> = P extends void ? PropsChildren : P & PropsChildren

export default PropsWithChildren

export type IconProps = {
    className?: string
}

export enum ColorBase {
    primary = 'primary'
}

export type ButtonProps = {
    type?: 'submit' | 'reset' | 'button' | undefined;
    color?: ColorBase;
    className?: string;
    onClick?: () => void;
}

export type ColorStyle = {
    color: string,
    dark?: string,
    light?: string
}

export enum ButtonType {
    Flat = 'flat',
    Outlined = 'outlined'
}


export enum FormMode {
    Idle,
    Add,
    Update
}


export type FormInputDrawer<T> = {
    status: boolean;
    mode: FormMode;
    value?: T
}

export type FormInputModal<T> = {
    status: boolean;
    mode: FormMode;
    value?: T
}


export type ConfirmDeleteType<T = any> = {
    status: boolean;
    value?: T
}