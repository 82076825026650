import { Menu, Transition } from '@headlessui/react'
import { Fragment, FunctionComponent, ReactNode } from 'react'

type Props = {
  icon: ReactNode
  menus: Array<{ key: string; label: string }>
  onSelected?: (v: string) => void
}

const DropdownIconButton: FunctionComponent<Props> = ({ menus, icon, onSelected }) => {
  function handleOnSelected(val: string) {
    if (onSelected) onSelected(val)
  }
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <Menu.Button>{icon}</Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 '>
          <div className='p-2 flex flex-col items-stretch'>
            {menus.map((menu) => (
              <Menu.Item key={menu.key}>
                <button
                  type='button'
                  className='w-full hover:text-primary py-2 px-2 text-left'
                  onClick={() => handleOnSelected(menu.key)}
                >
                  {menu.label}
                </button>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
export default DropdownIconButton
