import { ChevronRightIcon } from 'lucide-react'
import { FunctionComponent, useState } from 'react'
import { ModelProperty } from '../../../modules/types/modelProperty'
import DropdownIconButton from '../../atoms/DropdownIconButton'
import EllipsisVertical from '../../atoms/Icons/EllipsisVertical'
import Switcher from '../../atoms/Switcher'

type Props = {
  value: ModelProperty
  onChangeStatus: (id: string, v: boolean) => void
  onSelectMenu: (key: string, value: ModelProperty) => void
  onSelectPart: (v: ModelProperty) => void
}

const ModelPropertyItem: FunctionComponent<Props> = ({
  value,
  onChangeStatus,
  onSelectMenu,
  onSelectPart,
}) => {
  const [enable, setEnable] = useState(value.status)

  const handleOnChange = (v: boolean) => {
    setEnable(v)
    onChangeStatus(value.propertyId, v)
  }
  return (
    <div className='flex space-x-4 items-center px-5 py-3 bg-secondary-bg-color border my-2'>
      <div className='flex-1 flex space-x-4 items-center'>
        <Switcher enabled={enable} onChange={handleOnChange} />
        <img className='h-[58px] object-cover' src={value.image} />
        <div className='flex flex-col'>
          <p className='text-xl'>{value.name}</p>
          <p className='text-sm text-primary-light'>{value.default && 'Default'}</p>
        </div>
      </div>
      <div className='flex-1 flex space-x-5 justify-around'>
        <div className='flex flex-col space-y-1'>
          <p className=' inline-flex space-x-2'>
            <span className=' font-bold'>Action type : </span>
            <span>{value.actionType}</span>
          </p>
          <p className=' inline-flex space-x-2'>
            <span className=' font-bold'>Sub group : </span>
            <span>{value.formId || '-'}</span>
          </p>
        </div>
      </div>
      <div className='flex-1'>
        <p>
          <span className='font-bold'>Price (USD) :</span>
          <span>{(value?.price?.usd || 0).toLocaleString()}</span>
        </p>
      </div>
      <DropdownIconButton
        icon={<EllipsisVertical />}
        menus={[
          {
            key: 'on-edit',
            label: 'Edit',
          },
          {
            key: 'on-delete',
            label: 'Delete',
          },
        ]}
        onSelected={(key) => onSelectMenu(key, value)}
      />
      <button className='m-0' onClick={() => onSelectPart(value)}>
        <ChevronRightIcon />
      </button>
    </div>
  )
}

export default ModelPropertyItem
