import { configureStore } from '@reduxjs/toolkit'
import assetsReducer from './stores/assets'
import translationsReducer from './stores/translations'
import modelsReducer from './stores/models'
import customizationPartsReducer from './stores/customizationParts'
import propertyReducer from './stores/properties'

import { module } from './types/module'
export const store = configureStore({
    reducer: {
        [module.assets]: assetsReducer,
        [module.models]: modelsReducer,
        [module.translations]: translationsReducer,
        [module.customizationParts]: customizationPartsReducer,
        [module.property]: propertyReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch