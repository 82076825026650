import React from 'react'

type Props = {
  width?: number
  src?: string
  color?: string
}

export const ImageModel: React.FC<Props> = ({ width = 180, src, color }) => {
  const className = `w-[180px] bg-[${color}]`

  return (
    <div className={className}>
      <div
        style={{
          backgroundColor:
            'transparent radial-gradient(closest-side at 50% 50%, rgba(183, 186, 185, 0.2) 0%, rgba(0, 0, 0, 0.3) 100%) 0% 0% no-repeat padding-box',
        }}
        className='p-2'
      >
        <img className=' w-full object-cover' src={src} />
      </div>
    </div>
  )
}
