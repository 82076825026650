import { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import Drawer from 'react-modern-drawer'
import { configurationTypes, textureConfiguration } from '../../../modules/types/constant'
import {
  Configuration,
  ConfigurationItem,
  ConfigurationType,
  PerformType,
} from '../../../modules/types/modelProperty'
import FormField from '../../atoms/FormField'
import Input from '../../atoms/Input'
import Selector from '../../atoms/Selector'
import TextError from '../../atoms/TextError'
import { FormMode } from '../../types'
import RootMainTemplate from '../RootMainTemplate'

type Props = {
  formName: string
  isOpen: boolean
  mode: FormMode
  value?: ConfigurationItem
  performType: PerformType
  // onSubmit: (val: Configuration) => void
  onAdd: (val: Configuration) => void
  onUpdate: (index: number, val: Configuration) => void
  onClose: () => void
}

const visibilityOptions = [
  {
    label: 'visible',
    value: true,
  },
  {
    label: 'invisible',
    value: false,
  },
]

type InputConfiguration = Omit<Configuration, 'texture' | 'visible'> & {
  texture?: string
  visible: string | boolean
}

const DrawerPropertyForm: FunctionComponent<Props> = ({
  formName,
  isOpen,
  mode,
  value,
  onAdd,
  performType,
  onUpdate,
  onClose,
}) => {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InputConfiguration>()

  const typeValue = watch('type')
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleOnClickSave = useCallback(() => {
    buttonRef.current?.click()
  }, [])

  const handleOnSubmitFormData = useCallback(
    (values: InputConfiguration) => {
      const data = { ...values }
      console.log('data output: ', data)
      if (data.type === ConfigurationType.Visibility) {
        data.visible = data.visible === 'true' ? true : false
      }

      if (mode === FormMode.Add) {
        onAdd({
          ...data,
          performType: performType,
          texture: data?.texture ? { name: data?.texture, ...textureConfiguration } : undefined,
        } as Configuration)
      } else if (mode === FormMode.Update) {
        onUpdate(
          value?.index as number,
          {
            ...data,
            performType: performType,
            texture: data?.texture ? { name: data?.texture, ...textureConfiguration } : undefined,
          } as Configuration,
        )
      }
      onCloseDrawer()
    },
    [mode, value, performType],
  )

  const onCloseDrawer = () => {
    reset()
    onClose()
  }

  useEffect(() => {
    if (mode !== FormMode.Update || !value) return
    const v = value as Configuration
    const { type } = v
    setValue('materialName', v?.materialName || '')
    setValue('type', v.type)
    setValue('visible', 'false')

    if (type === ConfigurationType.Color) setValue('color', v.color)
    else if (type === ConfigurationType.Glossiness) setValue('glossiness', v.glossiness)
    else if (type === ConfigurationType.Metalness) setValue('metalness', v.metalness)
    else if (type === ConfigurationType.Roughness) setValue('roughness', v.roughness)
    else if (type === ConfigurationType.Texture) setValue('texture', String(v?.texture?.name || ''))
    else if (type === ConfigurationType.Visibility)
      setValue('visible', v?.visible ? 'true' : 'false')
  }, [value, mode])

  useEffect(() => {
    if (!isOpen) reset()
  }, [isOpen])

  return (
    <Drawer size={650} open={isOpen} onClose={onCloseDrawer} direction='right' className=''>
      <RootMainTemplate title={formName} onClose={onCloseDrawer} onSave={handleOnClickSave}>
        <div className='flex flex-col space-y-5 py-10 px-8'>
          <p className='text-xl font-bold'>{formName}</p>
          <form
            onSubmit={handleSubmit(handleOnSubmitFormData)}
            className='flex flex-col w-full space-y-5'
          >
            <FormField label='Name'>
              <Input
                {...register('materialName', {
                  required: { value: true, message: 'name is required' },
                })}
              />
              <TextError>{errors.materialName?.message}</TextError>
            </FormField>
            <FormField label='Config Type'>
              <Selector {...register('type')} options={configurationTypes} />
            </FormField>
            {typeValue === ConfigurationType.Color && (
              <FormField label='color'>
                <Input
                  {...register('color', {
                    required: {
                      value: typeValue === ConfigurationType.Color,
                      message: 'color is required',
                    },
                  })}
                />
                <TextError>{errors.color?.message}</TextError>
              </FormField>
            )}
            {typeValue === ConfigurationType.Texture && (
              <FormField label='Texture'>
                <Input
                  {...register('texture', {
                    required: {
                      value: typeValue === ConfigurationType.Texture,
                      message: 'texture is required',
                    },
                  })}
                />
                <TextError>{errors.texture?.message}</TextError>
              </FormField>
            )}
            {typeValue === ConfigurationType.Glossiness && (
              <FormField label='Glossiness'>
                <Input
                  type='number'
                  step='0.01'
                  {...register('glossiness', {
                    required: {
                      value: typeValue === ConfigurationType.Glossiness,
                      message: 'glossiness is required',
                    },
                    min: {
                      value: 0.0,
                      message: 'min value is greater equal than 0.0',
                    },
                    max: {
                      value: 1.0,
                      message: 'max value is less equal than 1.0',
                    },
                  })}
                />
                <TextError>{errors.glossiness?.message}</TextError>
              </FormField>
            )}
            {typeValue === ConfigurationType.Roughness && (
              <FormField label='Roughness'>
                <Input
                  type='number'
                  step='0.01'
                  {...register('roughness', {
                    required: {
                      value: typeValue === ConfigurationType.Roughness,
                      message: 'roughness is required',
                    },
                    min: {
                      value: 0.0,
                      message: 'min value is greater equal than 0.0',
                    },
                    max: {
                      value: 1.0,
                      message: 'max value is less equal than 1.0',
                    },
                  })}
                />
                <TextError>{errors.roughness?.message}</TextError>
              </FormField>
            )}
            {typeValue === ConfigurationType.Metalness && (
              <FormField label='Metalness'>
                <Input
                  type='number'
                  step='0.01'
                  {...register('metalness', {
                    required: {
                      value: typeValue === ConfigurationType.Metalness,
                      message: 'metalness is required',
                    },
                    min: {
                      value: 0.0,
                      message: 'min value is greater equal than 0.0',
                    },
                    max: {
                      value: 1.0,
                      message: 'max value is less equal than 1.0',
                    },
                  })}
                />
                <TextError>{errors.metalness?.message}</TextError>
              </FormField>
            )}
            {typeValue === ConfigurationType.Visibility && (
              <FormField label='Visibility'>
                <div className='flex items-center space-x-5 pt-2'>
                  <label htmlFor='field-visible' className='flex space-x-1 items-center'>
                    <input
                      {...register('visible')}
                      type='radio'
                      value={'true'}
                      id='field-visible'
                      className='w-5 h-5'
                    />
                    <p>Visible</p>
                  </label>
                  <label htmlFor='field-invisible' className='flex space-x-1 items-center'>
                    <input
                      {...register('visible')}
                      type='radio'
                      value={'false'}
                      id='field-invisible'
                      className='w-5 h-5'
                    />
                    <p>Invisible</p>
                  </label>
                </div>
              </FormField>
            )}
            <button ref={buttonRef} type='submit' className='hidden'></button>
          </form>
        </div>
      </RootMainTemplate>
    </Drawer>
  )
}

export default DrawerPropertyForm
