import httpAuth from '../../configs/http-auth.config';
import { CredentialAuthentication, SignInForm } from '../types/authentication';


class AuthenticationService {
    static readonly path: string = '/auth/v1'

    static async signIn(params: SignInForm): Promise<CredentialAuthentication> {
        const { data } = await httpAuth.post(`${this.path}/sign-in`, params);
        return data;
    }
}

export default AuthenticationService;