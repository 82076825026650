import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'

import { Configuration, ConfigurationItem, PerformType } from '../../../modules/types/modelProperty'
import DrawerPropertyForm from '../../templates/DrawerPropertyForm'
import { FormInputDrawer, FormMode } from '../../types'
import PropertyManagement from './PropertyManagement'

type Props = {
  configurations: Configuration[]
}

export type ModelPropertyItemManagementPanelRef = {
  getConfiguration: Configuration[]
  onResetConfiguration: () => void
}

const drawerPropertyFormTitle = {
  [PerformType.active]: 'Active Component',
  [PerformType.inactive]: 'InActive Component',
}

// eslint-disable-next-line react/display-name
const ModelPropertyItemManagementPanel = forwardRef<ModelPropertyItemManagementPanelRef, Props>(
  ({ configurations = [] }, ref) => {
    const [configurationList, setConfigurationList] = useState<ConfigurationItem[]>([])

    const [openDrawerProperty, setOpenDrawerProperty] = useState<
      FormInputDrawer<ConfigurationItem> & { formName?: string } & Pick<
          Configuration,
          'performType'
        >
    >({
      status: false,
      mode: FormMode.Idle,
      performType: PerformType.active,
    })

    const handleOnAddActiveComponent = useCallback(() => {
      // console.log('add drawer')
      setOpenDrawerProperty({
        status: true,
        mode: FormMode.Add,
        formName: 'Active Component',
        performType: PerformType.active,
      })
    }, [])

    const handleOnAddInActiveComponent = useCallback(() => {
      setOpenDrawerProperty({
        status: true,
        mode: FormMode.Add,
        formName: drawerPropertyFormTitle[PerformType.inactive],
        performType: PerformType.inactive,
      })
    }, [])

    const handleOnUpdateActiveComponent = useCallback((value: ConfigurationItem) => {
      setOpenDrawerProperty({
        status: true,
        mode: FormMode.Update,
        value,
        formName: drawerPropertyFormTitle[PerformType.active],
        performType: PerformType.active,
      })
    }, [])

    const handleOnUpdateInActiveComponent = useCallback((value: ConfigurationItem) => {
      setOpenDrawerProperty({
        status: true,
        mode: FormMode.Update,
        value,
        formName: drawerPropertyFormTitle[PerformType.inactive],
        performType: PerformType.inactive,
      })
    }, [])

    const handleOnDeleteComponent = useCallback(
      (index: number) => {
        setConfigurationList((prev) => {
          const pos = prev.findIndex((item) => item.index === index)
          if (pos < 0) return prev
          prev.splice(pos, 1)
          return [...prev]
        })
      },
      [configurationList],
    )

    const handleOnCloseDrawer = useCallback(() => {
      setOpenDrawerProperty({
        status: false,
        mode: FormMode.Idle,
        formName: '',
        performType: PerformType.active,
      })
    }, [])

    const handleOnAddConfiguration = useCallback((value: Configuration) => {
      setConfigurationList((prev) => [...prev, { index: prev.length, ...value }])
      handleOnCloseDrawer()
    }, [])

    const handleOnUpdateConfiguration = useCallback(
      (index: number, value: Configuration) => {
        setConfigurationList((prev) => {
          const pos = prev.findIndex((item) => item.index === index)
          if (pos < 0) return prev
          const clone = [...prev]
          clone[pos] = { index: index, ...value }
          return clone
        })
        handleOnCloseDrawer()
      },
      [handleOnCloseDrawer],
    )

    const handleOnResetConfiguration = useCallback(() => {
      setConfigurationList([])
    }, [])

    const activeComponents = useMemo(() => {
      return configurationList.filter((config) => config.performType === PerformType.active)
    }, [configurationList])

    const inactiveComponents = useMemo(() => {
      return configurationList.filter((config) => config.performType === PerformType.inactive)
    }, [configurationList])

    useImperativeHandle(ref, () => ({
      getConfiguration: configurationList.map(({ index, ...params }) => params),
      onResetConfiguration: handleOnResetConfiguration,
    }))

    useEffect(() => {
      if (!configurations.length) return
      setConfigurationList(configurations.map((config, index) => ({ ...config, index })))
    }, [configurations])

    return (
      <>
        <div className='h-full flex space-x-5 py-5 px-5'>
          <div className='flex-1'>
            <PropertyManagement
              title='Active Component'
              properties={activeComponents}
              onAdd={handleOnAddActiveComponent}
              onUpdate={handleOnUpdateActiveComponent}
              onDelete={handleOnDeleteComponent}
            />
          </div>
          <div className='flex-1 '>
            <PropertyManagement
              title='Inactive Component'
              properties={inactiveComponents}
              onAdd={handleOnAddInActiveComponent}
              onUpdate={handleOnUpdateInActiveComponent}
              onDelete={handleOnDeleteComponent}
            />
          </div>
        </div>
        <DrawerPropertyForm
          isOpen={openDrawerProperty.status}
          formName={openDrawerProperty.formName || ''}
          mode={openDrawerProperty.mode}
          value={openDrawerProperty?.value}
          performType={openDrawerProperty.performType}
          onAdd={handleOnAddConfiguration}
          onUpdate={handleOnUpdateConfiguration}
          onClose={handleOnCloseDrawer}
        />
      </>
    )
  },
)

export default memo(ModelPropertyItemManagementPanel)
