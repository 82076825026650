import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { Image, ImageCategory } from '../../types/image';
import { StateData } from '../../types/module';
import { createImage, createImageCategory, deleteImage, fetchImageCategoriesList, fetchImageList, updateImageCategory } from './action';
import { IAssetsState } from './state';


const caseReducerImageCategories = {
    onLoading(state: IAssetsState) {
        state.imageCategories.state = StateData.Loading
    },
    onSuccess(state: IAssetsState, action: PayloadAction<ImageCategory[]>) {
        state.imageCategories.state = StateData.Success;
        state.imageCategories.data = action.payload
    },
    onFailed(state: IAssetsState, action: PayloadAction<unknown>) {
        state.imageCategories.state = StateData.Failed;
        state.imageCategories.error = action.payload;
    }
}

const caseReducerImages = {
    onLoading(state: IAssetsState) {
        state.images.state = StateData.Loading
    },
    onSuccess(state: IAssetsState, action: PayloadAction<Image[]>) {
        state.images.state = StateData.Success;
        state.images.data = action.payload
    },
    onFailed(state: IAssetsState, action: PayloadAction<unknown>) {
        state.images.state = StateData.Failed;
        state.images.error = action.payload;
    }
}


const caseReducerCreateImageCategory = {
    onSuccess(state: IAssetsState, action: PayloadAction<ImageCategory>) {
        state.imageCategories.data = [...state.imageCategories.data, action.payload]
    }
}

const caseReducerUpdateImageCategory = {
    onSuccess(state: IAssetsState, action: PayloadAction<ImageCategory>) {
        const { payload } = action;
        const clones = Array.from(state.imageCategories.data).map((ele) => {
            if (ele.id === payload.id) return payload;
            return ele;
        })
        state.imageCategories.data = clones;
    }
}

const caseReducerCreateImage = {
    onSuccess(state: IAssetsState, action: PayloadAction<Image>) {
        const { payload } = action;
        state.images.data = [...state.images.data, payload]
    }
}


const caseReducerDeleteImage = {
    onSuccess(state: IAssetsState, action: PayloadAction<string>) {
        const id = action.payload;
        const clones = Array.from(state.images.data).filter((image) => image.id !== id);
        state.images.data = clones;
    }
}


const extraReducer = (builder: ActionReducerMapBuilder<IAssetsState>): void => {
    // Image Category
    builder.addCase(fetchImageCategoriesList.pending, caseReducerImageCategories.onLoading);
    builder.addCase(fetchImageCategoriesList.fulfilled, caseReducerImageCategories.onSuccess);
    builder.addCase(fetchImageCategoriesList.rejected, caseReducerImageCategories.onFailed);

    // Images
    builder.addCase(fetchImageList.pending, caseReducerImages.onLoading);
    builder.addCase(fetchImageList.fulfilled, caseReducerImages.onSuccess);
    builder.addCase(fetchImageList.rejected, caseReducerImages.onFailed);


    // Create Image Category
    builder.addCase(createImageCategory.fulfilled, caseReducerCreateImageCategory.onSuccess)

    // Update Image Category
    builder.addCase(updateImageCategory.fulfilled, caseReducerUpdateImageCategory.onSuccess)

    // Crate Image
    builder.addCase(createImage.fulfilled, caseReducerCreateImage.onSuccess);

    // Delete Image
    builder.addCase(deleteImage.fulfilled, caseReducerDeleteImage.onSuccess)


}

export default extraReducer