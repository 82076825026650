import { Dialog } from '@headlessui/react'
import { FunctionComponent, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { CreateImageCategory, ImageCategory } from '../../../modules/types/image'
import FlatButton from '../../atoms/Button/FlatButton'
import OutlinedButton from '../../atoms/Button/OutlinedButton'
import FormField from '../../atoms/FormField'
import Input from '../../atoms/Input'
import TextError from '../../atoms/TextError'
import { FormMode } from '../../types'
import Modal from '../Modal'

type Props = {
  isOpen: boolean
  onClose: () => void
  mode: FormMode
  value?: ImageCategory
  // onSubmit: (val: CreateImageCategory) => void
  onAdd: (val: CreateImageCategory) => void
  onUpdate: (val: ImageCategory) => void
}

const ImageCategoryFormModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  onAdd,
  onUpdate,
  mode,
  value,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<CreateImageCategory>()

  useEffect(() => {
    if (!isOpen || !value || mode !== FormMode.Update) return
    setValue('name', value?.name)
  }, [isOpen, value, mode])

  const onSubmitFormData = useCallback(
    (val: CreateImageCategory) => {
      if (mode === FormMode.Add) {
        onAdd(val)
      } else {
        onUpdate({ id: String(value?.id), ...val })
      }
    },
    [mode, onAdd, onUpdate],
  )

  useEffect(() => {
    if (!isOpen) reset()
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all z-30'>
        <Dialog.Title
          as='h3'
          className='text-lg inline-flex space-x-5 items-center font-medium leading-6 text-gray-900 pb-2 border-b'
        >
          Create Category
        </Dialog.Title>
        <form onSubmit={handleSubmit(onSubmitFormData)}>
          <div className='py-5 flex flex-col'>
            <FormField label='Category Name'>
              <Input {...register('name', { required: true })} />
              <TextError>{errors.name && 'Category Name is required.'}</TextError>
            </FormField>
          </div>
          <div className='mt-4 flex space-x-5 justify-end'>
            <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
            <FlatButton type='submit'>Confirm</FlatButton>
          </div>
        </form>
      </Dialog.Panel>
    </Modal>
  )
}

export default ImageCategoryFormModal
