import { FunctionComponent, ReactNode } from 'react'

type Props = {
  icon?: ReactNode
  label: string
  value?: string
}

const TranslationListTile: FunctionComponent<Props> = ({ icon, label, value }) => {
  return (
    <div className='flex flex-col space-y-3'>
      <div className='flex space-x-2 items-center px-1'>
        {icon}
        <p>{label}</p>
      </div>
      <div className=' border border-color-border rounded px-2 py-2'>{value}</div>
    </div>
  )
}

export default TranslationListTile
