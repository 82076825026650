import { createSlice } from '@reduxjs/toolkit';
import { module } from '../../types/module';
import { initialState, IPropertyState } from './state';
import extraReducer from './extraReducer';


export const propertySlice = createSlice({
    name: module.property,
    initialState,
    reducers: {
        resetModelProperties(state: IPropertyState) {
            state.modelProperties = initialState.modelProperties;
        },
        resetCurrentModelProperty(state: IPropertyState) {
            state.currentModelProperty = initialState.currentModelProperty;
        }
    },
    extraReducers: extraReducer
})


export const { resetModelProperties, resetCurrentModelProperty } = propertySlice.actions;

export default propertySlice.reducer;