import React, { ReactNode } from 'react'
import PropsWithChildren from '../../types'

type Props = {
  label: string
  prefix?: ReactNode
}

const FormField: React.FC<PropsWithChildren<Props>> = ({ label, prefix, children }) => {
  return (
    <div className='flex flex-col space-y-1 w-full'>
      <div className='flex space-x-5'>
        {prefix && <span className='text-secondary-font-color'>{prefix}</span>}
        <label className='font-semibold text'>{label}</label>
      </div>
      {children}
    </div>
  )
}

export default FormField
