import React, { FunctionComponent, memo, useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Category } from '../../../modules/types/customizationPart'
import { Translation } from '../../../modules/types/translation'
import OutlinedButton from '../../atoms/Button/OutlinedButton'
import FormField from '../../atoms/FormField'
import Input from '../../atoms/Input'
import DrawerTranslation from '../../templates/DrawerTranslation'
import { FormMode } from '../../types'
import Drawer from 'react-modern-drawer'
import RootMainTemplate from '../RootMainTemplate'
import Card from '../../atoms/Card'
import TextError from '../../atoms/TextError'

export type InputCategoryForm = {
  name: string
  translationId: string
}

type Props = {
  isOpen: boolean
  onClose: () => void
  mode: FormMode
  data?: Category
  onSubmitAddItem: (val: InputCategoryForm) => void
  onSubmitUpdateItem: (id: string, val: InputCategoryForm) => void
}

const CategoryFormModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  mode,
  data,
  onSubmitAddItem,
  onSubmitUpdateItem,
}) => {
  const formRef = useRef(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InputCategoryForm>()

  const [isOpenTranslationDrawer, setIsOpenTranslationDrawer] = useState<boolean>(false)
  const translationIdValue = watch('translationId')

  const handleOnCloseModal = useCallback(() => {
    reset()
    onClose()
  }, [])

  const handleOnSubmit = useCallback(
    (val: InputCategoryForm) => {
      if (mode === FormMode.Add) {
        onSubmitAddItem(val)
      } else {
        onSubmitUpdateItem(data?.groupId || '', val)
      }
      handleOnCloseModal()
    },
    [mode, data, handleOnCloseModal],
  )

  const handleCloseTranslationDrawer = useCallback(() => {
    setIsOpenTranslationDrawer(false)
  }, [])

  const handleOpenTranslationDrawer = useCallback(() => {
    setIsOpenTranslationDrawer(true)
  }, [])

  const handleOnSave = useCallback(() => {
    if (!buttonRef.current) return
    buttonRef.current.click()
  }, [])

  const handleOnSubmitTranslation = useCallback((value: Translation) => {
    setValue('name', value.name)
    setValue('translationId', value.docId)
  }, [])

  useEffect(() => {
    if (!data) return

    setValue('name', data?.name || '')
    setValue('translationId', data?.translationId || '')
  }, [data])

  return (
    <>
      <Drawer size='100vh' open={isOpen} direction='bottom' onClose={handleOnCloseModal} zIndex={0}>
        <RootMainTemplate
          title={mode === FormMode.Add ? 'Create New Category' : 'Update Category'}
          onClose={handleOnCloseModal}
          onSave={handleOnSave}
        >
          <Card className='container mx-auto p-5 my-10 max-w-xl'>
            <form
              ref={formRef}
              onSubmit={handleSubmit(handleOnSubmit)}
              className='w-full flex flex-col items-stretch space-y-5 py-3 '
            >
              <FormField label='Name'>
                <div className='flex space-x-5 w-full '>
                  <Input {...register('name', { required: true })} disabled className='flex-1' />
                  <OutlinedButton type='button' onClick={handleOpenTranslationDrawer}>
                    Translation
                  </OutlinedButton>
                </div>
                <TextError>{errors?.name ? 'Please choose translation' : ''}</TextError>
              </FormField>
              <input className='hidden' {...register('translationId')} />
              <button hidden={true} ref={buttonRef} type='submit' />
            </form>
          </Card>
        </RootMainTemplate>
      </Drawer>
      <DrawerTranslation
        translationId={translationIdValue}
        isOpen={isOpenTranslationDrawer}
        onClose={handleCloseTranslationDrawer}
        onSubmit={handleOnSubmitTranslation}
      />
    </>
  )
}

export default memo(CategoryFormModal)
