import { forwardRef, InputHTMLAttributes } from 'react'

type Ref = HTMLInputElement
type Props = InputHTMLAttributes<HTMLInputElement> & { onClick?: () => void }

// eslint-disable-next-line react/display-name
const InputImageGallery = forwardRef<Ref, Props>((props, ref) => {
  const { className, onClick, ...inputProps } = props
  return (
    <div className='inline-flex items-stretch'>
      <input
        ref={ref}
        {...inputProps}
        className={`border border-color-border px-3 py-3 rounded-l  text-[15px] appearance-none focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-blue-300 leading-tight bg-primary-bg-color ${className}`}
      />
      <button
        type='button'
        className='bg-secondary-bg-color border-t border-r border-b rounded-r inline-flex px-2'
        onClick={onClick}
      >
        Choose Image
      </button>
    </div>
  )
})

export default InputImageGallery
