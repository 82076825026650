import { createSlice } from '@reduxjs/toolkit';
import { module } from '../../types/module';
import extraReducer from './extraReducer';
import { initialState } from './state';

const assetsSlice = createSlice({
    name: module.assets,
    initialState,
    reducers: {},
    extraReducers: extraReducer
})

export default assetsSlice.reducer