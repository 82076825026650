import { Fragment, FunctionComponent } from 'react'

import LoadingContent from '../../molecules/LoadingContent'
import PropsWithChildren from '../../types'

type Props = {
  isLoading: boolean
  isSuccess: boolean
  className?: string
}

const StateTemplateV2: FunctionComponent<PropsWithChildren<Props>> = ({
  isLoading,
  isSuccess,
  className = 'h-full',
  children,
}) => {
  return (
    <Fragment>
      {isLoading ? (
        <div className={`${className} flex justify-center items-center `}>
          {' '}
          <LoadingContent />
        </div>
      ) : (
        <></>
      )}
      {isSuccess ? children : <></>}
    </Fragment>
  )
}

export default StateTemplateV2
