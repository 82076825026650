import { FunctionComponent } from 'react'
import { Translation } from '../../../modules/types/translation'
import EngFlagIcon from '../../atoms/Icons/Flags/EngFlagIcon'
import FrenchFlagIcon from '../../atoms/Icons/Flags/FrenchFlagIcon'
import ItalianFlagIcon from '../../atoms/Icons/Flags/ItalianFlagIcon'
import SpanishFlagIcon from '../../atoms/Icons/Flags/SpanishFlagIcon'
import TranslationListTile from '../../atoms/TranslationListTile'

type Props = {
  language?: Translation
}

const TranslationInfoPanel: FunctionComponent<Props> = ({ language }) => {
  return (
    <div className=' grid grid-cols-2 gap-5'>
      <TranslationListTile
        icon={<EngFlagIcon />}
        label='English'
        value={language?.translation?.en}
      />
      <TranslationListTile
        icon={<FrenchFlagIcon />}
        label='French'
        value={language?.translation?.fr}
      />
      <TranslationListTile
        icon={<SpanishFlagIcon />}
        label='Spanish'
        value={language?.translation?.es}
      />
      <TranslationListTile
        icon={<ItalianFlagIcon />}
        label='Italian'
        value={language?.translation?.it}
      />
    </div>
  )
}

export default TranslationInfoPanel
