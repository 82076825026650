import { Nullable } from '../../types/core';
import { Category, CustomizationPart } from '../../types/customizationPart';
import { StoreData } from '../../types/module';
import { initialStore } from '../../utils/store.util';


export interface ICustomizationPartState {
    categories: StoreData<Category[]>
    modelCustomizationParts: StoreData<CustomizationPart[]>
    currentModelCustomizationPart: StoreData<Nullable<CustomizationPart>>
}

export const initialState: ICustomizationPartState = {
    categories: initialStore<Category[]>([]),
    modelCustomizationParts: initialStore<CustomizationPart[]>([]),
    currentModelCustomizationPart: initialStore<Nullable<CustomizationPart>>(null),
}
