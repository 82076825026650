import { FunctionComponent, useState } from 'react'
import Accordion from '../../atoms/Disclosure'
import HeaderProductSettingAccordion from '../../atoms/HeaderProductSettingAccordion'
import PropsWithChildren from '../../types'

type Props = {
  title: string
  className?: string
}

const ProductSettingFormAccordion: FunctionComponent<PropsWithChildren<Props>> = ({
  title,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Accordion
      className='w-full border-b border-color-border py-2'
      header={<HeaderProductSettingAccordion isOpen={isOpen} title={title} />}
      onChange={(v) => setIsOpen(v)}
    >
      <div className='pt-1 pb-5'>{children}</div>
    </Accordion>
  )
}

export default ProductSettingFormAccordion
