import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { Model, UpdateModelOrder, UpdateModelStatus } from '../../types/models';
import { StateData } from '../../types/module';
import { deleteModel, fetchCurrentModel, fetchModelsList, updateModelData, updateModelStatus, updateOrderModels } from './actions';
import { IModelState } from './state';


const caseReducerModels = {
    onLoadingModels(state: IModelState) {
        state.models.state = StateData.Loading;
    },
    onSuccessModels(state: IModelState, action: PayloadAction<Model[]>) {
        state.models.state = StateData.Success;
        state.models.data = action.payload;
    },
    onFailedModels(state: IModelState, action: PayloadAction<unknown>) {
        state.models.state = StateData.Failed;
        state.models.error = action.payload;
    }
}

const caseReducerCurrentModel = {
    onLoadingCurrentModel(state: IModelState) {
        state.currentModel.state = StateData.Loading;
    },
    onSuccessCurrentModel(state: IModelState, action: PayloadAction<Model>) {
        state.currentModel.state = StateData.Success;
        state.currentModel.data = action.payload;
    },
    onFailedCurrentModel(state: IModelState, action: PayloadAction<unknown>) {
        state.currentModel.state = StateData.Failed;
        state.currentModel.error = action.payload;
    }
}

const caseReducerModelOrder = {
    onSuccess(state: IModelState, action: PayloadAction<UpdateModelOrder[]>) {
        const clone = Array.from(state.models.data).reduce((acc, cur) => {
            acc.set(cur.modelId, cur)
            return acc;
        }, new Map());
        const updateOrder = action.payload.map(({ modelId, order }) => ({ ...clone.get(modelId), order }))
        state.models.data = updateOrder;
    }
}

const caseReducerModelStatus = {
    onSuccess(state: IModelState, action: PayloadAction<UpdateModelStatus>) {
        const { payload } = action;
        const clones = Array.from(state.models.data).map((ele) => {
            if (ele.modelId === payload.modelId) return { ...ele, status: payload.status };
            return ele
        });
        state.models.data = clones;
    }
}

const caseReducerUpdateModelData = {
    onSuccess(state: IModelState, action: PayloadAction<Model>) {
        const { payload } = action;
        state.currentModel.data = payload;
    }
}

const caseReducerDeleteModel = {
    onSuccess(state: IModelState, action: PayloadAction<string>) {
        const { payload } = action;
        const clones = Array.from(state.models.data).filter((ele) => ele.modelId !== payload);
        state.models.data = clones;
    }
}

const extraReducer = (builder: ActionReducerMapBuilder<IModelState>): void => {
    // Model List
    builder.addCase(fetchModelsList.pending, caseReducerModels.onLoadingModels)
    builder.addCase(fetchModelsList.fulfilled, caseReducerModels.onSuccessModels)
    builder.addCase(fetchModelsList.rejected, caseReducerModels.onFailedModels)
    // Current Model
    builder.addCase(fetchCurrentModel.pending, caseReducerCurrentModel.onLoadingCurrentModel)
    builder.addCase(fetchCurrentModel.fulfilled, caseReducerCurrentModel.onSuccessCurrentModel)
    builder.addCase(fetchCurrentModel.rejected, caseReducerCurrentModel.onFailedCurrentModel)

    // Update Order Model
    builder.addCase(updateOrderModels.fulfilled, caseReducerModelOrder.onSuccess);

    // Update Status Model
    builder.addCase(updateModelStatus.fulfilled, caseReducerModelStatus.onSuccess);

    // Update Model Data
    builder.addCase(updateModelData.fulfilled, caseReducerUpdateModelData.onSuccess);

    // Delete Model
    builder.addCase(deleteModel.fulfilled, caseReducerDeleteModel.onSuccess);
}

export default extraReducer