import { FunctionComponent } from 'react'
import { getButtonClassUtilComponent } from '../../../../utils/button.util'

import PropsWithChildren, { ButtonProps, ButtonType, ColorBase } from '../../../types'
import BaseButton from '../BaseButton'
// import BaseButton from '../BaseButton'
// import FlatButtonStyled from './styled'

const FlatButton: FunctionComponent<PropsWithChildren<ButtonProps>> = ({
  type = 'button',
  color = ColorBase.primary,
  children,
  onClick,
  className = '',
}) => {
  const theme = getButtonClassUtilComponent(ButtonType.Flat, color)

  return (
    <BaseButton type={type} onClick={onClick} className={`${theme} ${className}`}>
      {children}
    </BaseButton>
  )
}

export default FlatButton
