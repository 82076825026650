import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import FlatButton from '../../components/atoms/Button/FlatButton'
import ConfirmDeleteModal from '../../components/molecules/ConfirmDeleteModal'
import LoadingContent from '../../components/molecules/LoadingContent'
import LoadingDialog from '../../components/molecules/LoadingDialog'

import SearchAndAddMenu from '../../components/molecules/SearchAndAddMenu'
import TranslationCard from '../../components/molecules/TranslationCard'
import TranslationFormModal, {
  FormMode,
  FormTranslation,
} from '../../components/molecules/TranslationFormModal'
import HeaderPage from '../../components/organisms/HeaderPage'
import GuardAuthenticationTemplate from '../../components/templates/GuardAuthentication'
import RootPage from '../../components/templates/RootPage'
import StateTemplate from '../../components/templates/StateTemplate'
import useConfirmDelete from '../../hooks/useConfirmDelete.hook'
import useToastMessage from '../../hooks/useToastMessage.hook'
import { useAppDispatch, useAppSelector } from '../../modules/hook'
import {
  createTranslation,
  deleteTranslation,
  fetchTranslationList,
  syncTranslationData,
  updateTranslation,
} from '../../modules/stores/translations/actions'

import { StateData } from '../../modules/types/module'
import { Translation, UpdateTranslation } from '../../modules/types/translation'

const TranslationSettings: FunctionComponent = () => {
  const dispatch = useAppDispatch()

  const { showError, showSuccess } = useToastMessage()

  const { openConfirmDelete, onOpenDeleteModal, onCloseDeleteModal } = useConfirmDelete()

  const [name, setName] = useState('')
  const [isOpenLoadingDialog, setIsOpenLoadingDialog] = useState<boolean>(false)
  const [openForm, setOpenForm] = useState<{ isOpen: boolean; mode: FormMode; data?: Translation }>(
    {
      isOpen: false,
      mode: FormMode.Add,
    },
  )
  const translations = useAppSelector((state) => state.translations.translations)

  const handleOnChange = useCallback((val: string) => {
    setName(val)
  }, [])

  const handleOnAdd = useCallback(() => {
    setOpenForm({ isOpen: true, mode: FormMode.Add, data: undefined })
  }, [])

  const handleOnEdit = useCallback((val: Translation) => {
    console.log(val)
    setOpenForm({ isOpen: true, mode: FormMode.Update, data: val })
  }, [])

  const handleOnDelete = useCallback(async (id: string) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(deleteTranslation(id)).unwrap()
      showSuccess('Delete successfully')
    } catch (error) {
      showError('Delete failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const handleOnCloseModal = useCallback(() => {
    setOpenForm({
      isOpen: false,
      mode: FormMode.Add,
    })
  }, [])

  const handleOnSubmitCreate = useCallback(
    async (val: FormTranslation) => {
      try {
        setIsOpenLoadingDialog(true)
        const { name, ...translation } = val
        const data = { name, translation }
        await dispatch(createTranslation(data)).unwrap()
        showSuccess('Create translation successfully')
        handleOnCloseModal()
      } catch (error) {
        console.log(error)
        showError('Create translation failed')
      } finally {
        setIsOpenLoadingDialog(false)
      }
    },
    [handleOnCloseModal],
  )

  const onSyncTranslation = useCallback(async () => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(syncTranslationData()).unwrap()
      showSuccess('Sync Translation Successfully')
    } catch (error) {
      showError('Sync Translation Failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const handleOnSubmitUpdate = useCallback(
    async (id: string, val: FormTranslation) => {
      try {
        setIsOpenLoadingDialog(true)
        const { name, ...translation } = val
        const data = { docId: id, name, translation }

        await dispatch(updateTranslation(data)).unwrap()
        showSuccess('Update translation successfully')
        handleOnCloseModal()
      } catch (error) {
        console.log(error)
        showError('Update translation failed')
      } finally {
        setIsOpenLoadingDialog(false)
      }
    },
    [handleOnCloseModal],
  )

  const translationList = useMemo(() => {
    if (translations.state !== StateData.Success) return []
    return name
      ? translations.data.filter((translation) =>
          translation.name.toLocaleLowerCase().includes(name.toLocaleLowerCase()),
        )
      : translations.data
  }, [translations.state, translations.data, name])

  useEffect(() => {
    dispatch(fetchTranslationList())
  }, [])

  return (
    <GuardAuthenticationTemplate>
      <RootPage>
        <div className='flex items-center'>
          <div className='flex-1'>
            <HeaderPage title='Translation Settings' subtitles={['Setting', 'Theme Setting']} />
          </div>
          <FlatButton onClick={onSyncTranslation}>Sync Translation to Production Site</FlatButton>
        </div>
        <SearchAndAddMenu
          placeholder='Enter Translation Name'
          onChange={handleOnChange}
          onAdd={handleOnAdd}
        />
        <div className='flex-1 overflow-y-auto pb-52'>
          <StateTemplate state={translations.state}>
            {translationList.map((translation, index) => (
              <TranslationCard
                key={translation.docId}
                translation={translation}
                className={index % 2 === 0 ? ' bg-gray-100' : 'bg-primary-bg-color'}
                onEdit={handleOnEdit}
                onDelete={onOpenDeleteModal}
              />
            ))}
          </StateTemplate>
        </div>
        <TranslationFormModal
          isOpen={openForm.isOpen}
          mode={openForm.mode}
          translation={openForm.data}
          onSubmitCreate={handleOnSubmitCreate}
          onSubmitUpdate={handleOnSubmitUpdate}
          onCloseModal={handleOnCloseModal}
        />
      </RootPage>
      <ConfirmDeleteModal
        isOpen={openConfirmDelete.status}
        value={openConfirmDelete?.value}
        onClose={onCloseDeleteModal}
        onConfirm={handleOnDelete}
      />
      <LoadingDialog isOpen={isOpenLoadingDialog} onClose={() => setIsOpenLoadingDialog(false)} />
    </GuardAuthenticationTemplate>
  )
}

export default TranslationSettings
