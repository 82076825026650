import { FunctionComponent, useCallback, useState } from 'react'
import OutlinedButton from '../../atoms/Button/OutlinedButton'
import TrashIcon from '../../atoms/Icons/TrushIcon'
import DrawerImage from '../../templates/DrawerImage'

type Props = {
  src?: string
  alt?: string
  onSelect?: (val: string) => void
  onDelete?: () => void
}

const BoxImageInput: FunctionComponent<Props> = ({ src, alt, onSelect, onDelete }) => {
  const handleOnOpenImageDrawer = useCallback(() => {
    if (onSelect) onSelect(src || '')
  }, [src])

  return (
    <>
      <div className='flex flex-col items-stretch space-y-2'>
        <div className='p-1 border-2 rounded'>
          <div className='flex items-center'>
            <img
              src={src}
              alt={alt}
              className='w-[114px] aspect-square object-cover'
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = '/images/defaultImage.png'
              }}
            />
            <button type='button' className='px-2' onClick={onDelete}>
              <TrashIcon />
            </button>
          </div>
        </div>
        <OutlinedButton type='button' onClick={handleOnOpenImageDrawer}>
          Select Image
        </OutlinedButton>
      </div>
    </>
  )
}

export default BoxImageInput
