import React from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import MenuSettings from './pages/MenuSetting'
import ProductSettings from './pages/ProductSetting'
import ProductSettingInformation from './pages/ProductSettingInformation'
import SignInPage from './pages/SignIn'
import ThemeSettings from './pages/ThemeSettings'
import TranslationSettings from './pages/TranslationSettings'
import RootMainPage from './RootMain'
// import logo from './logo.svg'

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootMainPage />,
    children: [
      {
        path: '/menu-settings',
        element: <MenuSettings />,
      },
      {
        path: '/product-settings',
        element: <ProductSettings />,
      },
      {
        path: '/translation-settings',
        element: <TranslationSettings />,
      },
      {
        path: '/theme-settings',
        element: <ThemeSettings />,
      },
      {
        index: true,
        element: <Navigate to='/menu-settings' replace />,
      },
    ],
  },
  {
    path: '/product-settings-information',
    element: <ProductSettingInformation />,
  },
  {
    path: '/sign-in',
    element: <SignInPage />,
  },
  {
    path: '/product-settings-information/:modelId',
    element: <ProductSettingInformation />,
  },
])

function App() {
  return <RouterProvider router={router} />
}

export default App
