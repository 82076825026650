import { FunctionComponent, memo, useState } from 'react'
import FlatButton from '../../atoms/Button/FlatButton'
import Input from '../../atoms/Input'

type Props = {
  placeholder?: string
  onChange?: (val: string) => void
  onAdd?: () => void
}

const SearchAndAddMenu: FunctionComponent<Props> = ({ placeholder, onChange, onAdd }) => {
  const [text, setText] = useState('')

  function handleOnChange(val: string) {
    setText(val)
    if (onChange) onChange(val)
  }

  return (
    <div className='flex justify-between space-x-5'>
      <Input
        placeholder={placeholder}
        value={text}
        className='w-full'
        onChange={(e) => handleOnChange(e.target.value)}
      />
      <FlatButton onClick={onAdd}>Add</FlatButton>
    </div>
  )
}

export default memo(SearchAndAddMenu)
