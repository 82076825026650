
import { Nullable } from '../../types/core';
import { Model } from '../../types/models';
import { StoreData } from '../../types/module';
import { initialStore } from '../../utils/store.util';

export interface IModelState {
    models: StoreData<Model[]>
    currentModel: StoreData<Nullable<Model>>
}


export const initialState: IModelState = {
    models: initialStore<Model[]>([]),
    currentModel: initialStore<Nullable<Model>>(null)
}