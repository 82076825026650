export enum DataStatus {
    active = 'active',
    inactive = 'inactive',
}

export type Nullable<T> = T | null;

export type BaseDocumentData = {
    _id: string;
    createAt?: Date;
    updateAt?: Date;
}