import { EditIcon, TrashIcon } from 'lucide-react'
import { FunctionComponent } from 'react'
import { Image } from '../../../modules/types/image'
import ImageButtonUpload from '../../molecules/ImageButtonUpload'

type Props = {
  title: string
  images: Image[]
  value: string
  onSelect: (val: string) => void
  onAdd: () => void
  onUpdateCategory: () => void
  onDelete: (id: string) => void
}

const GridImageUploading: FunctionComponent<Props> = ({
  title,
  images,
  value,
  onSelect,
  onAdd,
  onUpdateCategory,
  onDelete,
}) => {
  return (
    <div className='flex flex-col space-y-5 items-stretch pt-4 pb-5'>
      <div className='flex items-center justify-between'>
        <p className='text-2xl font-semibold'>{title}</p>
        <button type='button' className='outline-none ' onClick={onUpdateCategory}>
          <EditIcon />
        </button>
      </div>
      <ul role='button' className='grid grid-cols-4 gap-1'>
        {images.map((image) => (
          <li
            key={image.id}
            className={`border-2 flex flex-col items-center relative ${
              value === image.url ? 'border-primary' : ''
            } rounded-sm bg-gray-100`}
            onClick={() => onSelect(image.url)}
          >
            <img
              src={image.url}
              className={`h-[130px] w-full relative object-cover ${
                value === image.url ? ' border-primary' : 'border-transparent'
              }`}
            />

            <button
              type='button'
              onClick={() => onDelete(image.id)}
              className='absolute right-0 top-0 p-1 text-primary bg-white rounded-bl-sm'
            >
              <TrashIcon className='w-4 h-5' />
            </button>
            <div className='absolute bottom-0 left-0 w-full bg-black bg-opacity-60  py-1 px-2'>
              <p className='truncate text-xs text-white'>{image.name}</p>
            </div>
          </li>
        ))}
        <ImageButtonUpload onClick={onAdd} />
      </ul>
    </div>
  )
}

export default GridImageUploading
