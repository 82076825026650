
import axios, { AxiosInstance } from 'axios';
import config from './env.config';
const httpAuth: AxiosInstance = axios.create({
    baseURL: config.zapp_auth_url,
    headers: {
        'Content-Type': 'application/json',
    }
});

export default httpAuth;