import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { Category, CustomizationPart, UpdateCategory, UpdateCategoryOrder, UpdateCategoryStatus, UpdateCustomizationPartOrder, UpdateCustomizationPartStatus } from '../../types/customizationPart';
import { StateData } from '../../types/module';
import { createCategory, createCustomizationPart, createCustomizationPartFormGroup, deleteCategory, deleteCustomizationPart, deleteCustomizationPartFormGroup, fetchCategoriesList, fetchCustomizationPartById, fetchModelCustomizationPartsList, updateCategory, updateCategoryOrders, updateCategoryStatus, updateCustomizationPart, updateCustomizationPartFormGroup, updateCustomizationPartOrders, updateCustomizationPartStatus } from './action';
import { ICustomizationPartState } from './state';



const caseReducerCategories = {
    onLoadingCategories(state: ICustomizationPartState) {
        state.categories.state = StateData.Loading;
    },
    onSuccessCategories(state: ICustomizationPartState, action: PayloadAction<Category[]>) {
        state.categories.state = StateData.Success;
        state.categories.data = action.payload;
    },
    onFailedCategories(state: ICustomizationPartState, action: PayloadAction<unknown>) {
        state.categories.state = StateData.Failed;
        state.categories.error = action.payload;
    },
}

const caseReducerModelCustomizationPartsList = {
    onLoading(state: ICustomizationPartState) {
        state.modelCustomizationParts.state = StateData.Loading;
    },
    onSuccess(state: ICustomizationPartState, action: PayloadAction<CustomizationPart[]>) {
        state.modelCustomizationParts.state = StateData.Success;
        state.modelCustomizationParts.data = action.payload;
    },
    onFailed(state: ICustomizationPartState, action: PayloadAction<unknown>) {
        state.modelCustomizationParts.state = StateData.Failed;
        state.modelCustomizationParts.error = action.payload;
    },
}

const caseReducerCurrentCustomization = {
    onLoading(state: ICustomizationPartState) {
        state.currentModelCustomizationPart.state = StateData.Loading;
    },
    onSuccess(state: ICustomizationPartState, action: PayloadAction<CustomizationPart>) {
        state.currentModelCustomizationPart.state = StateData.Success;
        state.currentModelCustomizationPart.data = action.payload;
    },
    onFailed(state: ICustomizationPartState, action: PayloadAction<unknown>) {
        state.currentModelCustomizationPart.state = StateData.Failed;
        state.currentModelCustomizationPart.error = action.payload;
    },
}

const caseReducerCreateCategory = {
    onSuccess(state: ICustomizationPartState, action: PayloadAction<Category>) {
        const clone = state.categories.data;
        state.categories.data = [...clone, action.payload];
    }
}

const caseReducerUpdateCategoryOrder = {
    onSuccess(state: ICustomizationPartState, action: PayloadAction<UpdateCategoryOrder[]>) {
        const clone = Array.of(...state.categories.data).reduce((acc, cur) => {
            acc.set(cur.groupId, cur)
            return acc;
        }, new Map());
        // console.log('action.payload: ', action.payload)
        const updateOrder = action.payload.map(({ groupId, order }) => ({ ...clone.get(groupId), order }));
        // console.log('update order: ', updateOrder)
        state.categories.data = updateOrder;
    }
}

const caseReducerUpdateCategory = {
    onSuccess(state: ICustomizationPartState, action: PayloadAction<Category>) {
        const { payload } = action;
        const clones = Array.from(state.categories.data).map((ele) => {
            if (ele.groupId === payload.groupId) return payload;
            return ele
        });
        state.categories.data = clones
    }
}

const caseReducerUpdateCategoryStatus = {
    onSuccess(state: ICustomizationPartState, action: PayloadAction<Category>) {
        const { payload } = action;
        const clones = Array.from(state.categories.data).map((ele) => {
            if (ele.groupId === payload.groupId) return payload;
            return ele
        });
        state.categories.data = clones
    }
}

const caseReducerDeleteCategory = {
    onSuccess(state: ICustomizationPartState, action: PayloadAction<string>) {
        const groupId = action.payload;
        const clones = Array.from(state.categories.data).filter((ele) => ele.groupId !== groupId);
        state.categories.data = clones;
    }
}

const caseCreateCustomizationPart = {
    onSuccess(state: ICustomizationPartState, action: PayloadAction<CustomizationPart>) {
        const { payload } = action;
        const clones = state.modelCustomizationParts.data
        state.modelCustomizationParts.data = [...clones, payload];
    }
}

const caseUpdateCustomizationPartOrders = {
    onSuccess(state: ICustomizationPartState, action: PayloadAction<UpdateCustomizationPartOrder[]>) {
        const clone = Array.of(...state.modelCustomizationParts.data).reduce((acc, cur) => {
            acc.set(cur.partId, cur)
            return acc;
        }, new Map());
        const updateOrder = action.payload.map(({ partId, order }) => ({ ...clone.get(partId), order }));
        state.modelCustomizationParts.data = updateOrder;
    }
}

const caseUpdateCustomizationPartStatus = {
    onSuccess(state: ICustomizationPartState, action: PayloadAction<UpdateCustomizationPartStatus>) {
        const { payload } = action;
        const clones = Array.from(state.modelCustomizationParts.data).map((ele) => {
            if (ele.partId === payload.partId) return { ...ele, active: payload.active };
            return ele
        });
        state.modelCustomizationParts.data = clones
    }
}

const caseUpdateCustomizationPart = {
    onSuccess(state: ICustomizationPartState, action: PayloadAction<CustomizationPart>) {
        const { payload } = action;
        const clones = Array.from(state.modelCustomizationParts.data).map((ele) => {
            if (ele.partId === payload.partId) return payload;
            return ele
        });
        state.modelCustomizationParts.data = clones
    }
}

const caseDeleteCustomizationPart = {
    onSuccess(state: ICustomizationPartState, action: PayloadAction<string>) {
        const { payload } = action;
        const clones = Array.from(state.modelCustomizationParts.data).filter((ele) => {
            return ele.partId !== payload
        });
        state.modelCustomizationParts.data = clones
    }
}


const caseReducerCreateFormGroup = {
    onSuccess(state: ICustomizationPartState, action: PayloadAction<CustomizationPart>) {
        state.currentModelCustomizationPart.data = action.payload;
    }
}

const caseReducerUpdateFormGroup = {
    onSuccess(state: ICustomizationPartState, action: PayloadAction<CustomizationPart>) {
        state.currentModelCustomizationPart.data = action.payload;
    }
}

const caseReducerDeleteFormGroup = {
    onSuccess(state: ICustomizationPartState, action: PayloadAction<CustomizationPart>) {
        state.currentModelCustomizationPart.data = action.payload
    }
}

const extraReducer = (builder: ActionReducerMapBuilder<ICustomizationPartState>): void => {
    // Categories
    builder.addCase(fetchCategoriesList.pending, caseReducerCategories.onLoadingCategories);
    builder.addCase(fetchCategoriesList.fulfilled, caseReducerCategories.onSuccessCategories);
    builder.addCase(fetchCategoriesList.rejected, caseReducerCategories.onFailedCategories);

    // Customization Part
    builder.addCase(fetchModelCustomizationPartsList.pending, caseReducerModelCustomizationPartsList.onLoading);
    builder.addCase(fetchModelCustomizationPartsList.fulfilled, caseReducerModelCustomizationPartsList.onSuccess);
    builder.addCase(fetchModelCustomizationPartsList.rejected, caseReducerModelCustomizationPartsList.onFailed);

    // CurrentCustomization Part
    builder.addCase(fetchCustomizationPartById.pending, caseReducerCurrentCustomization.onLoading);
    builder.addCase(fetchCustomizationPartById.fulfilled, caseReducerCurrentCustomization.onSuccess);
    builder.addCase(fetchCustomizationPartById.rejected, caseReducerCurrentCustomization.onFailed);

    // Create Category
    builder.addCase(createCategory.fulfilled, caseReducerCreateCategory.onSuccess);

    // Update Category Order
    builder.addCase(updateCategoryOrders.fulfilled, caseReducerUpdateCategoryOrder.onSuccess);

    // Update Category Status
    builder.addCase(updateCategoryStatus.fulfilled, caseReducerUpdateCategoryStatus.onSuccess);

    // Update Category Info
    builder.addCase(updateCategory.fulfilled, caseReducerUpdateCategory.onSuccess);

    // Delete Category
    builder.addCase(deleteCategory.fulfilled, caseReducerDeleteCategory.onSuccess);

    // Create Customization Part
    builder.addCase(createCustomizationPart.fulfilled, caseCreateCustomizationPart.onSuccess);

    // Update Customization Part Order
    builder.addCase(updateCustomizationPartOrders.fulfilled, caseUpdateCustomizationPartOrders.onSuccess);

    // Update Customization Part Status
    builder.addCase(updateCustomizationPartStatus.fulfilled, caseUpdateCustomizationPartStatus.onSuccess);

    // Update Customization Part
    builder.addCase(updateCustomizationPart.fulfilled, caseUpdateCustomizationPart.onSuccess);

    builder.addCase(deleteCustomizationPart.fulfilled, caseDeleteCustomizationPart.onSuccess);


    // Create form group
    builder.addCase(createCustomizationPartFormGroup.fulfilled, caseReducerCreateFormGroup.onSuccess);
    // Update form group
    builder.addCase(updateCustomizationPartFormGroup.fulfilled, caseReducerUpdateFormGroup.onSuccess);
    // Delete form group
    builder.addCase(deleteCustomizationPartFormGroup.fulfilled, caseReducerDeleteFormGroup.onSuccess);

}

export default extraReducer;