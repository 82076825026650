import { Fragment, FunctionComponent } from 'react'
import { StateData } from '../../../modules/types/module'
import LoadingContent from '../../molecules/LoadingContent'
import PropsWithChildren from '../../types'

type Props = {
  state: StateData
  className?: string
}

const StateTemplate: FunctionComponent<PropsWithChildren<Props>> = ({
  state,
  className = 'h-full',
  children,
}) => {
  return (
    <Fragment>
      {state === StateData.Loading ? (
        <div className={`${className} flex justify-center items-center `}>
          {' '}
          <LoadingContent />
        </div>
      ) : (
        <></>
      )}
      {state === StateData.Success ? children : <></>}
    </Fragment>
  )
}

export default StateTemplate
