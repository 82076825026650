import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

// import component 👇
import Drawer from 'react-modern-drawer'

// import styles 👇
import 'react-modern-drawer/dist/index.css'
import { useAppDispatch, useAppSelector } from '../../../modules/hook'
import { fetchTranslationList } from '../../../modules/stores/translations/actions'
import { StateData } from '../../../modules/types/module'
import { Translation } from '../../../modules/types/translation'
import Input from '../../atoms/Input'
import PropsWithChildren from '../../types'
import RootMainTemplate from '../RootMainTemplate'
import StateTemplate from '../StateTemplate'

type Props = {
  isOpen: boolean
  onClose: () => void
  translationId?: string
  onSubmit?: (val: Translation) => void
}

type InputForm = {
  translationId: string
}

const DrawerTranslation: FunctionComponent<PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  translationId,
  onSubmit,
}) => {
  const dispatch = useAppDispatch()
  const { register, setValue, handleSubmit } = useForm<InputForm>()

  const buttonSubmitRef = useRef<HTMLButtonElement>(null)

  const [name, setName] = useState('')
  const { state, data } = useAppSelector((state) => state.translations.translations)

  const handleOnSave = useCallback(() => {
    buttonSubmitRef.current?.click()
  }, [])

  const handleOnSubmitForm = useCallback(
    (value: InputForm) => {
      const translation = data.find((ele) => ele.docId === value.translationId)
      if (onSubmit && translation) onSubmit(translation)
      if (onClose) onClose()
    },
    [data, onSubmit, onClose],
  )

  const translations = useMemo(() => {
    if (state !== StateData.Success) return []
    return name
      ? data.filter((translation) =>
          translation.name.toLocaleLowerCase().includes(name.toLocaleLowerCase()),
        )
      : data
  }, [state, data, name])

  useEffect(() => {
    if (state === StateData.Idle) {
      dispatch(fetchTranslationList())
    }
  }, [])

  useEffect(() => {
    if (!translationId || !isOpen) return

    setValue('translationId', translationId)
  }, [translationId])

  return (
    <Drawer size={650} open={isOpen} onClose={onClose} direction='right'>
      <RootMainTemplate title='Translations' onClose={onClose} onSave={handleOnSave}>
        <form onSubmit={handleSubmit(handleOnSubmitForm)} className='w-full h-full'>
          <div className='flex flex-col h-full py-5 px-5 bg-white items-stretch space-y-5'>
            <Input
              className='w-full'
              placeholder='Enter name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className='flex-1 flex flex-col overflow-y-auto pb-10'>
              <StateTemplate state={state}>
                {translations.map((translation) => (
                  <label
                    key={translation.docId}
                    className='flex items-center space-x-5 border-b py-5 px-5'
                  >
                    <input
                      type='radio'
                      {...register('translationId')}
                      value={translation.docId}
                      className='h-5 w-5'
                    />
                    <p className='text-xl'>{translation.name}</p>
                  </label>
                ))}
                <button type='submit' ref={buttonSubmitRef}></button>
              </StateTemplate>
            </div>
          </div>
        </form>
      </RootMainTemplate>
    </Drawer>
  )
}
export default DrawerTranslation
