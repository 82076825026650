import { createSlice } from '@reduxjs/toolkit';
import { module } from '../../types/module';
import extraReducer from './extraReducer';
import { initialState } from './state';

const translationSlice = createSlice({
    name: module.translations,
    initialState,
    reducers: {},
    extraReducers: extraReducer
})

export default translationSlice.reducer;