import { createSlice } from '@reduxjs/toolkit';
import { module } from '../../types/module';
import extraReducer from './extraReducer';
import { ICustomizationPartState, initialState } from './state';


export const customizationPartSlice = createSlice({
    name: module.customizationParts,
    initialState,
    reducers: {
        resetCurrentCustomization(state: ICustomizationPartState) {
            state.currentModelCustomizationPart = initialState.currentModelCustomizationPart
            return state;
        },

    },
    extraReducers: extraReducer
})

export const { resetCurrentCustomization } = customizationPartSlice.actions;
export default customizationPartSlice.reducer