import { createAsyncThunk } from '@reduxjs/toolkit';
import AssetService from '../../services/assets.service';
import { CreateImage, CreateImageCategory, Image, ImageCategory } from '../../types/image';
import { module } from '../../types/module';
import { errorHandler } from '../../utils/errorHandler.util';


export const fetchImageList = createAsyncThunk<Image[]>(
    `${module.assets}/fetchImageList`,
    async (_, { rejectWithValue }) => {
        try {
            const result = await AssetService.getImages();
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)

export const fetchImageCategoriesList = createAsyncThunk<ImageCategory[]>(
    `${module.assets}/fetchImageCategoriesList`,
    async (_, { rejectWithValue }) => {
        try {
            const result = await AssetService.getImageCategories();
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)


export const createImageCategory = createAsyncThunk<ImageCategory, CreateImageCategory>(
    `${module.assets}/createImageCategory`,
    async (params: CreateImageCategory, { rejectWithValue }) => {
        try {
            const result = await AssetService.createImageCategory(params);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)

export const updateImageCategory = createAsyncThunk<ImageCategory, ImageCategory>(
    `${module.assets}/updateImageCategory`,
    async (params: ImageCategory, { rejectWithValue }) => {
        try {
            const { id, ...data } = params;
            const result = await AssetService.updateImageCategory(id, data);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)

export const createImage = createAsyncThunk<Image, CreateImage>(
    `${module.assets}/createImage`,
    async (params: CreateImage, { rejectWithValue }) => {
        try {
            const result = await AssetService.createImage(params);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)


export const deleteImage = createAsyncThunk<string, string>(
    `${module.assets}/deleteImage`,
    async (id: string, { rejectWithValue }) => {
        try {
            await AssetService.deleteImage(id);
            return id
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)