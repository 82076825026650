import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Disclosure, Transition } from '@headlessui/react'

import LinkMenu from '../../atoms/Menu/LinkMenu'
import ChevronDownIcon from '../../atoms/Icons/ChevronDownIcon'
type Props = {
  title: string
  currentPath: string
  navigators: { name: string; link: string }[]
}
const GroupMenuLinks: FunctionComponent<Props> = ({ title, currentPath, navigators }) => {
  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <>
          <Disclosure.Button className='flex w-full justify-between items-center rounded-lg px-3 py-4 text-xl font-light border-none text-left  hover:text-primary'>
            <span>{title}</span>
            <ChevronDownIcon
              className={`${!open ? '-rotate-90' : 'rotate-0'} h-5 w-5 transform duration-100 `}
            />
          </Disclosure.Button>
          <Transition
            show={open}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
          >
            <Disclosure.Panel static className='px-4 pt-1 pb-2'>
              <ul className='flex flex-col items-stretch'>
                {navigators.map((navigator, index) => (
                  <Link key={index.toString()} to={navigator.link}>
                    <LinkMenu isActive={navigator.link.includes(currentPath)}>
                      {navigator.name}
                    </LinkMenu>
                  </Link>
                ))}
              </ul>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

export default GroupMenuLinks
