import { Fragment, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import Drawer from 'react-modern-drawer'
import { ExtendPart } from '../../../modules/types/models'
import { LanguageMap } from '../../../modules/types/translation'
import Card from '../../atoms/Card'
import FormField from '../../atoms/FormField'
import EngFlagIcon from '../../atoms/Icons/Flags/EngFlagIcon'
import FrenchFlagIcon from '../../atoms/Icons/Flags/FrenchFlagIcon'
import ItalianFlagIcon from '../../atoms/Icons/Flags/ItalianFlagIcon'
import SpanishFlagIcon from '../../atoms/Icons/Flags/SpanishFlagIcon'
import Input from '../../atoms/Input'
import TextError from '../../atoms/TextError'
import BoxImageInput from '../../molecules/BoxImageInput'
import { FormMode } from '../../types'
import DrawerImage from '../DrawerImage'
import RootMainTemplate from '../RootMainTemplate'

type Props = {
  isOpen: boolean
  onClose: () => void
  initial?: { index: number; data: ExtendPart }
  mode: FormMode
  onAdd: (value: InputFormExtensionPart) => void
  onUpdate: (index: number, value: ExtendPart) => void
}

export type InputFormExtensionPart = {
  name: LanguageMap
  image: string
}

const DrawerExtensionPartForm: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  initial,
  mode,
  onAdd,
  onUpdate,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<InputFormExtensionPart>()
  const imageValue = watch('image')

  const [openImageDrawer, setOpenImageDrawer] = useState(false)

  const buttonSubmitRef = useRef<HTMLButtonElement>(null)

  // const handleOnOpenImageDrawer = useCallback(
  //   (value: string) => {
  //     setValue('image', value)
  //   },
  //   [imageValue],
  // )

  const handleOnDeleteImage = useCallback(() => {
    setValue('image', '')
  }, [])

  const handleOnClickSave = useCallback(() => {
    buttonSubmitRef.current?.click()
  }, [])

  const handleOnSubmitForm = useCallback(
    (value: InputFormExtensionPart) => {
      // if (onSubmit) onSubmit(value)
      // onClose()
      if (mode === FormMode.Add) {
        onAdd(value)
      } else if (mode === FormMode.Update) {
        onUpdate(initial?.index as number, { ...initial?.data, ...value } as ExtendPart)
      }
      onClose()
    },
    [mode, onAdd, onUpdate, initial],
  )

  const handleOnOpenImageDrawer = useCallback(() => {
    setOpenImageDrawer(true)
  }, [])

  const handleOnCloseImageDrawer = useCallback(() => {
    setOpenImageDrawer(false)
  }, [])

  const handleOnSubmitImageDrawer = useCallback((value: string) => {
    setValue('image', value)
  }, [])

  useEffect(() => {
    if (mode === FormMode.Add || !initial) return
    const { name, image } = initial.data
    setValue('name.en', name?.en || '')
    setValue('name.fr', name?.fr || '')
    setValue('name.es', name?.es || '')
    setValue('name.it', name?.it || '')
    setValue('image', image || '')
  }, [mode, initial])

  useEffect(() => {
    if (isOpen) return
    reset()
  }, [isOpen])

  return (
    <Fragment>
      <Drawer size='100vh' open={isOpen} onClose={onClose} direction='bottom' zIndex={0}>
        <RootMainTemplate title='Parts' onClose={onClose} onSave={handleOnClickSave}>
          <div className='container mx-auto py-5'>
            <form onSubmit={handleSubmit(handleOnSubmitForm)} className='w-full'>
              <Card className='flex space-x-5 w-full px-8 py-8'>
                <div className='flex flex-col space-y-5'>
                  <p className=' text-secondary-font-color'>Image</p>
                  <BoxImageInput
                    src={imageValue || ''}
                    onSelect={handleOnOpenImageDrawer}
                    onDelete={handleOnDeleteImage}
                  />
                  <input className='hidden' {...register('image')} />
                </div>
                <div className='flex-1 flex flex-col space-y-5'>
                  <p className=' text-secondary-font-color'>Translation</p>
                  <div className=' grid grid-cols-2 gap-x-5 gap-y-8'>
                    <FormField label='English' prefix={<EngFlagIcon />}>
                      <Input {...register('name.en', { required: true })} />
                      <TextError>{errors.name?.en ? 'English is required.' : ''}</TextError>
                    </FormField>
                    <FormField label='French' prefix={<FrenchFlagIcon />}>
                      <Input {...register('name.fr', { required: true })} />
                      <TextError>{errors.name?.fr ? 'French is required.' : ''}</TextError>
                    </FormField>
                    <FormField label='Spanish' prefix={<SpanishFlagIcon />}>
                      <Input {...register('name.es', { required: true })} />
                      <TextError>{errors.name?.es ? 'Spanish is required.' : ''}</TextError>
                    </FormField>
                    <FormField label='Italian' prefix={<ItalianFlagIcon />}>
                      <Input {...register('name.it', { required: true })} />
                      <TextError>{errors.name?.it ? 'Italian is required.' : ''}</TextError>
                    </FormField>
                  </div>
                </div>
              </Card>
              <button type='submit' ref={buttonSubmitRef}></button>
            </form>
          </div>
        </RootMainTemplate>
      </Drawer>
      <DrawerImage
        isOpen={openImageDrawer}
        onClose={handleOnCloseImageDrawer}
        onSubmit={handleOnSubmitImageDrawer}
      />
    </Fragment>
  )
}

export default DrawerExtensionPartForm
