import { FunctionComponent, memo, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { DataStatus } from '../../../modules/types/core'
import { Model } from '../../../modules/types/models'
import ProductCard from '../../molecules/ProductCard'

type Props = {
  models: Model[]
  onEdit?: (id: string) => void
  onSync?: (id: string) => void
  onChangeOrder?: (val: Model[]) => void
  onChangeStatus?: (id: string, status: DataStatus) => void
}

const reorder = (list: Model[], startIndex: number, endIndex: number): Model[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

// result: DropResult, provided: ResponderProvided
const DndProductsList: FunctionComponent<Props> = ({
  models = [],
  onEdit,
  onSync,
  onChangeOrder,
  onChangeStatus,
}) => {
  const [list, setList] = useState<Model[]>(models)

  function onDragEnd({ destination, source }: DropResult) {
    if (!destination) {
      return
    }

    if (destination.index === source.index) {
      return
    }

    const items = reorder(models, source.index, destination.index)
    const updateOrderItem = items.map((ele, index) => ({ ...ele, order: index + 1 }))
    if (onChangeOrder) onChangeOrder(updateOrderItem)
    setList(updateOrderItem)
  }

  function handleOnEdit(id: string) {
    if (onEdit) onEdit(id)
  }

  function handleOnSync(modelId: string) {
    if (onSync) onSync(modelId)
  }

  const handleOnChangeStatus = (id: string, status: DataStatus) => {
    if (onChangeStatus) onChangeStatus(id, status)
  }

  useEffect(() => {
    setList(models)
  }, [models])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {list.map((model, index) => (
              <Draggable key={model.modelId} draggableId={model.modelId} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ProductCard
                      model={model}
                      onChangeStatus={(val) => handleOnChangeStatus(model.modelId, val)}
                      onEdit={() => handleOnEdit(model.modelId)}
                      onSync={() => handleOnSync(model.modelId)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default memo(DndProductsList)
