import { Fragment, FunctionComponent, ReactNode } from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import PropsWithChildren from '../../types'

type Props = {
  header?: ReactNode
  className?: string
  onChange?: (val: boolean) => void
}

const Accordion: FunctionComponent<PropsWithChildren<Props>> = ({
  header,
  className,
  onChange,
  children,
}) => {
  function handleOnChange(v: boolean) {
    if (onChange) onChange(v)
  }
  return (
    <div className={className}>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className='w-full' onClick={() => handleOnChange(!open)}>
              {header}
            </Disclosure.Button>
            <Transition
              show={open}
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <Disclosure.Panel>{children}</Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default Accordion
