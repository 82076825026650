import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { ModelProperty, UpdateModelPropertyOrder, UpdateModelPropertyStatus } from '../../types/modelProperty';
import { StateData } from '../../types/module';
import { createModelProperty, deleteModelProperty, fetchModelPropertiesByCustomizationPart, fetchModelPropertyById, updateModelProperty, updateModelPropertyOrders, updateModelPropertyStatus } from './action';
import { IPropertyState } from './state';



const caseReducerCurrentModelProperty = {
    onLoading(state: IPropertyState) {
        state.currentModelProperty.state = StateData.Loading;
    },
    onSuccess(state: IPropertyState, action: PayloadAction<ModelProperty>) {
        state.currentModelProperty.state = StateData.Success;
        state.currentModelProperty.data = action.payload
    },
    onFailed(state: IPropertyState, action: PayloadAction<unknown>) {
        state.currentModelProperty.state = StateData.Failed;
        state.currentModelProperty.error = action.payload
    }
}

const caseReducerModelProperties = {
    onLoading(state: IPropertyState) {
        state.modelProperties.state = StateData.Loading;
    },
    onSuccess(state: IPropertyState, action: PayloadAction<ModelProperty[]>) {
        state.modelProperties.state = StateData.Success;
        state.modelProperties.data = action.payload
    },
    onFailed(state: IPropertyState, action: PayloadAction<unknown>) {
        state.currentModelProperty.state = StateData.Failed;
        state.currentModelProperty.error = action.payload
    }
}

const caseReducerCreateModelProperty = {
    onSuccess(state: IPropertyState, action: PayloadAction<ModelProperty>) {
        const { payload } = action;
        state.modelProperties.data = [...state.modelProperties.data, payload];
    }
}

const caseReducerUpdateModelProperty = {
    onSuccess(state: IPropertyState, action: PayloadAction<ModelProperty>) {
        const { payload } = action;
        const clones = Array.from(state.modelProperties.data).map((ele) => {
            if (ele.propertyId === payload.propertyId) return payload;
            return ele
        });
        state.modelProperties.data = clones
    }
}

const caseReducerUpdateModelPropertyOrder = {
    onSuccess(state: IPropertyState, action: PayloadAction<UpdateModelPropertyOrder[]>) {
        const { payload } = action;
        const clone = Array.of(...state.modelProperties.data).reduce((acc, cur) => {
            acc.set(cur.propertyId, cur)
            return acc;
        }, new Map());
        const updateOrder = payload.map(({ propertyId, order }) => ({ ...clone.get(propertyId), order }));
        state.modelProperties.data = updateOrder;
    }
}

const caseReducerUpdateModelPropertyStatus = {
    onSuccuss(state: IPropertyState, action: PayloadAction<UpdateModelPropertyStatus>) {
        const { payload } = action;
        const clones = Array.from(state.modelProperties.data).map((ele) => {
            if (ele.propertyId === payload.propertyId) return { ...ele, status: payload.status };
            return ele
        });
        state.modelProperties.data = clones
    }
}

const caseReducerDeleteModelProperty = {
    onSuccess(state: IPropertyState, action: PayloadAction<string>) {
        const id = action.payload;
        const clones = Array.of(...state.modelProperties.data).filter((ele) => ele.propertyId !== id);
        state.modelProperties.data = clones
    }
}


const extraReducer = (builder: ActionReducerMapBuilder<IPropertyState>): void => {
    // Current Model Property
    builder.addCase(fetchModelPropertyById.pending, caseReducerCurrentModelProperty.onLoading);
    builder.addCase(fetchModelPropertyById.fulfilled, caseReducerCurrentModelProperty.onSuccess);
    builder.addCase(fetchModelPropertyById.rejected, caseReducerCurrentModelProperty.onFailed);

    // Get Model Property
    builder.addCase(fetchModelPropertiesByCustomizationPart.pending, caseReducerModelProperties.onLoading);
    builder.addCase(fetchModelPropertiesByCustomizationPart.fulfilled, caseReducerModelProperties.onSuccess);
    builder.addCase(fetchModelPropertiesByCustomizationPart.rejected, caseReducerModelProperties.onFailed);

    // Create Model Property
    builder.addCase(createModelProperty.fulfilled, caseReducerCreateModelProperty.onSuccess);

    // Update Model Property
    builder.addCase(updateModelProperty.fulfilled, caseReducerUpdateModelProperty.onSuccess);

    // Update Model Property Orders
    builder.addCase(updateModelPropertyOrders.fulfilled, caseReducerUpdateModelPropertyOrder.onSuccess);

    // Update Model Property Status
    builder.addCase(updateModelPropertyStatus.fulfilled, caseReducerUpdateModelPropertyStatus.onSuccuss);

    // Update Delete Property
    builder.addCase(deleteModelProperty.fulfilled, caseReducerDeleteModelProperty.onSuccess);
}

export default extraReducer