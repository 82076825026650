import { Dialog, Transition } from '@headlessui/react'
import { Fragment, FunctionComponent, memo, useCallback, useEffect } from 'react'
import { Translation } from '../../../modules/types/translation'
import FormField from '../../atoms/FormField'
import Input from '../../atoms/Input'
import { useForm, SubmitHandler } from 'react-hook-form'
import FlatButton from '../../atoms/Button/FlatButton'
import OutlinedButton from '../../atoms/Button/OutlinedButton'

export enum FormMode {
  Add = 'add',
  Update = 'update',
}

export type FormTranslation = {
  name: string
  en: string
  es: string
  fr: string
  it: string
}

type Props = {
  isOpen: boolean
  translation?: Translation
  mode: FormMode
  onCloseModal?: () => void
  onSubmitCreate?: (val: FormTranslation) => void
  onSubmitUpdate?: (id: string, val: FormTranslation) => void
}

const TranslationFormModal: FunctionComponent<Props> = ({
  isOpen,
  translation,
  mode,
  onCloseModal,
  onSubmitCreate,
  onSubmitUpdate,
}) => {
  const { register, handleSubmit, setValue, reset } = useForm<FormTranslation>({
    defaultValues: {
      name: translation?.name || '',
      en: translation?.translation?.en || '',
      fr: translation?.translation?.fr || '',
      es: translation?.translation?.es || '',
      it: translation?.translation?.it || '',
    },
  })

  function handleOnCloseModal() {
    reset()
    if (onCloseModal) onCloseModal()
  }

  const handleOnSubmit: SubmitHandler<FormTranslation> = (data) => {
    if (mode === FormMode.Add && onSubmitCreate) onSubmitCreate(data)
    if (mode === FormMode.Update && onSubmitUpdate) onSubmitUpdate(String(translation?.docId), data)
    if (onCloseModal) onCloseModal()
    reset()
  }

  useEffect(() => {
    if (!translation) return
    console.log(translation)
    setValue('name', translation?.name)
    setValue('en', translation?.translation?.en || '')
    setValue('fr', translation?.translation?.fr || '')
    setValue('es', translation?.translation?.es || '')
    setValue('it', translation?.translation?.it || '')
  }, [translation])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={handleOnCloseModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900 pb-2 border-b'
                >
                  {mode === FormMode.Add ? 'Create New Translations' : 'Update Translation'}
                </Dialog.Title>
                <form
                  onSubmit={handleSubmit(handleOnSubmit)}
                  className='w-full flex flex-col space-y-5 py-3'
                >
                  <FormField label='Name'>
                    <Input {...register('name')} />
                  </FormField>
                  <FormField label='English'>
                    <Input {...register('en')} />
                  </FormField>
                  <FormField label='French'>
                    <Input {...register('es')} />
                  </FormField>
                  <FormField label='Spanish'>
                    <Input {...register('fr')} />
                  </FormField>
                  <FormField label='Italian'>
                    <Input {...register('it')} />
                  </FormField>
                  <div className='flex justify-end items-center space-x-5'>
                    <OutlinedButton type='button' onClick={handleOnCloseModal}>
                      Cancel
                    </OutlinedButton>
                    <FlatButton type='submit'>Submit</FlatButton>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default memo(TranslationFormModal)
