import { createAsyncThunk } from '@reduxjs/toolkit';
import ModelPropertyApi from '../../services/modelProperty.service';
import { CreateModelProperty, ModelProperty, UpdateModelProperty, UpdateModelPropertyOrder, UpdateModelPropertyStatus } from '../../types/modelProperty';
import { module } from '../../types/module';
import { errorHandler } from '../../utils/errorHandler.util';

export const fetchModelPropertyById = createAsyncThunk<ModelProperty, string>(
    `${module.property}/fetchModelPropertyById`,
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await ModelPropertyApi.getModelProperty(id);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const fetchModelPropertiesByCustomizationPart = createAsyncThunk<ModelProperty[], string>(
    `${module.property}/fetchModelPropertiesByCustomizationPart`,
    async (customizationPartId: string, { rejectWithValue }) => {
        try {
            const result = await ModelPropertyApi.getModelPropertyByCustomizationPartId(customizationPartId);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const createModelProperty = createAsyncThunk<ModelProperty, CreateModelProperty>(
    `${module.property}/createModelProperty`,
    async (params: CreateModelProperty, { rejectWithValue }) => {
        try {
            const result = await ModelPropertyApi.createModelProperty(params);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const updateModelProperty = createAsyncThunk<ModelProperty, UpdateModelProperty>(
    `${module.property}/updateModelProperty`,
    async (params: UpdateModelProperty, { rejectWithValue }) => {
        try {
            const { propertyId, ...data } = params;
            const result = await ModelPropertyApi.updateModelProperty(propertyId, data);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const updateModelPropertyOrders = createAsyncThunk<UpdateModelPropertyOrder[], UpdateModelPropertyOrder[]>(
    `${module.property}/updateModelPropertyOrders`,
    async (params: UpdateModelPropertyOrder[], { rejectWithValue }) => {
        try {
            await ModelPropertyApi.updateModelPropertyOrder(params);
            return params;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)


export const updateModelPropertyStatus = createAsyncThunk<UpdateModelPropertyStatus, UpdateModelPropertyStatus>(
    `${module.property}/updateModelPropertyStatus`,
    async (params: UpdateModelPropertyStatus, { rejectWithValue }) => {
        try {
            const { propertyId, status } = params
            await ModelPropertyApi.updateModelPropertyStatus(propertyId, status);
            return params;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const deleteModelProperty = createAsyncThunk<string, string>(
    `${module.property}/deleteModelProperty`,
    async (id: string, { rejectWithValue }) => {
        try {
            await ModelPropertyApi.deleteModelProperty(id);
            return id
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)
