import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormMode } from '../../types'

import Drawer from 'react-modern-drawer'
import RootMainTemplate from '../RootMainTemplate'
import Tabs from '../../molecules/Tab'
import { FormGroup } from '../../../modules/types/customizationPart'
import { useAppDispatch, useAppSelector } from '../../../modules/hook'
import {
  createModelProperty,
  fetchModelPropertiesByCustomizationPart,
  fetchModelPropertyById,
  updateModelProperty,
} from '../../../modules/stores/properties/action'
import { StateData } from '../../../modules/types/module'

import ModelPropertyInfoPanel, {
  ModelPropertyInfoPanelRef,
  OutputModelPropertyForm,
} from '../../organisms/ModelPropertyInfoPanel'
import Card from '../../atoms/Card'
import ModelPropertyItemManagementPanel, {
  ModelPropertyItemManagementPanelRef,
} from '../../organisms/ModelPropertyItemManagementPanel'
import StateTemplateV2 from '../StateTemplateV2'
import { CreateModelProperty, UpdateModelProperty } from '../../../modules/types/modelProperty'
import LoadingDialog from '../../molecules/LoadingDialog'
import useToastMessage from '../../../hooks/useToastMessage.hook'
import { resetCurrentModelProperty } from '../../../modules/stores/properties'
import DemoThemeModalPanel from '../../molecules/DemoThemeModelPanel'

type Props = {
  isOpen: boolean
  onClose: () => void
  mode: FormMode
  subGroups: FormGroup[]
  propertyId?: string
  modelId: string
  customizationPartId: string
}
const items = [
  {
    key: 'property',
    name: 'Property',
  },
  {
    key: 'component',
    name: 'Component',
  },
]

const defaultPreview = { bgBeginValue: '', bgEndValue: '', imageValue: '' }

const DrawerModelPropertyForm: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  subGroups,
  mode,
  modelId,
  customizationPartId,
  propertyId,
}) => {
  const dispatch = useAppDispatch()
  const { state, data } = useAppSelector((state) => state.property.currentModelProperty)

  const propertyFormRef = useRef<ModelPropertyInfoPanelRef>(null)
  const propertyItemRef = useRef<ModelPropertyItemManagementPanelRef>(null)

  const { showError, showSuccess } = useToastMessage()

  const [previewData, setPreviewData] = useState<{
    bgBeginValue: string
    bgEndValue: string
    imageValue: string
  }>(defaultPreview)

  const [isOpenLoadingDialog, setIsOpenLoadingDialog] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState(items[0].key)

  const handleOnChangeTab = useCallback((value: string) => {
    setCurrentTab(value)
  }, [])

  const handleOnClickSave = useCallback(() => {
    propertyFormRef.current?.onSubmit()
  }, [])

  const handleOnSubmitModelPropertyInfo = useCallback(
    (values: OutputModelPropertyForm) => {
      const payload = {
        ...values,
        modelId,
        customizationPartId,
        configures: propertyItemRef.current?.getConfiguration || [],
      }
      if (mode === FormMode.Add) {
        onAddModelProperty(payload)
      } else if (mode === FormMode.Update) {
        console.log('update payload: ', payload)
        onUpdateModelProperty({ ...payload, propertyId: propertyId || '' })
      }
    },
    [mode],
  )

  const onCloseDrawer = useCallback(() => {
    propertyFormRef.current?.onReset()
    propertyItemRef.current?.onResetConfiguration()
    dispatch(resetCurrentModelProperty())
    setPreviewData(defaultPreview)
    if (onClose) onClose()
  }, [])

  const onAddModelProperty = useCallback(async (payload: CreateModelProperty) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(createModelProperty(payload)).unwrap()
      showSuccess('Create Model Property Successfully')
      dispatch(fetchModelPropertiesByCustomizationPart(customizationPartId))

      onCloseDrawer()
    } catch (error) {
      console.log(error)
      showError('Create Model Property Failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const onUpdateModelProperty = useCallback(async (payload: UpdateModelProperty) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(updateModelProperty(payload)).unwrap()
      showSuccess('Update Model Property Successfully')
      dispatch(fetchModelPropertiesByCustomizationPart(customizationPartId))
      onCloseDrawer()
    } catch (error) {
      showError('Update Model Property Failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const isLoadingState = useMemo(() => {
    return mode === FormMode.Update && state === StateData.Loading
  }, [mode, state])

  const isSuccessState = useMemo(() => {
    return mode === FormMode.Add || state === StateData.Success
  }, [mode, state])

  useEffect(() => {
    if (mode !== FormMode.Update || !propertyId || data?.propertyId === propertyId || !isOpen)
      return
    dispatch(fetchModelPropertyById(propertyId))
  }, [propertyId, mode, data?.propertyId, isOpen])

  useEffect(() => {
    return () => {
      dispatch(resetCurrentModelProperty())
    }
  }, [])

  return (
    <>
      <Drawer size='100vh' open={isOpen} onClose={onCloseDrawer} direction='bottom' zIndex={0}>
        <RootMainTemplate
          title='Property Setting'
          onClose={onCloseDrawer}
          onSave={handleOnClickSave}
        >
          <StateTemplateV2 isLoading={isLoadingState} isSuccess={isSuccessState}>
            <div className='flex h-full w-full overflow-hidden'>
              <Card className='flex-1 flex flex-col h-full'>
                <Tabs items={items} onChange={handleOnChangeTab} selectedKey={currentTab} />
                <div className='flex-1 overflow-y-auto '>
                  <div className={`h-full ${currentTab === items[0].key ? '' : 'hidden'}`}>
                    <ModelPropertyInfoPanel
                      ref={propertyFormRef}
                      data={data}
                      subGroups={subGroups}
                      onSubmit={handleOnSubmitModelPropertyInfo}
                      onChangeTheme={setPreviewData}
                    />
                  </div>
                  <div className={`h-full ${currentTab === items[1].key ? '' : 'hidden'}`}>
                    <ModelPropertyItemManagementPanel
                      ref={propertyItemRef}
                      configurations={data?.configures || []}
                    />
                  </div>
                </div>
              </Card>
              <div className=' w-96 h-full'>
                <DemoThemeModalPanel
                  bgStart={previewData?.bgBeginValue || ''}
                  bgEnd={previewData?.bgEndValue || ''}
                  image={previewData?.imageValue || ''}
                />
              </div>
            </div>
          </StateTemplateV2>
        </RootMainTemplate>
      </Drawer>
      <LoadingDialog isOpen={isOpenLoadingDialog} onClose={() => setIsOpenLoadingDialog(false)} />
    </>
  )
}

export default DrawerModelPropertyForm
