import { useCallback, useState } from 'react';
import { ConfirmDeleteType } from '../components/types';



const useConfirmDelete = () => {
    const [openConfirmDelete, setOpenConfirmDelete] = useState<ConfirmDeleteType<any>>({ status: false });

    const onOpenDeleteModal = useCallback((value: unknown) => {
        console.log('on open delete modal')
        setOpenConfirmDelete({ status: true, value });
    }, [])

    const onCloseDeleteModal = useCallback(() => {
        setOpenConfirmDelete({ status: false });
    }, [])


    return {
        openConfirmDelete,
        onOpenDeleteModal,
        onCloseDeleteModal
    }
}

export default useConfirmDelete;