import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { StateAction, StateData } from '../../types/module';
import { Translation } from '../../types/translation';
import { createTranslation, deleteTranslation, fetchTranslationList, updateTranslation } from './actions';
import { ITranslationState } from './state';



const caseReducerTranslations = {
    onLoadingTranslations(state: ITranslationState) {
        state.translations.state = StateData.Loading;
    },
    onSuccessTranslations(state: ITranslationState, action: PayloadAction<Translation[]>) {
        state.translations.state = StateData.Success;
        state.translations.data = action.payload
    },
    onFailedTranslations(state: ITranslationState, action: PayloadAction<unknown>) {
        state.translations.state = StateData.Failed;
        state.translations.error = action.payload;
    },
    onCreatingTranslation(state: ITranslationState) {
        state.translations.action = StateAction.OnCreating;
    },
    onCreatedSuccessTranslations(state: ITranslationState, action: PayloadAction<Translation>) {
        const translations = Array.from(state.translations.data);
        translations.push(action.payload);

        state.translations.action = StateAction.OnSuccess;
        state.translations.data = translations;
    },
    onCreatedFailedTranslations(state: ITranslationState, action: PayloadAction<unknown>) {
        state.translations.action = StateAction.OnFailed;
        state.translations.error = action.payload;
    },
    onUpdatingTranslation(state: ITranslationState) {
        state.translations.action = StateAction.OnUpdating;
    },
    onUpdatedSuccessTranslation(state: ITranslationState, action: PayloadAction<Translation>) {
        const translations = Array.from(state.translations.data);

        const index = translations.findIndex((translation) => translation.docId === action.payload.docId);
        translations[index] = action.payload;

        state.translations.action = StateAction.OnSuccess;
        state.translations.data = [...translations];
    },


    onDeleteTranslation(state: ITranslationState, action: PayloadAction<string>) {
        const id = action.payload;
        const clones = Array.from(state.translations.data).filter((ele) => ele.docId !== id);
        state.translations.data = clones;
    }
}


const extraReducer = (builder: ActionReducerMapBuilder<ITranslationState>): void => {
    builder.addCase(fetchTranslationList.pending, caseReducerTranslations.onLoadingTranslations);
    builder.addCase(fetchTranslationList.fulfilled, caseReducerTranslations.onSuccessTranslations);
    builder.addCase(fetchTranslationList.rejected, caseReducerTranslations.onFailedTranslations)

    builder.addCase(createTranslation.pending, caseReducerTranslations.onCreatingTranslation);
    builder.addCase(createTranslation.fulfilled, caseReducerTranslations.onCreatedSuccessTranslations);
    builder.addCase(createTranslation.rejected, caseReducerTranslations.onFailedTranslations);

    // builder.addCase(updateTranslation.pending, caseReducerTranslations.onUpdatingTranslation);
    builder.addCase(updateTranslation.fulfilled, caseReducerTranslations.onUpdatedSuccessTranslation);

    builder.addCase(deleteTranslation.fulfilled, caseReducerTranslations.onDeleteTranslation)
    // builder.addCase(deleteTranslation.fulfilled, caseReducerTranslations.onDeleteTranslation);
}

export default extraReducer