import { FunctionComponent } from 'react'

const FrenchFlagIcon: FunctionComponent = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='29'
      height='19'
      viewBox='0 0 29 19'
    >
      <defs>
        <pattern
          id='pattern'
          width='100%'
          height='100%'
          preserveAspectRatio='xMidYMid slice'
          viewBox='0 0 150 100'
        >
          <image
            width='150'
            height='100'
            xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAABkCAYAAABkW8nwAAAACXBIWXMAAC4jAAAuIwF4pT92AAABZ0lEQVR4nO3SsU2CURSA0SehZQJbamlcgQHo2cjS2oWIlQ0T2NlpASYCiSuYj/Cbcwa4796X7+5h/XweE/e4uh8vT5upn/HrZ7cbh+32Bjb5m9mUl+d2CYuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIuEsEgIi4SwSAiLhLBICIvE/D986+fXcby+vV/tvcXpOJbfH8ns036fzL2qMcYF/XcVJKZlWnUAAAAASUVORK5CYII='
          ></image>
        </pattern>
      </defs>
      <path fill='url(#pattern)' d='M0 0H29V19H0z' data-name='ดาวน์โหลด (1)'></path>
    </svg>
  )
}

export default FrenchFlagIcon
