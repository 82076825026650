import { FunctionComponent, memo } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { CustomizationPart } from '../../../modules/types/customizationPart'
import DropdownIconButton from '../../atoms/DropdownIconButton'
import ChevronRightIcon from '../../atoms/Icons/ChevronRightIcon'
import EllipsisVertical from '../../atoms/Icons/EllipsisVertical'
import Switcher from '../../atoms/Switcher'

type Props = {
  customizationParts: CustomizationPart[]
  onChangeStatus?: (id: string, status: boolean) => void
  onChangeOrder?: (items: CustomizationPart[]) => void
  onSelectPart?: (val: CustomizationPart) => void
  onEdit?: (val: CustomizationPart) => void
  onDelete?: (id: string) => void
}
const reorder = (
  list: CustomizationPart[],
  startIndex: number,
  endIndex: number,
): CustomizationPart[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const DndModelCustomizationParts: FunctionComponent<Props> = ({
  customizationParts,
  onChangeStatus,
  onChangeOrder,
  onSelectPart,
  onEdit,
  onDelete,
}) => {
  function onDragEnd({ destination, source }: DropResult) {
    if (!destination) {
      return
    }

    if (destination.index === source.index) {
      return
    }

    const newOrderingList = reorder(customizationParts, source.index, destination.index)
    if (onChangeOrder) onChangeOrder(newOrderingList)
  }

  function handleSelectPart(val: CustomizationPart) {
    if (onSelectPart) onSelectPart(val)
  }

  function handleOnSelectMenu(key: string, index: number, val: CustomizationPart) {
    if (key === 'on-edit' && onEdit) onEdit(val)
    if (key === 'on-delete' && onDelete) onDelete(val.partId)
  }

  function handleOnChangeStatus(id: string, status: boolean) {
    if (onChangeStatus) onChangeStatus(id, status)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {customizationParts.map((part, index) => (
              <Draggable key={index} draggableId={index.toString()} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className='flex space-x-4 items-center px-5 py-3 bg-secondary-bg-color border my-2'
                  >
                    <Switcher
                      enabled={part.active}
                      onChange={(v) => handleOnChangeStatus(part.partId, v)}
                    />
                    <div className='flex-1'>
                      <p className='text-xl font-bold'>{part.name}</p>
                    </div>
                    <DropdownIconButton
                      icon={<EllipsisVertical />}
                      menus={[
                        {
                          key: 'on-edit',
                          label: 'Edit',
                        },
                        {
                          key: 'on-delete',
                          label: 'Delete',
                        },
                      ]}
                      onSelected={(key) => handleOnSelectMenu(key, index, part)}
                    />
                    <button className='m-0' onClick={() => handleSelectPart(part)}>
                      <ChevronRightIcon />
                    </button>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default memo(DndModelCustomizationParts)
