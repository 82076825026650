import { PlusCircleIcon } from 'lucide-react'
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import Drawer from 'react-modern-drawer'

// import styles 👇
import 'react-modern-drawer/dist/index.css'
import useConfirmDelete from '../../../hooks/useConfirmDelete.hook'
import useToastMessage from '../../../hooks/useToastMessage.hook'
import { useAppDispatch, useAppSelector } from '../../../modules/hook'
import {
  createImage,
  createImageCategory,
  deleteImage,
  fetchImageCategoriesList,
  fetchImageList,
  updateImageCategory,
} from '../../../modules/stores/assets/action'
import { CreateCategory } from '../../../modules/types/customizationPart'
import { CreateImage, CreateImageCategory, ImageCategory } from '../../../modules/types/image'

import { StateData } from '../../../modules/types/module'
import Input from '../../atoms/Input'
import Selector from '../../atoms/Selector'
import ConfirmDeleteModal from '../../molecules/ConfirmDeleteModal'
import ImageCategoryFormModal from '../../molecules/ImageCategoryFormModal'
import ImageFormModal from '../../molecules/ImageFormModal'
import LoadingDialog from '../../molecules/LoadingDialog'
import GridImageUploading from '../../organisms/GridImageUploading'
import PropsWithChildren, { FormInputModal, FormMode } from '../../types'
import RootMainTemplate from '../RootMainTemplate'

import StateTemplateV2 from '../StateTemplateV2'

type Props = {
  isOpen: boolean
  value?: string
  onClose: () => void
  onSubmit?: (val: string) => void
}

const DrawerImage: FunctionComponent<PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  value,
  onSubmit,
}) => {
  const dispatch = useAppDispatch()

  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  const [selectedImage, setSelectedImage] = useState<string>('')
  const [isOpenLoadingDialog, setIsOpenLoadingDialog] = useState<boolean>(false)
  const [openCreateImageCategory, setOpenCreateImageCategory] = useState<
    FormInputModal<ImageCategory>
  >({ status: false, mode: FormMode.Idle })

  const [openImageFormDialog, setOpenImageFormDialog] = useState<{
    status: boolean
    categoryId?: string
  }>({ status: false })
  const { showSuccess, showError } = useToastMessage()
  const { openConfirmDelete, onOpenDeleteModal, onCloseDeleteModal } = useConfirmDelete()

  const { imageCategories, images } = useAppSelector((state) => ({
    images: state.assets.images,
    imageCategories: state.assets.imageCategories,
  }))

  const handleOnSelectImage = useCallback((val: string) => {
    setSelectedImage(val)
  }, [])

  const handleOnSave = useCallback(() => {
    if (onSubmit) onSubmit(selectedImage)
    if (onClose) onClose()
  }, [selectedImage])

  const handleOnCloseImageCategory = useCallback(() => {
    setOpenCreateImageCategory({ status: false, mode: FormMode.Idle })
  }, [])

  const handleOnOpenCreateImageCategory = useCallback(() => {
    setOpenCreateImageCategory({ status: true, mode: FormMode.Add })
  }, [])

  const handleOnOpenUpdateImageCategory = useCallback((val: ImageCategory) => {
    setOpenCreateImageCategory({ status: true, mode: FormMode.Update, value: val })
  }, [])

  const handleOnOpenImageFormDialog = useCallback((categoryId: string) => {
    setOpenImageFormDialog({ status: true, categoryId })
  }, [])

  const handleOnCloseImageFormDialog = useCallback(() => {
    setOpenImageFormDialog({ status: false })
  }, [])

  const onAddCategory = useCallback(
    async (val: CreateImageCategory) => {
      try {
        setIsOpenLoadingDialog(true)
        await dispatch(createImageCategory(val)).unwrap()
        showSuccess('Create category successfully')
      } catch (error) {
        showError('Create category failed')
      } finally {
        setIsOpenLoadingDialog(false)
        handleOnCloseImageCategory()
      }
    },
    [handleOnCloseImageCategory],
  )

  const onUpdateCategory = useCallback(
    async (val: ImageCategory) => {
      try {
        setIsOpenLoadingDialog(true)
        await dispatch(updateImageCategory(val)).unwrap()
        showSuccess('Update category successfully')
      } catch (error) {
        showError('Update category failed')
      } finally {
        setIsOpenLoadingDialog(false)
        handleOnCloseImageCategory()
      }
    },
    [handleOnCloseImageCategory],
  )

  const handleOnSubmitImage = useCallback(
    async (val: CreateImage) => {
      try {
        setIsOpenLoadingDialog(true)

        await dispatch(createImage(val)).unwrap()
        showSuccess('Create Image Successfully')
      } catch (error) {
        showError('Create Image failed')
      } finally {
        setIsOpenLoadingDialog(false)
        handleOnCloseImageFormDialog()
      }
    },
    [handleOnCloseImageFormDialog],
  )

  const onDeleteImage = useCallback(async (id: string) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(deleteImage(id)).unwrap()
      showSuccess('Delete Image Successfully')
    } catch (error) {
      console.log(error)
      showError('Cannot Delete Image')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const categoryOptions = useMemo(() => {
    const { state, data } = imageCategories
    const arr = [{ label: 'All', value: '' }]
    if (state !== StateData.Success) return arr
    return [...arr, ...data.map((ele) => ({ value: ele.id, label: ele.name }))]
  }, [imageCategories])

  const imageList = useMemo(() => {
    const { state, data } = images
    if (state !== StateData.Success) return []
    return name
      ? data.filter((ele) => ele.name.toLocaleLowerCase().includes(name.toLocaleLowerCase()))
      : data
  }, [name, images])

  const categoryList = useMemo(() => {
    const { state, data } = imageCategories
    if (state !== StateData.Success) return []
    return category ? data.filter((ele) => ele.id === category) : data
  }, [category, imageCategories])

  useEffect(() => {
    if (!isOpen) return
    if (images.state === StateData.Idle) {
      dispatch(fetchImageList())
    }
    if (imageCategories.state === StateData.Idle) {
      dispatch(fetchImageCategoriesList())
    }
  }, [isOpen])

  useEffect(() => {
    if (!value) return
    setSelectedImage(value)
  }, [value])

  return (
    <>
      <Drawer size={650} open={isOpen} onClose={onClose} direction='right' zIndex={0}>
        <RootMainTemplate title='Image' onClose={onClose} onSave={handleOnSave}>
          <div className='flex flex-col h-full py-5 px-5 bg-white items-stretch space-y-5'>
            <div className='flex space-x-5'>
              <Selector
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                options={categoryOptions}
              />
              <div className='flex-1'>
                <Input
                  className='w-full'
                  placeholder='Enter name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className='flex-1 flex flex-col overflow-y-auto pb-10'>
              <StateTemplateV2
                isLoading={[imageCategories.state, images.state].includes(StateData.Loading)}
                isSuccess={[imageCategories.state, images.state].every(
                  (state) => state === StateData.Success,
                )}
              >
                <div className='flex flex-col items-stretch pb-32'>
                  {categoryList.map((category) => {
                    const _images = imageList.filter((ele) => ele.categoryId === category.id)

                    if (!_images.length) return <Fragment key={category.id}></Fragment>

                    return (
                      <Fragment key={category.id}>
                        <GridImageUploading
                          title={category.name}
                          images={_images}
                          value={selectedImage}
                          onSelect={handleOnSelectImage}
                          onAdd={() => handleOnOpenImageFormDialog(category.id)}
                          onUpdateCategory={() => handleOnOpenUpdateImageCategory(category)}
                          onDelete={onOpenDeleteModal}
                        />
                        <div className='border-b border-2' />
                      </Fragment>
                    )
                  })}
                  <button
                    type='button'
                    onClick={handleOnOpenCreateImageCategory}
                    className=' border-2 rounded-md flex flex-col items-center py-5 mt-5 space-y-3 mb-5 text-primary '
                  >
                    <PlusCircleIcon className='w-7 h-7' />
                    <p>New Category</p>
                  </button>
                </div>
              </StateTemplateV2>
            </div>
          </div>
        </RootMainTemplate>
      </Drawer>
      <ConfirmDeleteModal
        isOpen={openConfirmDelete.status}
        value={openConfirmDelete?.value}
        onClose={onCloseDeleteModal}
        onConfirm={onDeleteImage}
      />
      <ImageCategoryFormModal
        isOpen={openCreateImageCategory.status}
        mode={openCreateImageCategory.mode}
        value={openCreateImageCategory.value}
        onClose={handleOnCloseImageCategory}
        onAdd={onAddCategory}
        onUpdate={onUpdateCategory}
      />
      <ImageFormModal
        isOpen={openImageFormDialog.status}
        onSubmit={handleOnSubmitImage}
        categoryId={String(openImageFormDialog.categoryId)}
        onClose={handleOnCloseImageFormDialog}
      />
      <LoadingDialog isOpen={isOpenLoadingDialog} onClose={() => setIsOpenLoadingDialog(false)} />
    </>
  )
}
export default DrawerImage
