import { ImageIcon } from 'lucide-react'
import { FunctionComponent } from 'react'
import Card from '../../atoms/Card'

type Props = {
  bgStart: string
  bgEnd: string
  image: string
}

const DemoThemeModalPanel: FunctionComponent<Props> = ({ bgStart, bgEnd, image }) => {
  return (
    <div className='h-ful flex flex-col'>
      <div className='px-3 h-16 flex items-center bg-primary-bg-color'>Review</div>
      <div className='flex-1 py-5 px-3'>
        <Card>
          <div className='flex flex-col items-stretch  py-5 px-5 space-y-5'>
            <p>Property</p>
            <div
              style={{ background: `linear-gradient(315deg, ${bgStart} 0%, ${bgEnd} 100%)` }}
              className=' flex flex-col items-center  py-5 px-5'
            >
              {image ? (
                <img src={image} className='object-cover' />
              ) : (
                <div className='h-56 flex justify-center items-center'>
                  <ImageIcon className=' w-8 h-8' />
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default DemoThemeModalPanel
