import { createAsyncThunk } from '@reduxjs/toolkit';
import TranslationApi from '../../services/translations.service';
import { module } from '../../types/module';
import { CreateTranslation, Translation, UpdateTranslation } from '../../types/translation';
import { errorHandler } from '../../utils/errorHandler.util';

export const fetchTranslationList = createAsyncThunk<Translation[]>(`${module.translations}/fetchTranslationList`,
    async (_, { rejectWithValue }) => {
        try {
            const result = await TranslationApi.getTranslations();
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)

export const createTranslation = createAsyncThunk<Translation, CreateTranslation>(
    `${module.translations}/createTranslation`,
    async (params, { rejectWithValue }) => {
        try {
            const result = await TranslationApi.createTranslation(params);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)

export const updateTranslation = createAsyncThunk<Translation, UpdateTranslation>(
    `${module.translations}/updateTranslation`,
    async (params, { rejectWithValue }) => {
        try {
            const { docId, ...data } = params;
            const result = await TranslationApi.updateTranslation(docId, data);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)


export const deleteTranslation = createAsyncThunk<string, string>(
    `${module.translations}/deleteTranslation`,
    async (id, { rejectWithValue }) => {
        try {
            await TranslationApi.deleteTranslation(id);
            return id;
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)

export const syncTranslationData = createAsyncThunk(
    `${module.translations}/syncTranslationData`,
    async (_, { rejectWithValue }) => {
        try {
            await TranslationApi.syncTranslation();
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)