import http from '../../configs/http.config';
import { CreateImage, CreateImageCategory, Image, ImageCategory } from '../types/image';

class AssetService {
    static readonly path: string = '/assets'

    static async getImages(): Promise<Image[]> {
        const { data } = await http.get(`${this.path}/images`);
        return data;
    }

    static async getImageCategories(): Promise<ImageCategory[]> {
        const { data } = await http.get(`${this.path}/image-categories`)
        return data
    }

    static async createImageCategory(params: CreateImageCategory): Promise<ImageCategory> {
        const { data } = await http.post(`${this.path}/image-categories`, params)
        return data;
    }

    static async updateImageCategory(id: string, params: CreateImageCategory): Promise<ImageCategory> {
        const { data } = await http.put(`${this.path}/image-categories/${id}`, params);
        return data
    }

    static async createImage(params: CreateImage): Promise<Image> {
        const formData = new FormData();
        formData.append('file', params.file);
        formData.append('name', params.name);
        formData.append('categoryId', params.categoryId);

        const { data } = await http.post(`${this.path}/images`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        return data;
    }

    static async deleteImage(id: string): Promise<void> {
        await http.delete(`${this.path}/images/${id}`);
    }
}

export default AssetService;