import { Image, ImageCategory } from '../../types/image';
import { StoreData } from '../../types/module';
import { initialStore } from '../../utils/store.util';


export interface IAssetsState {
    images: StoreData<Image[]>;
    imageCategories: StoreData<ImageCategory[]>;
}

export const initialState: IAssetsState = {
    images: initialStore<Image[]>([]),
    imageCategories: initialStore<ImageCategory[]>([])
}
