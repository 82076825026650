import { Menu, Transition } from '@headlessui/react'
import { Fragment, FunctionComponent, useEffect } from 'react'
import { ColorPicker, useColor, toColor } from 'react-color-palette'
import 'react-color-palette/lib/css/styles.css'

type Props = {
  value: string
  onSelected?: (v: string) => void
}

const DropdownColorPicker: FunctionComponent<Props> = ({ value, onSelected }) => {
  function handleOnSelected(val: string) {
    if (onSelected) onSelected(val)
  }

  const [color, setColor] = useColor('hex', value)

  useEffect(() => {
    setColor(toColor('hex', value || '#FFFFFF'))
  }, [value])

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <Menu.Button
        className='w-10 h-10 rounded-sm border border-gray-300 shadow-sm'
        style={{ backgroundColor: value }}
      ></Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0  mt-2 w-[295px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 '>
          <ColorPicker
            width={295}
            height={159}
            color={color}
            onChange={(v) => {
              setColor(v)
              handleOnSelected(v.hex)
            }}
            hideHSV
            dark
          />
          ;
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
export default DropdownColorPicker
