import { FunctionComponent, memo, useState } from 'react'
import { DataStatus } from '../../../modules/types/core'
import { Model } from '../../../modules/types/models'
import FlatButton from '../../atoms/Button/FlatButton'
import OutlinedButton from '../../atoms/Button/OutlinedButton'
import { ImageModel } from '../../atoms/Image/ImageModel'
import Switcher from '../../atoms/Switcher'

type Props = {
  model: Model
  onChangeStatus?: (val: DataStatus) => void
  onEdit?: () => void
  onSync?: () => void
}

const ProductCard: FunctionComponent<Props> = ({ model, onChangeStatus, onEdit, onSync }) => {
  const [enabled, setEnabled] = useState(model.status === DataStatus.active)

  function handleOnChangeStatus(status: boolean) {
    setEnabled(status)
    if (onChangeStatus) onChangeStatus(status ? DataStatus.active : DataStatus.inactive)
  }

  return (
    <div className=' bg-primary-bg-color shadow flex items-center space-x-3 border px-5 py-5'>
      <Switcher enabled={enabled} onChange={handleOnChangeStatus} />
      <ImageModel src={model.image} color={model.environment?.backgroundScene} />
      <div className='flex-1 flex flex-col'>
        <p className=' text-xl font-semibold'>{model.name}</p>
        <div className='flex flex-col space-y-1'>
          <p className='inline-flex space-x-2'>
            <span className='font-semibold'>Price (USD) :</span>
            <span>{model.price?.usd.toLocaleString()}</span>
          </p>
          <p className='inline-flex space-x-2'>
            <span className='font-semibold'>Price (EUR) :</span>
            <span>{model?.price?.euro.toLocaleString()}</span>
          </p>
          <p className='inline-flex space-x-2'>
            <span className='font-semibold'>Price (GBP) :</span>
            <span>{model?.price?.pound.toLocaleString()}</span>
          </p>
        </div>
      </div>
      <div className='flex flex-col space-y-5 items-end'>
        <FlatButton onClick={onSync}>Sync Model to Production Site</FlatButton>
        <OutlinedButton onClick={onEdit}>Edit</OutlinedButton>
      </div>
    </div>
  )
}

export default ProductCard
