import { BaseDocumentData } from './core';
import { ModelProperty } from './modelProperty';
import { Translation } from './translation';

export type Category = {
    _id: string;
    name: string;
    groupId: string;
    translationId: string;
    active: boolean;
    order: number;
    language?: Translation;
} & BaseDocumentData

export type CreateCategory = Pick<Category, 'name' | 'translationId'>;
export type UpdateCategory = Partial<CreateCategory> & Pick<Category, 'groupId'>;

export type UpdateCategoryOrder = {
    groupId: string;
    order: number
}


export type UpdateCategoryStatus = Pick<Category, 'groupId' | 'active'>;

export enum CustomizationPartTypeEnum {
    Main = 'main',
    AddOn = 'AddOn'
}

export enum ActionType {
    selector = 'selector',
    toggle = 'toggle',
    multipleSelector = 'multipleSelector'
}


export type FormGroup = {
    formId: string;
    name: string;
    actionType?: ActionType,
    translationId: string;
}

export enum DisplayLayout {
    RowSelectorLayout = 'RowSelectorLayout',
    GridSelectorLayout = 'GridSelectorLayout',
    GridSelectorLayoutSumCost = 'GridSelectorLayoutSumCost',
    DynamicSelectorLayout = 'DynamicSelectorLayout'
}

export enum FormType {
    Single = 'Single',
    MultipleRelation = 'MultipleRelation',
    MultipleNoneRelation = 'MultipleNoneRelation'
}

export type CustomizationPart = {
    type: CustomizationPartTypeEnum;
    modelId: string;
    partId: string;
    categoryId: string;
    formGroups?: FormGroup[];
    formType: FormType,
    actionType: ActionType,
    name: string;
    translationId: string;
    order: number;
    displayLayout: DisplayLayout;
    active: boolean;
    modelProperties?: ModelProperty[];
}

export type UpdateCustomizationPartOrder = {
    partId: string;
    order: number;
}

export type UpdateCustomizationPartStatus = Pick<CustomizationPart, 'partId' | 'active'>

export type CreateCustomizationPart = Omit<CustomizationPart, 'partId' | 'active' | 'order'>
export type UpdateCustomizationPart = Partial<CreateCustomizationPart> & Pick<CustomizationPart, 'partId'>

export type CreateFormGroup = { id: string, formGroup: FormGroup }
export type UpdateFormGroup = { id: string, formGroup: FormGroup }
export type DeleteFormGroup = { id: string, formId: string };



