import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './modules/store'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <App />,
//     children: [
//       {
//         path: '/menu-settings',
//         element: <MenuSettings />,
//       },
//       {
//         path: '/product-settings',
//         element: <ProductSettings />,
//       },
//       {
//         path: '/translation-settings',
//         element: <TranslationSettings />,
//       },
//       {
//         path: '/theme-settings',
//         element: <ThemeSettings />,
//       },
//     ],
//   },
//   {
//     path: '/product-settings-information',
//     element: <ProductSettingInformation />,
//   },
//   {
//     path: '/sign-in',
//     element: <SignInPage />,
//   },
//   {
//     path: '/product-settings-information/:modelId',
//     element: <ProductSettingInformation />,
//   },
// ])

root.render(
  <Provider store={store}>
    {/* <RouterProvider router={router} /> */}
    <App />
    <ToastContainer />
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
