import { FunctionComponent, memo, useMemo, useState } from 'react'
import { configurationTypes } from '../../../../modules/types/constant'
import { Configuration, ConfigurationItem } from '../../../../modules/types/modelProperty'
import FlatButton from '../../../atoms/Button/FlatButton'
import DropdownIconButton from '../../../atoms/DropdownIconButton'
import FormField from '../../../atoms/FormField'
import EllipsisVertical from '../../../atoms/Icons/EllipsisVertical'
import Input from '../../../atoms/Input'
import Selector from '../../../atoms/Selector'
import { getPolygonType, getValueFromProperty } from './transform'

type Props = {
  title: string
  properties: ConfigurationItem[]
  onAdd: () => void
  onUpdate: (val: ConfigurationItem) => void
  onDelete: (index: number) => void
}

const menus = [
  {
    key: 'on-edit',
    label: 'Edit',
  },
  {
    key: 'on-delete',
    label: 'Delete',
  },
]

const PropertyManagement: FunctionComponent<Props> = ({
  title,
  properties,
  onAdd,
  onUpdate,
  onDelete,
}) => {
  const [name, setName] = useState<string>('')
  const [configType, setConfigType] = useState<string>('')

  function handleOnSelected(key: string, value: ConfigurationItem, index: number) {
    if (key === 'on-edit' && onUpdate) onUpdate(value)
    else if (key === 'on-delete' && onDelete) onDelete(index)
  }

  const filterablePipeline = useMemo(() => {
    let clones = Array.from(properties || [])
    if (!clones.length) return []

    if (name) {
      clones = clones.filter((ele) =>
        ele.materialName.toLocaleLowerCase().includes(name.toLocaleLowerCase()),
      )
    }

    if (configType) {
      clones = clones.filter((ele) => ele.type === configType)
    }

    return clones
  }, [properties, name, configType])

  return (
    <>
      <div className='flex flex-col w-ful space-y-5 items-stretch h-auto pb-20'>
        <div className='flex items-center justify-between'>
          <p className=' font-bold text-xl'>{title}</p>
          <FlatButton onClick={onAdd}>Add</FlatButton>
        </div>
        <div className='flex items-center space-x-5'>
          <div className='flex-1'>
            <FormField label='Name'>
              <Input placeholder='Enter the name' onChange={(e) => setName(e.target.value)} />
            </FormField>
          </div>
          <div className='flex-1'>
            <FormField label='Category'>
              <Selector
                options={configurationTypes}
                onChange={(e) => setConfigType(e.target.value)}
              />
            </FormField>
          </div>
        </div>
        <div className='flex-1 w-full'>
          <table className='table-auto w-full'>
            <thead className='bg-gray-100 '>
              <tr>
                <th scope='col' className='px-6 py-3 text-left'>
                  Polygon Type
                </th>
                <th scope='col' className='px-6 py-3 text-left'>
                  Name
                </th>
                <th scope='col' className='px-6 py-3 text-left'>
                  Config Type
                </th>
                <th scope='col' className='px-6 py-3 text-left'>
                  Value
                </th>
                <th scope='col' className='px-6 py-3 text-left'></th>
              </tr>
            </thead>
            <tbody>
              {filterablePipeline.map((ele, index) => (
                <tr key={index} className='border-b'>
                  <td className='px-6 py-4'>{getPolygonType(ele.type)}</td>
                  <td className='px-6 py-4'>{ele.materialName}</td>
                  <td className='px-6 py-4'>{ele.type}</td>
                  <td className='px-6 py-4'>{getValueFromProperty(ele)}</td>
                  <td>
                    <DropdownIconButton
                      icon={<EllipsisVertical />}
                      menus={menus}
                      onSelected={(key) => handleOnSelected(key, ele, ele.index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
export default memo(PropertyManagement)
