import { FunctionComponent } from 'react'
import BoxColor from '../../atoms/BoxColor'

type Props = {
  name: string
  color?: string
}

const BoxColorIndicator: FunctionComponent<Props> = ({ name, color }) => {
  return (
    <div className='flex flex-col items-center'>
      <BoxColor color={color || ''} />
      <p className='text-center'>{name}</p>
    </div>
  )
}

export default BoxColorIndicator
