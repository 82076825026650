import { FunctionComponent } from 'react'
import PropsWithChildren from '../../types'

type Props = {
  className?: string
}

const Card: FunctionComponent<PropsWithChildren<Props>> = ({ children, className }) => {
  return (
    <div className={`bg-primary-bg-color shadow-sm border rounded ${className}`}>{children}</div>
  )
}

export default Card
