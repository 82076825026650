import { FunctionComponent, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import FlatButton from '../../components/atoms/Button/FlatButton'

import LoadingDialog from '../../components/molecules/LoadingDialog'
import useToastMessage from '../../hooks/useToastMessage.hook'
import AuthenticationService from '../../modules/services/authentication.service'
import { SignInForm } from '../../modules/types/authentication'
import { setSessionCredentialAuthentication } from '../../modules/utils/authentication.util'

const SignInPage: FunctionComponent = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SignInForm>()

  const navigate = useNavigate()
  const [isOpenLoadingDialog, setIsOpenLoadingDialog] = useState(false)

  const { showSuccess, showError } = useToastMessage()

  const onSubmitForm = useCallback(async (data: SignInForm) => {
    try {
      setIsOpenLoadingDialog(true)
      const result = await AuthenticationService.signIn(data)
      setSessionCredentialAuthentication(result)
      showSuccess('Sign in successfully')
      navigate('/menu-settings')
    } catch (error) {
      showError('Account is invalid.')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  return (
    <>
      <div className=' h-screen bg-[#161616] flex text-white'>
        <div className='flex-1 h-full flex flex-col items-center justify-center bg-slate-600'>
          <p className=' text-3xl font-bold'>Model Configuration</p>
          <img
            src='https://d2bpet1ynglyot.cloudfront.net/webp/Model/model_i300_carbon_launch_edition.webp'
            className=' object-cover'
          />
        </div>
        <div className='flex-1 h-full flex  lex-col items-center justify-center'>
          <div className='w-[380px] pb-20'>
            <form
              onSubmit={handleSubmit(onSubmitForm)}
              className='flex flex-col items-stretch justify-center space-y-5'
            >
              {/* <img src='/images/logo.png' alt='logo' className=' self-center object-contain py-5' /> */}
              <div className='flex flex-col py-5'>
                <img
                  src='/images/logo.png'
                  alt='logo'
                  className=' self-center object-contain py-5 w-[150px]'
                />
                <p className='text-3xl self-center'>Login to your account</p>
              </div>

              <div className='flex flex-col items-stretch space-y-2'>
                <input
                  placeholder='Email'
                  {...register('email', {
                    required: { value: true, message: 'Email is required' },
                    pattern: {
                      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: 'Email is invalid.',
                    },
                  })}
                  className='px-4 w-full py-4 rounded-md text-[15px] appearance-none focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-primary leading-tight bg-black'
                />
                <p className=' text-primary-light pl-1'>{errors.email?.message}</p>
              </div>

              <div className='flex flex-col items-stretch space-y-2'>
                <input
                  placeholder='Password'
                  {...register('password', {
                    required: { value: true, message: 'Password is required' },
                  })}
                  type='password'
                  className='w-full px-4 py-4 rounded-md text-[15px] appearance-none focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-primary leading-tight bg-black'
                />
                <p className='pl-1 text-primary-light'>{errors.password?.message}</p>
              </div>
              <div className='pt-4 w-full'>
                <FlatButton type='submit' className=' w-full h-[48px]'>
                  CONFIRM
                </FlatButton>
              </div>
            </form>
          </div>
        </div>
      </div>
      <LoadingDialog isOpen={isOpenLoadingDialog} onClose={() => setIsOpenLoadingDialog(false)} />
    </>
  )
}

export default SignInPage
