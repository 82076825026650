import { forwardRef, SelectHTMLAttributes } from 'react'

// type Props = {
//   options: Array<{ label: string; value: string }>
// }

type Ref = HTMLSelectElement
type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  options: Array<{ label: string; value: string }>
}
// eslint-disable-next-line react/display-name
const Selector = forwardRef<Ref, Props>((props, ref) => {
  const { className, options, ...selectorProps } = props
  return (
    <select
      ref={ref}
      {...selectorProps}
      className={`bg-gray-50 border border-gray-300 text-gray-900 px-3 py-3 text-sm rounded focus:ring-blue-500 focus:border-blue-500   ${className}`}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  )
})

export default Selector
