/* eslint-disable @typescript-eslint/no-empty-function */
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../modules/hook'
import FlatButton from '../../components/atoms/Button/FlatButton'
import DndProductsList from '../../components/organisms/DndProductsList'

import HeaderPage from '../../components/organisms/HeaderPage'
import RootPage from '../../components/templates/RootPage'

import {
  fetchModelsList,
  syncModels,
  updateModelStatus,
  updateOrderModels,
} from '../../modules/stores/models/actions'
import { StateData } from '../../modules/types/module'
import StateTemplate from '../../components/templates/StateTemplate'
import { useNavigate } from 'react-router-dom'
import { Model } from '../../modules/types/models'
import LoadingDialog from '../../components/molecules/LoadingDialog'
import { DataStatus } from '../../modules/types/core'
import useToastMessage from '../../hooks/useToastMessage.hook'
import GuardAuthenticationTemplate from '../../components/templates/GuardAuthentication'
import TextCaption from '../../components/atoms/TextCaption'
import DndTitleCaption from '../../components/molecules/DndTitleCaption'

const ProductSettings: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const navigator = useNavigate()
  const models = useAppSelector((state) => state.model.models)
  const [isOpenLoadingDialog, setIsOpenLoadingDialog] = useState<boolean>(false)

  const { showSuccess, showError } = useToastMessage()

  const handleOnEdit = useCallback((id: string) => {
    console.log('id: ', id)
    navigator(`../product-settings-information/${id}`)
  }, [])

  const handleOnSync = useCallback(async (id: string) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(syncModels(id)).unwrap()
      showSuccess('Sync Models Successfully')
    } catch (error) {
      showError('Sync Models Failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const handleOnChangeOrder = useCallback(async (items: Model[]) => {
    try {
      setIsOpenLoadingDialog(true)
      const payload = items.map(({ modelId, order }) => ({ modelId, order }))
      await dispatch(updateOrderModels(payload)).unwrap()
      showSuccess('Update Order Successfully')
    } catch (error) {
      showError('Update Order Failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const handleOnChangeStatus = useCallback(async (id: string, status: DataStatus) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(updateModelStatus({ modelId: id, status })).unwrap()
      showSuccess('Update Status Successfully')
    } catch (error) {
      showError('Update Status Failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  useEffect(() => {
    if (models.state === StateData.Idle) dispatch(fetchModelsList())
  }, [])

  return (
    <GuardAuthenticationTemplate>
      <RootPage>
        <HeaderPage title='Product Settings' subtitles={['Settings', 'Product Settings']} />
        <div className='flex items-center justify-between'>
          <DndTitleCaption />
          <FlatButton onClick={() => navigator('../product-settings-information')}>Add</FlatButton>
        </div>
        <StateTemplate state={models.state}>
          <div className='flex-1 overflow-y-auto pb-56'>
            <DndProductsList
              models={models.data}
              onEdit={handleOnEdit}
              onSync={handleOnSync}
              onChangeOrder={handleOnChangeOrder}
              onChangeStatus={handleOnChangeStatus}
            />
          </div>
        </StateTemplate>
      </RootPage>
      <LoadingDialog isOpen={isOpenLoadingDialog} onClose={() => setIsOpenLoadingDialog(false)} />
    </GuardAuthenticationTemplate>
  )
}

export default ProductSettings
