import { FunctionComponent, memo, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { Category, FormGroup } from '../../../modules/types/customizationPart'

import CategoryCard from '../../molecules/CategoryCard'

type Props = {
  items: Category[]
  onChangeOrder?: (items: Category[]) => void
  onSelectPart?: (val: Category) => void
  onEdit?: (val: Category) => void
  onDelete?: (id: string) => void
  onChangeStatus?: (id: string, status: boolean) => void
}
const reorder = (list: Category[], startIndex: number, endIndex: number): Category[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const DndCategoryList: FunctionComponent<Props> = ({
  items,
  onChangeOrder,
  onEdit,
  onDelete,
  onChangeStatus,
}) => {
  const [data, setData] = useState<Category[]>([])
  function onDragEnd({ destination, source }: DropResult) {
    if (!destination) {
      return
    }

    if (destination.index === source.index) {
      return
    }

    const newOrderingList = reorder(items, source.index, destination.index)
    const updateOrder = newOrderingList.map((ele, index) => ({ ...ele, order: index + 1 }))

    if (onChangeOrder) onChangeOrder(updateOrder)
    setData(updateOrder)
  }

  useEffect(() => {
    setData(items)
  }, [items])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {data.map((item, index) => (
              <Draggable key={index} draggableId={index.toString()} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className='py-1'
                  >
                    <CategoryCard
                      category={item}
                      onEdit={onEdit}
                      onChangeStatus={onChangeStatus}
                      onDelete={onDelete}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default memo(DndCategoryList)
