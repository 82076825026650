import { CredentialAuthentication } from '../types/authentication';
import { Nullable } from '../types/core';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

export const setSessionCredentialAuthentication = (params: CredentialAuthentication): void => {
    sessionStorage.setItem(ACCESS_TOKEN_KEY, params.accessToken);
    sessionStorage.setItem(REFRESH_TOKEN_KEY, params.refreshToken);
}

export const getAccessToken = (): Nullable<string> => {
    return sessionStorage.getItem(ACCESS_TOKEN_KEY);
}

export const getRefreshToken = (): Nullable<string> => {
    return sessionStorage.getItem(REFRESH_TOKEN_KEY);
}


export const resetCredentialAuthentication = (): void => {
    sessionStorage.removeItem(ACCESS_TOKEN_KEY);
    sessionStorage.removeItem(REFRESH_TOKEN_KEY);
}