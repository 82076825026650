import request from 'axios'

export const errorHandler = (error: unknown): unknown => {
    if (request.isAxiosError(error)) return error.response?.data
    return error
}


export const getErrorInputMessage = (errorType: string, field: string) => {
    if (errorType === 'required') {
        return `${field} is required`;
    }
    if (errorType === 'pattern') {
        return `${field} is invalid format.`;
    }

    return '';
}
