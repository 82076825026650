import { createAsyncThunk } from '@reduxjs/toolkit';
import ModelApi from '../../services/model.service';
import { CreateModelData, Model, UpdateModelData, UpdateModelOrder, UpdateModelStatus } from '../../types/models';
import { module } from '../../types/module';
import { errorHandler } from '../../utils/errorHandler.util';

export const fetchModelsList = createAsyncThunk<Model[]>(
    `${module.models}/fetchModelsList`,
    async (_, { rejectWithValue }) => {
        try {
            const result = await ModelApi.getModels();
            return result;
        } catch (error) {
            return rejectWithValue(error)
        }

    }
)

export const fetchCurrentModel = createAsyncThunk<Model, string>(`${module.models}/fetchCurrentModel`,
    async (modelId, { rejectWithValue }) => {
        try {
            const result = await ModelApi.getCurrentModelByModelId(modelId);
            return result;
        } catch (error) {
            return rejectWithValue(error)
        }

    },
)

export const createModelData = createAsyncThunk<void, CreateModelData>(
    `${module.models}/createModelData`,
    async (params: CreateModelData, { rejectWithValue }) => {
        try {
            await ModelApi.createModel(params);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateOrderModels = createAsyncThunk<UpdateModelOrder[], UpdateModelOrder[]>(
    `${module.models}/updateOrderModels`,
    async (params: UpdateModelOrder[], { rejectWithValue }) => {
        try {
            await ModelApi.updateModelOrder(params);
            return params;
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)

export const updateModelStatus = createAsyncThunk<UpdateModelStatus, UpdateModelStatus>(
    `${module.models}/updateModelStatus`,
    async (params: UpdateModelStatus, { rejectWithValue }) => {
        try {
            const { modelId, status } = params
            await ModelApi.updateModelStatus(modelId, { status });
            return params;
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)

export const updateModelData = createAsyncThunk<Model, UpdateModelData>(
    `${module.models}/updateModelData`,
    async (params: UpdateModelData, { rejectWithValue }) => {
        try {
            const { modelId, ...form } = params;
            const result = await ModelApi.updateModelData(modelId, form);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)


export const deleteModel = createAsyncThunk<string, string>(
    `${module.models}/deleteModel`,
    async (params: string, { rejectWithValue }) => {
        try {
            await ModelApi.deleteModel(params);
            return params;
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)

export const syncModels = createAsyncThunk<void, string>(
    `${module.models}/syncModels`,
    async (id: string, { rejectWithValue }) => {
        try {
            await ModelApi.syncModels(id);
        } catch (error) {
            return rejectWithValue(errorHandler(error));
        }
    }
)