import { FunctionComponent } from 'react'
import BoxColor from '../../atoms/BoxColor'

type Props = {
  colors: string[]
  name: string
}

const DualBoxColorIndicator: FunctionComponent<Props> = ({ colors, name }) => {
  return (
    <div className='flex flex-col items-center'>
      <div className='flex space-x-2'>
        <BoxColor color={colors[0]} />
        <BoxColor color={colors[1]} />
      </div>
      <p className='text-center'>{name}</p>
    </div>
  )
}
export default DualBoxColorIndicator
