import http from '../../configs/http.config';
import {
    Category,
    CreateCategory,
    CreateCustomizationPart,
    CustomizationPart,
    FormGroup,
    UpdateCategory,
    UpdateCategoryOrder,
    UpdateCustomizationPart,
    UpdateCustomizationPartOrder
} from '../types/customizationPart';

class CustomizationPartAPI {
    static readonly path: string = '/customization-parts'
    static readonly pathCategory: string = '/customization-parts/categories'

    static async getCategory(): Promise<Category[]> {
        const { data } = await http.get(`${this.path}/all/categories`);
        return data
    }

    static async createCategory(params: CreateCategory): Promise<Category> {
        const { data } = await http.post(this.pathCategory, params);
        return data;
    }

    static async updateCategory(id: string, params: Omit<UpdateCategory, 'groupId'>): Promise<Category> {
        const { data } = await http.put(`${this.pathCategory}/${id}`, params);
        return data;
    }

    static async updateCategoryStatus(id: string, params: Pick<Category, 'active'>): Promise<Category> {
        const { data } = await http.patch(`${this.pathCategory}/${id}/status`, params);
        return data;
    }

    static async updateCategoryOrders(params: UpdateCategoryOrder[]): Promise<void> {
        await http.patch(`${this.pathCategory}/order`, params);
    }

    static async deleteCategory(groupId: string): Promise<void> {
        await http.delete(`${this.pathCategory}/${groupId}`);
    }

    static async getCustomizationPartByModelId(modelId: string): Promise<CustomizationPart[]> {
        const { data } = await http.get(`${this.path}/customization`, { params: { modelId } });
        return data;
    }

    static async getCustomizationPart(id: string): Promise<CustomizationPart> {
        const { data } = await http.get(`${this.path}/customization/${id}/property`);
        return data
    }

    static async createCustomizationPart(params: CreateCustomizationPart): Promise<CustomizationPart> {
        const { data } = await http.post(`${this.path}`, params);
        return data;
    }

    static async updateCustomizationOrder(params: UpdateCustomizationPartOrder[]): Promise<void> {
        await http.patch(`${this.path}/orders`, params);
    }

    static async updateCustomizationStatus(id: string, status: boolean): Promise<void> {
        await http.patch(`${this.path}/customization/${id}/status`, { status });
    }

    static async updateCustomizationPart(id: string, params: Omit<UpdateCustomizationPart, 'partId'>): Promise<CustomizationPart> {
        const { data } = await http.put(`${this.path}/${id}`, params);
        return data;
    }

    static async deleteCustomizationPart(id: string): Promise<void> {
        await http.delete(`${this.path}/${id}`);
    }

    static async createCustomizationFormGroup(id: string, params: FormGroup): Promise<CustomizationPart> {
        const { data } = await http.post(`${this.path}/customization/${id}/formGroups`, params);
        return data;
    }

    static async updateCustomizationFormGroup(id: string, params: FormGroup): Promise<CustomizationPart> {
        const { data } = await http.put(`${this.path}/customization/${id}/formGroups`, params);
        return data;
    }

    static async deleteCustomizationFormGroup(id: string, formId: string): Promise<CustomizationPart> {
        const { data } = await http.delete(`${this.path}/customization/${id}/formGroups/${formId}`)
        return data;
    }

    static async syncCategories(): Promise<void> {
        await http.post(`${this.pathCategory}/synchronize/prod`);
    }
}

export default CustomizationPartAPI;