import { forwardRef, InputHTMLAttributes } from 'react'

type Ref = HTMLInputElement
type Props = InputHTMLAttributes<HTMLInputElement>

// eslint-disable-next-line react/display-name
const Input = forwardRef<Ref, Props>((props, ref) => {
  const { className, ...inputProps } = props
  return (
    <input
      ref={ref}
      {...inputProps}
      className={`border border-color-border px-3 py-3 rounded text-[15px] appearance-none focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-blue-300 leading-tight bg-primary-bg-color ${className}`}
    />
  )
})

export default Input
