import { FunctionComponent, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { resetCredentialAuthentication } from '../../../modules/utils/authentication.util'
import LinkMenu from '../../atoms/Menu/LinkMenu'
import GroupMenuLinks from '../../molecules/GroupMenuLink'

const SideBar: FunctionComponent = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const navigators = [
    {
      name: 'Menu Settings',
      link: './menu-settings',
    },
    {
      name: 'Product Settings',
      link: './product-settings',
    },
    {
      name: 'Translation Settings',
      link: './translation-settings',
    },
  ]

  const signOut = useCallback(() => {
    resetCredentialAuthentication()
    navigate('../sign-in')
  }, [])

  return (
    <div className='flex flex-col bg-dark-bg-color text-white w-[330px] h-screen px-5'>
      <img
        src='/images/logo.png'
        alt='logo'
        className=' self-center object-contain w-[191px] py-5'
      />
      <ul className='flex flex-col items-stretch '>
        <GroupMenuLinks currentPath={location.pathname} title='Settings' navigators={navigators} />
        <div className='border-b' />

        <LinkMenu>
          <button className='outline-none text-left' onClick={signOut}>
            Sign out
          </button>
        </LinkMenu>
      </ul>
    </div>
  )
}

export default SideBar
