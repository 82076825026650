
export const module = {
    models: 'model',
    customizationParts: 'customizationParts',
    translations: 'translations',
    assets: 'assets',
    property: 'property'
} as const

export enum StateData {
    Idle = 'idle',
    Loading = 'loading',
    Success = 'success',
    Failed = 'failed'
}

export enum StateAction {
    Idle = 'idle',
    OnCreating = 'onCreating',
    OnUpdating = 'onUpdating',
    OnSuccess = 'onSuccess',
    OnFailed = 'onFailed'
}

export interface StoreData<T> {
    state: StateData;
    data: T;
    action: StateAction;
    error: unknown
}

