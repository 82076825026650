import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'

import HeaderPage from '../../components/organisms/HeaderPage'
import RootPage from '../../components/templates/RootPage'
import SearchAndAddMenu from '../../components/molecules/SearchAndAddMenu'
import FlatButton from '../../components/atoms/Button/FlatButton'

import { useAppDispatch, useAppSelector } from '../../modules/hook'
import {
  createCategory,
  deleteCategory,
  fetchCategoriesList,
  syncCategoriesData,
  updateCategory,
  updateCategoryOrders,
  updateCategoryStatus,
} from '../../modules/stores/customizationParts/action'
import { StateData } from '../../modules/types/module'
import StateTemplate from '../../components/templates/StateTemplate'

import DndCategoryList from '../../components/organisms/DndCategoryList'
import { Category } from '../../modules/types/customizationPart'
import { FormInputModal, FormMode } from '../../components/types'
import DrawerCategoryForm, {
  InputCategoryForm,
} from '../../components/templates/DrawerCategoryForm'
import LoadingDialog from '../../components/molecules/LoadingDialog'
import useToastMessage from '../../hooks/useToastMessage.hook'
import useConfirmDelete from '../../hooks/useConfirmDelete.hook'
import ConfirmDeleteModal from '../../components/molecules/ConfirmDeleteModal'
import GuardAuthenticationTemplate from '../../components/templates/GuardAuthentication'
import TextCaption from '../../components/atoms/TextCaption'
import DndTitleCaption from '../../components/molecules/DndTitleCaption'

const MenuSettings: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { showSuccess, showError } = useToastMessage()

  const [text, setText] = useState('')
  const [isOpenLoadingDialog, setIsOpenLoadingDialog] = useState<boolean>(false)

  const { openConfirmDelete, onOpenDeleteModal, onCloseDeleteModal } = useConfirmDelete()

  const { state, data } = useAppSelector((state) => state.customizationParts.categories)
  const [openCategoryFormModal, setOpenCategoryFormModal] = useState<FormInputModal<Category>>({
    status: false,
    mode: FormMode.Idle,
  })

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnClickAddOpenModal = useCallback(() => {
    setOpenCategoryFormModal({ status: true, mode: FormMode.Add })
  }, [])

  const handleOnClickUpdateOpenModal = useCallback((val: Category) => {
    setOpenCategoryFormModal({ status: true, mode: FormMode.Update, value: val })
  }, [])

  const handleOnDeleteCategory = useCallback(async (id: string) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(deleteCategory(id)).unwrap()
      showSuccess('Delete Success')
    } catch (error) {
      console.log(error)
      showError('Delete Failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const handleOnChange = useCallback((val: string) => {
    setText(val)
  }, [])

  const handleOnChangeOrder = useCallback(async (items: Category[]) => {
    try {
      setIsOpenLoadingDialog(true)
      const payload = items.map(({ groupId, order }) => ({ groupId, order }))
      await dispatch(updateCategoryOrders(payload)).unwrap()
      showSuccess('Update category order successfully')
    } catch (error) {
      console.error(error)
      showError('update category order failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const handleOnAddCategory = useCallback(async (val: InputCategoryForm) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(createCategory(val)).unwrap()
      showSuccess('Create category successfully')
    } catch (error) {
      console.error(error)
      showError('Create category failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const handleOnUpdateCategory = useCallback(async (id: string, val: InputCategoryForm) => {
    try {
      setIsOpenLoadingDialog(true)
      console.log('update cate')
      await dispatch(updateCategory({ groupId: id, ...val })).unwrap()
      showSuccess('Update category successfully')
    } catch (error) {
      console.error(error)
      showError('Update category failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const handleOnChangeStatus = useCallback(async (id: string, status: boolean) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(updateCategoryStatus({ groupId: id, active: status })).unwrap()
      showSuccess('Update category status successfully')
    } catch (error) {
      console.error(error)
      showError('Update category status failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const handleOnCloseCategoryFormModal = useCallback(() => {
    setOpenCategoryFormModal((prev) => ({ ...prev, status: false }))
  }, [])

  const handleOnSyncData = useCallback(async () => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(syncCategoriesData()).unwrap()
      showSuccess('Sync Menus successfully')
    } catch (error) {
      showError('Sync Menus failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const categories = useMemo(() => {
    if (state !== StateData.Success) return []
    console.log(data)
    return text
      ? data.filter((d) => d.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()))
      : data
  }, [state, text, data])

  useEffect(() => {
    if (state === StateData.Idle) dispatch(fetchCategoriesList())
  }, [])

  return (
    <GuardAuthenticationTemplate>
      <RootPage className=' overflow-y-hidden'>
        <Fragment>
          <div className='flex'>
            <div className='flex-1'>
              <HeaderPage title='Menu Settings' subtitles={['Setting', 'Menu Settings']} />
            </div>
            <FlatButton onClick={handleOnSyncData}>Sync Menu to Production Site</FlatButton>
          </div>
          <div className='flex-1 flex flex-col  h-full overflow-y-hidden space-y-5'>
            <SearchAndAddMenu
              placeholder='Search'
              onChange={handleOnChange}
              onAdd={handleOnClickAddOpenModal}
            />
            <DndTitleCaption />
            <StateTemplate state={state}>
              <div className='flex-1 overflow-y-auto pb-36'>
                <DndCategoryList
                  items={categories}
                  onChangeStatus={handleOnChangeStatus}
                  onChangeOrder={handleOnChangeOrder}
                  onEdit={handleOnClickUpdateOpenModal}
                  onDelete={onOpenDeleteModal}
                />
              </div>
            </StateTemplate>
          </div>
        </Fragment>
      </RootPage>
      <DrawerCategoryForm
        isOpen={openCategoryFormModal.status}
        mode={openCategoryFormModal.mode}
        data={openCategoryFormModal.value}
        onClose={handleOnCloseCategoryFormModal}
        onSubmitAddItem={handleOnAddCategory}
        onSubmitUpdateItem={handleOnUpdateCategory}
      />
      <ConfirmDeleteModal
        isOpen={openConfirmDelete.status}
        value={openConfirmDelete?.value}
        onClose={onCloseDeleteModal}
        onConfirm={handleOnDeleteCategory}
      />
      <LoadingDialog isOpen={isOpenLoadingDialog} onClose={() => setIsOpenLoadingDialog(false)} />
    </GuardAuthenticationTemplate>
  )
}

export default MenuSettings
