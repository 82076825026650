import { createSlice } from '@reduxjs/toolkit';
import { module } from '../../types/module';
import extraReducer from './extraReducer';
import { IModelState, initialState } from './state';


export const modelsSlice = createSlice({
    name: module.models,
    initialState,
    reducers: {
        resetCurrentModel(state: IModelState) {
            state.currentModel = initialState.currentModel
        },
    },
    extraReducers: extraReducer
})

export const { resetCurrentModel } = modelsSlice.actions;

export default modelsSlice.reducer