import { Fragment, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import Drawer from 'react-modern-drawer'
import useToastMessage from '../../../hooks/useToastMessage.hook'
import { useAppDispatch } from '../../../modules/hook'
import {
  createCustomizationPartFormGroup,
  updateCustomizationPartFormGroup,
} from '../../../modules/stores/customizationParts/action'
import {
  CreateFormGroup,
  FormGroup,
  UpdateFormGroup,
} from '../../../modules/types/customizationPart'
import { Translation } from '../../../modules/types/translation'
import OutlinedButton from '../../atoms/Button/OutlinedButton'
import Card from '../../atoms/Card'
import FormField from '../../atoms/FormField'
import Input from '../../atoms/Input'
import LoadingDialog from '../../molecules/LoadingDialog'
import { FormMode } from '../../types'
import DrawerTranslation from '../DrawerTranslation'
import RootMainTemplate from '../RootMainTemplate'

type Props = {
  isOpen: boolean
  initial?: FormGroup
  mode: FormMode
  customizationPartId: string
  onClose: () => void
  onSubmit?: (mode: FormMode, val: FormGroup) => void
}

type InputType = {
  name: string
  translationId: string
}

const DrawerSubGroupForm: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  initial,
  customizationPartId,
  mode,
}) => {
  const { register, setValue, handleSubmit, watch, reset } = useForm<InputType>()
  const buttonRef = useRef<HTMLButtonElement>(null)

  const translationId = watch('translationId')
  const dispatch = useAppDispatch()

  const [isOpenLoading, setIsOpenLoading] = useState<boolean>(false)
  const [isOpenTranslationDrawer, setIsOpenTranslationDrawer] = useState<boolean>(false)

  const { showSuccess, showError } = useToastMessage()

  const handleOpenTranslationDrawer = useCallback(() => {
    setIsOpenTranslationDrawer(true)
  }, [])

  const handleCloseTranslationDrawer = useCallback(() => {
    setIsOpenTranslationDrawer(false)
  }, [])

  const handleOnSubmitTranslation = useCallback((value: Translation) => {
    setValue('name', value.name)
    setValue('translationId', value.docId)
  }, [])

  const handleOnSubmitForm = useCallback(
    (values: InputType) => {
      if (mode === FormMode.Add) {
        const formGroupId = values.name.toLocaleLowerCase().replace(' ', '_')
        onCreateSubGroup({ id: customizationPartId, formGroup: { ...values, formId: formGroupId } })
      } else {
        onUpdateSubGroup({
          id: customizationPartId,
          formGroup: { ...values, formId: String(initial?.formId) },
        })
      }
    },
    [mode, initial, customizationPartId],
  )

  const onCreateSubGroup = useCallback(async (data: CreateFormGroup) => {
    try {
      setIsOpenLoading(true)
      await dispatch(createCustomizationPartFormGroup(data)).unwrap()
      onCloseDrawer()
      showSuccess('Create form group successfully')
    } catch (error) {
      showError('Create form group failed')
    } finally {
      setIsOpenLoading(false)
    }
  }, [])

  const onUpdateSubGroup = useCallback(async (data: UpdateFormGroup) => {
    try {
      setIsOpenLoading(true)
      await dispatch(updateCustomizationPartFormGroup(data)).unwrap()
      onCloseDrawer()
      showSuccess('Update from group successfully')
    } catch (error) {
      showError('Update form group failed')
    } finally {
      setIsOpenLoading(false)
    }
  }, [])

  const handleOnClickSave = useCallback(() => {
    buttonRef.current?.click()
  }, [])

  const onCloseDrawer = useCallback(() => {
    reset()
    onClose()
  }, [])

  useEffect(() => {
    if (!open || mode !== FormMode.Update || !initial) return
    setValue('name', initial.name)
    setValue('translationId', initial.translationId)
  }, [open, mode, initial])

  return (
    <Fragment>
      <Drawer size='80vw' open={isOpen} onClose={onCloseDrawer} direction='right' zIndex={0}>
        <RootMainTemplate title='Translations' onClose={onCloseDrawer} onSave={handleOnClickSave}>
          <div className='container mx-auto max-w-3xl py-5'>
            <Card className='py-5 px-5'>
              <form onSubmit={handleSubmit(handleOnSubmitForm)} className='w-full'>
                <div className='flex flex-col h-full py-5 px-5 bg-white items-stretch space-y-5'>
                  <FormField label='Sub Group Name'>
                    <div className='flex space-x-5'>
                      <div className='flex-1'>
                        <Input {...register('name')} className='w-full' />
                      </div>
                      <OutlinedButton type='button' onClick={handleOpenTranslationDrawer}>
                        Translation
                      </OutlinedButton>
                    </div>
                  </FormField>
                </div>
                <input className='hidden' {...register('translationId')} />
                <button type='submit' className='hidden' ref={buttonRef}></button>
              </form>
            </Card>
          </div>
        </RootMainTemplate>
      </Drawer>
      <DrawerTranslation
        isOpen={isOpenTranslationDrawer}
        translationId={translationId}
        onClose={handleCloseTranslationDrawer}
        onSubmit={handleOnSubmitTranslation}
      />
      <LoadingDialog isOpen={isOpenLoading} onClose={() => setIsOpenLoading(false)} />
    </Fragment>
  )
}

export default DrawerSubGroupForm
