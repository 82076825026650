import { Switch } from '@headlessui/react'
import { FunctionComponent } from 'react'

type Props = {
  enabled: boolean
  onChange: (v: boolean) => void
}

const Switcher: FunctionComponent<Props> = ({ enabled = false, onChange }) => {
  return (
    <Switch
      checked={enabled}
      onChange={onChange}
      className={`${enabled ? 'bg-teal-700' : ' bg-gray-600'}
          relative inline-flex h-[28px] w-[54px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className='sr-only'>Use setting</span>
      <span
        aria-hidden='true'
        className={`${enabled ? ' translate-x-[24px]' : 'translate-x-0'}
            pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  )
}

export default Switcher
