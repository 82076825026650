import { FunctionComponent, memo } from 'react'
import { getButtonClassUtilComponent } from '../../../../utils/button.util'

import PropsWithChildren, { ButtonProps, ButtonType, ColorBase } from '../../../types'
import BaseButton from '../BaseButton'

const OutlinedButton: FunctionComponent<PropsWithChildren<ButtonProps>> = ({
  type = 'button',
  color = ColorBase.primary,
  children,
  onClick,
}) => {
  const className = getButtonClassUtilComponent(ButtonType.Outlined, color)
  return (
    <BaseButton type={type} onClick={onClick} className={className}>
      {children}
    </BaseButton>
  )
}

export default memo(OutlinedButton)
