import { FunctionComponent, useEffect, useState } from 'react'
import { getAccessToken, getRefreshToken } from '../../../modules/utils/authentication.util'
import LoadingContent from '../../molecules/LoadingContent'
import PropsWithChildren from '../../types'

const GuardAuthenticationTemplate: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const accessToken = getAccessToken()
    const refreshToken = getRefreshToken()
    if (!accessToken || !refreshToken) {
      window.location.href = '/sign-in'
      return
    }
    setIsLoading(false)
  }, [])

  return <>{isLoading ? <LoadingContent /> : children}</>
}

export default GuardAuthenticationTemplate
