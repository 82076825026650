import { useCallback } from 'react';
import { toast } from 'react-toastify';


type UseToastMessage = () => {
    showSuccess: (message: string) => void;
    showError: (message: string) => void
}

const useToastMessage: UseToastMessage = () => {
    const position = 'top-center'
    const autoClose = 2000;


    const showSuccess = useCallback((message: string) => {
        toast.success(message, {
            position,
            autoClose,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        })
    }, []);

    const showError = useCallback((message: string) => {
        toast.error(message, {
            position,
            autoClose,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        })
    }, []);


    return {
        showSuccess,
        showError
    }
}

export default useToastMessage;