import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { getAccessToken } from '../modules/utils/authentication.util';
import config from './env.config';
const instance: AxiosInstance = axios.create({
    baseURL: config.zapp_model_url,
    headers: {
        'Content-Type': 'application/json',
        authorization: getAccessToken()
        // authorization: sessionStorage.getItem('authenticationToken')
    }
});

instance.interceptors.request.use(
    function (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
        config.headers.set('authorization', getAccessToken())
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log('error: ', error)
    if (error.response.status === 401) {
        // const protocol = window.location.protocol;
        // const hostname = window.location.host
        window.location.href = '/sign-in'
        // window.location.href = `${protocol}://${hostname}/sign-in`
    }
    return Promise.reject(error);
})

export default instance


