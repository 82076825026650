import { FunctionComponent } from 'react'
import ToolbarDrawer from '../../molecules/ToolbarDrawer'
import PropsWithChildren from '../../types'

type Props = {
  title: string
  onSave?: () => void
  onClose?: () => void
}

const RootMainTemplate: FunctionComponent<PropsWithChildren<Props>> = ({
  title,
  onSave,
  onClose,
  children,
}) => {
  return (
    <div className='h-screen flex flex-col bg-secondary-bg-color relative'>
      <ToolbarDrawer title={title} onClose={onClose} onSave={onSave} />
      <div className='flex-1 overflow-y-auto'>{children}</div>
    </div>
  )
}

export default RootMainTemplate
