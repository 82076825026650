import { ButtonType, ColorBase } from '../components/types';


const btnBgClass = {
    primary: 'bg-primary hover:bg-primary-dark'
}

const btnOutlinedClass = {
    primary: 'border-primary text-primary hover:bg-primary hover:text-white'
}

export const getButtonClassUtilComponent = (type: ButtonType, colorStyle: ColorBase): string => {
    if (type === ButtonType.Flat) {
        return `text-white ${btnBgClass[colorStyle]}`
    } else if (type === ButtonType.Outlined) {
        return `border ${btnOutlinedClass[colorStyle]}`
    }
    return ''
}

