import { FunctionComponent } from 'react'

const ItalianFlagIcon: FunctionComponent = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='32'
      height='21'
      viewBox='0 0 32 21'
    >
      <defs>
        <pattern
          id='italianFlag'
          width='100%'
          height='100%'
          preserveAspectRatio='xMidYMid slice'
          viewBox='0 0 225 150'
        >
          <image
            width='225'
            height='150'
            xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAACWAgMAAABV+geQAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACVBMVEUAkkb////OKzdsRJOSAAAAAWJLR0QB/wIt3gAAAAd0SU1FB+cDEgIiHiQcy3MAAAA9SURBVGje7csxAQAgDAOwIpJnatCDSgRUAE/yJylrl7nlxDRN0zRN0zRN0zRN0zRN0zRN0zRN0zRN0/w8H1gJAmyOGdK4AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTAzLTE4VDAyOjM0OjMwKzAwOjAwHN1txAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wMy0xOFQwMjozNDozMCswMDowMG2A1XgAAAAASUVORK5CYII='
          ></image>
        </pattern>
      </defs>
      <path fill='url(#italianFlag)' d='M0 0h32v21H0z'></path>
    </svg>
  )
}

export default ItalianFlagIcon
