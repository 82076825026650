import { FunctionComponent } from 'react'
import PropsWithChildren, { ButtonProps } from '../../../types'

type Props = {
  className?: string
} & Omit<ButtonProps, 'color'>

const BaseButton: FunctionComponent<PropsWithChildren<Props>> = ({
  type,
  className,
  children,
  onClick,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`h-[40px] px-4 rounded cursor-pointer text-sm outline-none ${className} transform duration-100`}
    >
      {children}
    </button>
  )
}

export default BaseButton
