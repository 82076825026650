import { FunctionComponent, PropsWithChildren } from 'react'

type Props = {
  isActive?: boolean
}

const LinkMenu: FunctionComponent<PropsWithChildren<Props>> = ({ isActive = false, children }) => {
  return (
    <li
      className={`px-3 py-4  hover:text-primary text-xl font-light cursor-pointer ${
        isActive ? 'text-primary' : 'text-white'
      }`}
    >
      {children}
    </li>
  )
}

export default LinkMenu
