import { createAsyncThunk } from '@reduxjs/toolkit';
import CustomizationPartAPI from '../../services/customizationPart.service';
import { Category, CreateCategory, CreateCustomizationPart, CreateFormGroup, CustomizationPart, DeleteFormGroup, FormGroup, UpdateCategory, UpdateCategoryOrder, UpdateCategoryStatus, UpdateCustomizationPart, UpdateCustomizationPartOrder, UpdateCustomizationPartStatus, UpdateFormGroup } from '../../types/customizationPart';
import { module } from '../../types/module';
import { errorHandler } from '../../utils/errorHandler.util';


export const fetchCategoriesList = createAsyncThunk<Category[]>(
    `${module.customizationParts}/fetchCategoriesList`,
    async (_, { rejectWithValue }) => {
        try {
            const result = CustomizationPartAPI.getCategory();
            return result
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const createNewCategory = createAsyncThunk<CreateCategory, Category>(
    `${module.customizationParts}/createNewCategory`,
    async (params, { rejectWithValue }) => {
        try {
            const result = CustomizationPartAPI.createCategory(params);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const updateCategory = createAsyncThunk<Category, UpdateCategory>(
    `${module.customizationParts}/updateCategory`,
    async (params, { rejectWithValue }) => {
        try {
            const { groupId, ...data } = params
            const result = await CustomizationPartAPI.updateCategory(groupId, data);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const deleteCategory = createAsyncThunk<string, string>(
    `${module.customizationParts}/deleteCategory`,
    async (groupId: string, { rejectWithValue }) => {
        try {
            await CustomizationPartAPI.deleteCategory(groupId);
            return groupId;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const fetchModelCustomizationPartsList = createAsyncThunk<CustomizationPart[], string>(
    `${module.customizationParts}/fetchModelCustomizationPartsList`,
    async (modelId: string, { rejectWithValue }) => {
        try {
            const result = await CustomizationPartAPI.getCustomizationPartByModelId(modelId);
            return result;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchCustomizationPartById = createAsyncThunk<CustomizationPart, string>(
    `${module.customizationParts}/fetchCustomizationPartById`,
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await CustomizationPartAPI.getCustomizationPart(id);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const createCategory = createAsyncThunk<Category, CreateCategory>(
    `${module.customizationParts}/createCategory`,
    async (params: CreateCategory, { rejectWithValue }) => {
        try {
            const result = await CustomizationPartAPI.createCategory(params);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)


export const updateCategoryOrders = createAsyncThunk<UpdateCategoryOrder[], UpdateCategoryOrder[]>(
    `${module.customizationParts}/updateCategoryOrders`,
    async (params: UpdateCategoryOrder[], { rejectWithValue }) => {
        try {
            await CustomizationPartAPI.updateCategoryOrders(params);
            return params;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)


export const updateCategoryStatus = createAsyncThunk<Category, UpdateCategoryStatus>(
    `${module.customizationParts}/updateCategoryStatus`,
    async (params: UpdateCategoryStatus, { rejectWithValue }) => {
        try {
            const { groupId, ...data } = params
            const result = await CustomizationPartAPI.updateCategoryStatus(groupId, data);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const createCustomizationPart = createAsyncThunk<CustomizationPart, CreateCustomizationPart>(
    `${module.customizationParts}/createCustomizationPart`,
    async (params: CreateCustomizationPart, { rejectWithValue }) => {
        try {
            const result = await CustomizationPartAPI.createCustomizationPart(params);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const updateCustomizationPartOrders = createAsyncThunk<UpdateCustomizationPartOrder[], UpdateCustomizationPartOrder[]>(
    `${module.customizationParts}/updateCustomizationPartOrder`,
    async (params: UpdateCustomizationPartOrder[], { rejectWithValue }) => {
        try {
            await CustomizationPartAPI.updateCustomizationOrder(params);
            return params;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const updateCustomizationPartStatus = createAsyncThunk<UpdateCustomizationPartStatus, UpdateCustomizationPartStatus>(
    `${module.customizationParts}/updateCustomizationPartStatus`,
    async (params: UpdateCustomizationPartStatus, { rejectWithValue }) => {
        try {
            const { partId, active } = params;
            await CustomizationPartAPI.updateCustomizationStatus(partId, active);
            return params;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const updateCustomizationPart = createAsyncThunk<CustomizationPart, UpdateCustomizationPart>(
    `${module.customizationParts}/updateCustomizationPart`,
    async (params: UpdateCustomizationPart, { rejectWithValue }) => {
        try {
            const { partId, ...data } = params;
            const result = await CustomizationPartAPI.updateCustomizationPart(partId, data);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)


export const deleteCustomizationPart = createAsyncThunk<string, string>(
    `${module.customizationParts}/deleteCustomizationPart`,
    async (params: string, { rejectWithValue }) => {
        try {
            await CustomizationPartAPI.deleteCustomizationPart(params);
            return params;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const syncCategoriesData = createAsyncThunk(
    `${module.customizationParts}/syncCategoriesData`,
    async (_, { rejectWithValue }) => {
        try {
            await CustomizationPartAPI.syncCategories();
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)


export const createCustomizationPartFormGroup = createAsyncThunk<CustomizationPart, CreateFormGroup>(
    `${module.customizationParts}/createCustomizationPartFormGroup`,
    async (params: CreateFormGroup, { rejectWithValue }) => {
        try {
            const { id, formGroup } = params;
            const result = await CustomizationPartAPI.createCustomizationFormGroup(id, formGroup);
            return result
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const updateCustomizationPartFormGroup = createAsyncThunk<CustomizationPart, UpdateFormGroup>(
    `${module.customizationParts}/updateCustomizationPartFormGroup`,
    async (params: UpdateFormGroup, { rejectWithValue }) => {
        try {
            const { id, formGroup } = params;
            const result = await CustomizationPartAPI.updateCustomizationFormGroup(id, formGroup);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)

export const deleteCustomizationPartFormGroup = createAsyncThunk<CustomizationPart, DeleteFormGroup>(
    `${module.customizationParts}/deleteCustomizationPartFormGroup`,
    async (params: DeleteFormGroup, { rejectWithValue }) => {
        try {
            const { id, formId } = params;
            const result = await CustomizationPartAPI.deleteCustomizationFormGroup(id, formId);
            return result;
        } catch (error) {
            return rejectWithValue(errorHandler(error))
        }
    }
)