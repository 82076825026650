import { FunctionComponent } from 'react'

type Props = {
  color: string
}

const BoxColor: FunctionComponent<Props> = ({ color }) => {
  return (
    <div
      style={{ backgroundColor: color || '#FFFFFF' }}
      className='w-[35px] h-[35px] rounded-[5px] border'
    />
  )
}

export default BoxColor
