import { FunctionComponent } from 'react'
import PropsWithChildren from '../../types'

const RootPage: FunctionComponent<PropsWithChildren<{ className?: string }>> = ({
  className,
  children,
}) => {
  return <div className={`h-screen flex flex-col space-y-5 p-5 ${className}`}>{children}</div>
}

export default RootPage
