import { StoreData } from '../../types/module';
import { Translation } from '../../types/translation';
import { initialStore } from '../../utils/store.util';



export interface ITranslationState {
    translations: StoreData<Translation[]>,
}

export const initialState: ITranslationState = {
    translations: initialStore<Translation[]>([])
}