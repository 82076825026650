import { Fragment, FunctionComponent, memo } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'

import { ExtendPart } from '../../../modules/types/models'
import DropdownIconButton from '../../atoms/DropdownIconButton'
import ChevronRightIcon from '../../atoms/Icons/ChevronRightIcon'
import EllipsisVertical from '../../atoms/Icons/EllipsisVertical'

type Props = {
  extensionParts: ExtendPart[]
  onChangeOrder?: (items: ExtendPart[]) => void
  onSelectPart?: (val: ExtendPart) => void
  onEdit?: (index: number, val: ExtendPart) => void
  onDelete?: (index: number) => void
}
const reorder = (list: ExtendPart[], startIndex: number, endIndex: number): ExtendPart[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

// const getItemStyle = (isDragging, draggableStyle) => ({
//   // some basic styles to make the items look a bit nicer
//   userSelect: 'none',
//   padding: grid * 2,
//   margin: `0 0 ${grid}px 0`,

//   // change background colour if dragging
//   background: isDragging ? 'lightgreen' : 'grey',

//   // styles we need to apply on draggables
//   ...draggableStyle,
// })

const DndPartExtensionList: FunctionComponent<Props> = ({
  extensionParts,
  onChangeOrder,
  onSelectPart,
  onEdit,
  onDelete,
}) => {
  function onDragEnd({ destination, source }: DropResult) {
    if (!destination) {
      return
    }

    if (destination.index === source.index) {
      return
    }

    const newOrderingList = reorder(extensionParts, source.index, destination.index)
    if (onChangeOrder) onChangeOrder(newOrderingList)
  }

  function handleSelectPart(val: ExtendPart) {
    if (onSelectPart) onSelectPart(val)
  }

  function handleOnSelectMenu(key: string, index: number, val: ExtendPart) {
    if (key === 'on-edit' && onEdit) onEdit(index, val)
    if (key === 'on-delete' && onDelete) onDelete(index)
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {extensionParts.map((part, index) => (
                <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className='flex space-x-4 items-center px-5 py-3 bg-secondary-bg-color border my-2'>
                        <div className='flex-1'>
                          <p className='text-xl font-bold'>{part.name.en}</p>
                        </div>
                        <DropdownIconButton
                          icon={<EllipsisVertical />}
                          menus={[
                            {
                              key: 'on-edit',
                              label: 'Edit',
                            },
                            {
                              key: 'on-delete',
                              label: 'Delete',
                            },
                          ]}
                          onSelected={(key) => handleOnSelectMenu(key, index, part)}
                        />
                        <button
                          type='button'
                          className='m-0'
                          onClick={() => handleSelectPart(part)}
                        >
                          <ChevronRightIcon />
                        </button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default memo(DndPartExtensionList)
