import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import LoadingContent from '../../components/molecules/LoadingContent'
import SearchAndAddMenu from '../../components/molecules/SearchAndAddMenu'
import TranslationCard from '../../components/molecules/TranslationCard'
import HeaderPage from '../../components/organisms/HeaderPage'
import GuardAuthenticationTemplate from '../../components/templates/GuardAuthentication'
import RootPage from '../../components/templates/RootPage'
import { useAppDispatch, useAppSelector } from '../../modules/hook'
import { fetchTranslationList } from '../../modules/stores/translations/actions'

import { StateData } from '../../modules/types/module'
import { Translation } from '../../modules/types/translation'

const ThemeSettings: FunctionComponent = () => {
  const dispatch = useAppDispatch()

  const [name, setName] = useState('')
  const translations = useAppSelector((state) => state.translations.translations)

  const handleOnChange = useCallback((val: string) => {
    setName(val)
  }, [])

  const handleOnEdit = useCallback((val: Translation) => {
    console.log(val)
  }, [])

  const handleOnDelete = useCallback((val: string) => {
    console.log(val)
  }, [])

  const translationList = useMemo(() => {
    if (translations.state !== StateData.Success) return []
    return name
      ? translations.data.filter((translation) =>
          translation.name.toLocaleLowerCase().includes(name.toLocaleLowerCase()),
        )
      : translations.data
  }, [translations.state, translations.data, name])

  useEffect(() => {
    dispatch(fetchTranslationList())
  }, [])

  return (
    <GuardAuthenticationTemplate>
      <RootPage>
        <HeaderPage title='Theme Settings' subtitles={['Setting', 'Theme Setting']} />
        <SearchAndAddMenu onChange={handleOnChange} />
        <div className='flex-1 overflow-y-auto'>
          {translations.state === StateData.Loading ? <LoadingContent /> : <></>}
          {translations.state === StateData.Success ? (
            <Fragment>
              {translationList.map((translation) => (
                <TranslationCard
                  key={translation.docId}
                  translation={translation}
                  onEdit={handleOnEdit}
                  onDelete={handleOnDelete}
                />
              ))}
            </Fragment>
          ) : (
            <></>
          )}
        </div>
      </RootPage>
    </GuardAuthenticationTemplate>
  )
}

export default ThemeSettings
