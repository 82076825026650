import Loading from '../../atoms/Loading'

const LoadingContent = () => {
  return (
    <div className='flex flex-col items-center space-y-0'>
      <Loading />
      <p className=' font-bold'>Please Waiting</p>
    </div>
  )
}

export default LoadingContent
